.info--section
	background: $blue
	.container
		width: 100%
		max-width: 1400px
		margin: 0 auto
		*
			color: white
			text-align: center
		h2
			color: white
			font-weight: bold
			width: 100%
			max-width: 100%
			margin-top: 1rem
			margin-bottom: 2rem
		.info--text
			font-size: 1.6rem
			margin: 0 auto
			text-transform: uppercase
			font-weight: bold
		p
			margin: 2rem auto 1rem