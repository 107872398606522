
//@import url('https://fonts.googleapis.com/css?family=PT+Sans+Narrow:700|Raleway:100,400,700')
// @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:700|Roboto:100,400,300i,400i,700&display=swap')

h1, .heading 
	font-weight: 100
	color: $blue
	font-size: 5.4rem
	margin-bottom: 30px
	line-height: 1
	font-family: $main-font
	font-weight: 100
	text-align: left
	text-transform: uppercase
	@media (max-width: 1300px)
		font-size: 4.236rem
	@media (max-width: 700px)
		font-size: 2.618rem
.thin
	font-weight: 100
	letter-spacing: 0.05rem
.bold
	font-weight: 700!important
.uppercase
	text-transform: uppercase
.large__p
	font-size: 1.4rem

a
	color: inherit
	font: inherit
	// text-decoration: none

h2:not(.heading)
	font-family: $main-font
	font-weight: 100
	color: $blue
	font-size: 2.618rem
	margin: 80px auto
	text-align: center
	width: 300px
	text-transform: uppercase
	@media (max-width: 700px)
		font-size: 1.618rem
		margin:	50px auto


h3 
	font-family: $condensed-font
	font-weight: 700
	color: $blue
	font-size: 1.618rem
	@media (max-width: 740px)
		font-size: 1.2rem
	&.caption
		margin-bottom: 50px
		position: relative
		padding-left: 3rem
		@media (max-width: 1300px)
			margin-bottom: 30px
		@media (max-width: 740px)
			padding-left: 0rem
			margin-bottom: 10px
		&::before 
			position: absolute
			content: ""
			display: inline-block
			background: $blue
			width: 2rem
			height: 3px
			top: 14px
			left: 0
			margin: 3px 20px 3px 3px
			@media (max-width: 740px)
				top: 8px
				display: none


h4
	font-family: $main-font
	font-weight: 100
	color: $blue
	font-size: 2.618rem
	margin-bottom: 20px
	line-height: 1.2
	text-transform: uppercase
	@media (max-width: 940px)
		font-size: 1.618rem!important


h5
	font-family: $main-font
	font-weight: 100
	color: $blue
	font-size: 1.2rem
	margin: 15px 0px 15px 15px
	position: relative
	&::after 
		content: url('../img/arrow-right-blue.svg')
		display: inline-block
		position: absolute
		right: 14px
		height: 20px
		width: 10px
		top: 50%
		transform: translateY(-10px)
		transition: .2s ease-in-out

h6
	font-family: $condensed-font
	font-size: 1.4rem
	font-weight: 400
	text-align: center
	color: white
	margin-bottom: 20px
	font-family: PT Sans Narrow, $condensed-font


p 
	font-size: 1rem
	color: $grey
	margin-bottom: 50px
	line-height: 1.6
	font-weight: 400
	max-width: 700px


.fs240
	font-size: 240%