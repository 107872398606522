.twen
	display: flex
	align-items: flex-end
	p 
		z-index: 1
		margin-left: 30px
		margin-bottom: 30px
		font-size: 1.4rem
		color: #fff
		font-style: italic
		line-height: 1.2
		font-weight: 300
		@media (max-width: 640px)
			font-size: 0.7rem 
			margin-left: 20px

#twen
	h1
		font-size: 2.4rem
		line-height: 1.2
		text-transform: none
		@media (max-width: 960px)
			font-size: 1.6rem
		@media (max-width: 760px)
			font-size: 1.4rem
	
#twen-info, #fashionabo-info
	// margin-bottom: 2rem
	position: relative
	.kids--row
		display: flex
		align-items: center
		margin-right: 20px!important
			
	
		

	*
		color: white
	padding: 2em
	.flex-row
		align-items: center
		justify-content: center
	p   
		margin-bottom: 1rem
		&:last-child
			margin-bottom: 0
	h3
		max-width: 600px
		font-size: 2.4rem
		@media (max-width: 760px)
			font-size: 1.4rem
	.divider
		width: 1px
		margin: 0 60px
		height: 200px
		background: #fff
	.col-1
		max-width: 600px
		&:first-child
			margin-right: 60px
		&:last-child
			padding-left: 60px
			border-left: 1px solid white
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
			max-width: 100%

	@media (max-width: 760px)
		.flex-row
			display: block
			.col-1
				margin: 0
				h3
					margin-bottom: 30px
				&:last-child
					padding-left: 20px
					a
						margin-bottom: 0

.logo--grid
	display: flex
	flex-wrap: wrap
	max-width: 1600px
	margin: 3em auto
	align-items: center
	.logo--item
		flex: 1 0 20%
		margin: 10px
		text-align: center
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
			width: 100%
			height: 100%
		img
			width: 100%
			max-width: 250px
			max-height: 150px
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
				width: auto
			   
#fashion
	.fw--image 
		&.fashion		  
			background: url('../img/fashion.jpg') top center no-repeat
			background-size: cover
#twen
	@media (max-width: 1330px)
		min-height: 0
	.fw--image 
		&.twen		  
			background: url('../img/aktion/header.jpg') top 10% center no-repeat
			background-size: cover
				
.marken--grid
	// max-width: 1600px
	margin: 0 auto 0
	display: flex
	flex-wrap: wrap
	.fw--image-container
		flex: 1 0 30%
		position: relative
		height: 0
		padding-bottom: 40%
		overflow: hidden
		// margin-bottom: 2px
		background-position: center
		background-size: cover
	img
		flex: 1 0 30%
		width: 30%
		height: 100%
		.fw--image
			transition: all 300ms ease
			position: absolute
			width: 99.5%
			height: 100%
			left: 50%
			top: 50%
			transform: translate(-50%,-50% )
			background-size: cover
			background-position: center center
			&.big-image
				width: 99.7%
				margin-top: 3px
			// &.scaled
			//	 width: 150%
			//	 height: 150%
		.platzhalter
			position: absolute
			left: 50%
			top: 50%
			transform: translate(-50%,-50% )
			font-weight: 600
			color: white
			background: $blue
			padding: 0.3em 0.6em
	@media (min-width: 768px)
		
		.fw--image-container, img
			flex: 1 0 15%

#kids-info
	.stoerer
		margin-left: 1rem
		position: relative
		width: 10rem
		height: 10rem
		min-width: 10rem
		min-height: 10rem
		display: flex
		flex-wrap: wrap
		flex-direction: column
		align-items: center
		justify-content: center
		background: #ffcf00
		border-radius: 50%
		padding: 10px
		text-align: center
		color: black
		font-size: 1rem
		font-weight: 400
		margin-bottom: 1rem
		transform: rotate(-10deg)
		strong
			font-weight: 700
			color: black
			font-size: 1.2rem
		@media (max-width: 540px)
			margin-right: -1rem
			width: 8rem
			height: 8rem
			min-width: 8rem
			min-height: 8rem
			font-size: 0.8rem
	.fw--image 
		&.kids
			background: url('../img/img-kids-1.jpg') center no-repeat
			background-size: cover
		&.kids2
			background: url('../img/img-kids-2.jpg') center no-repeat
			background-size: cover
		&.kids3
			background: url('../img/img-kids-3.jpg') top center no-repeat
			background-size: cover
.fw-image-slider
	.kids2
		opacity: 0
		animation: fadingGallery forwards infinite ease 15s
	.kids3
		opacity: 0
		animation: fadingGallery2 forwards infinite ease 15s


@keyframes fadingGallery
	28.333%
		opacity: 0
	33.333%, 61.33%
		opacity: 1
	66.666%
		opacity: 0
@keyframes fadingGallery2
	61.33%
		opacity: 0
	66.666%
		opacity: 1
	95%
		opacity: 1
	100%
		opacity: 0

#fashion, #kids-info, #twen
	h3
		font-weight: 100
		font-size: 2rem
		font-family: $main-font