
.content--container
    max-width: 900px
    margin: 0px auto
    padding: 3em 2em
    p
        max-width: 100%
        &:last-child
            //margin-bottom: 0
    h3
        margin-bottom: 30px
    strong
        font-weight: 600

#wimpern, #sicca
    //min-height: 0
    h2
        font-size: 1.6rem
        font-family: $condensed-font
        line-height: 1.2!important
    strong
        font-weight: 600
.preise--content
    display: flex
    justify-content: center
    flex-wrap: wrap
    margin-bottom: 3em
    .artikel
        flex: 1 0 30%
        margin: 10px
        box-shadow: 0 0 10px rgba(black, 0.15)
        position: relative
        .artikel--image-container
            position: relative
        .konservierung
            position: absolute
            right: 0px
            bottom: 5px
            background: $blue
            color: white
            padding: 0.3em 0.6em
            display: block
        .artikel--image
            max-width: 100%
        .artikel--desc
            padding: 0.6em 2em 1em
            display: flex
            align-items: center
            flex-wrap: wrap
            justify-content: space-between
            h3
                margin: 0
                font-size: 1rem
                font-family: $main-font
                color: black
                flex: 1 0 0
            .artikel--preis
                text-align: right
                flex: 1 0 0
                font-weight: 600
                font-style: normal
                display: inline-block
                color: $blue
                font-size: 2rem
                letter-spacing: -0.05rem
                span
                    font-size: 1.2rem
                    font-weight: inherit
        @media (max-width: 960px)
            flex: 1 0 45%
        @media (max-width: 560px)
            flex: 1 0 90%
            .artikel--desc
                padding: 1em
