.sports--bg
    background-color: #fff
    background-image: url('../img/bg-sports.jpg'), url('../img/bg-sports.jpg')
    background-size: 40%, 40%
    background-repeat: no-repeat, no-repeat
    background-position: top -10% right -10%, bottom -10% left -10%
    padding-bottom: 60px
    .flex-row.no-align
        .col-1
            margin-right: 10px
            .flex-row
                img
                    flex: 1 0 0 
                    max-width: 200px
        @media (max-width: 940px)
            display: block     
            .col-1
                .flex-row
                    img
                        flex: 1 0 0 
    h1
        margin: 40px 0   
    .brillen
        padding: 30px
        display: grid
        grid-template-areas: "rad ski golf" "segel lauf leer"
        @media (max-width: 1240px)
            grid-template-areas: "rad ski" "golf segel" "lauf leer"
            padding: 20px
        @media (max-width: 760px)
            grid-template-areas: "rad" "ski" "golf" "segel" "lauf" "leer"
.info--box
    flex: 1 0 30%
    margin: 10px
    &:nth-child(1)
        grid-area: rad
    &:nth-child(2)
        grid-area: ski
    &:nth-child(3)
        grid-area: golf
    &:nth-child(4)
        grid-area: segel
    &:nth-child(5)
        grid-area: lauf
    h3
        margin-bottom: 10px

    .image--wrapper
        padding-bottom: 60%
    p
        margin-bottom: 20px