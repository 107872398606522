.check--list
    margin: 0
    list-style-type: none
    li
        position: relative
        margin-bottom: 15px
        font-size: 1rem
        font-weight: 400
        margin-left: 30px
        &:before
            content: ''
            background: url("../img/icn--check.svg") center no-repeat
            width: 20px
            height: 20px
            background-size: contain
            position: absolute
            top: 0
            left: -30px
        strong
            font-weight: 600
            color: $blue
.icon--list
    margin: 30px 0
    list-style-type: none
    li
        position: relative
        margin-bottom: 2rem
        font-size: 1rem
        font-weight: 400
        display: flex
        align-items: center

        strong
            font-weight: 600
            color: $blue
        .icon
            position: relative
            width: 60px
            min-width: 60px
            margin-right: 1rem
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
                width: auto
                min-width: auto
    
    @media (max-width: 740px)
        li
            display: block
            .icon
                margin: 10px auto
                display: block
                width: 80px
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
                    width: auto

.plus--list
    margin: 0
    list-style-type: none
    max-width: 1100px
    li
        position: relative
        margin-bottom: 10px
        font-size: 1rem
        font-weight: 400
        margin-left: 20px
        &:before
            content: '+'
            width: 20px
            height: 20px
            background-size: contain
            position: absolute
            top: 0
            left: -20px
        strong
            font-weight: 600
            color: $blue
