footer
	background: white
	border-top: 2px solid $blue
	display: flex
	align-items: center
	justify-content: flex-end
	padding: 20px
	position: relative
	z-index: 1
	.impressum
		margin-right: 40px
		a
			text-decoration: none
			color: $blue
			transition: .2s ease
			&:hover
				opacity: 0.8
	.social-icons
		margin-right: 40px
		display: flex
		a
			margin-right: 15px
			text-decoration: none
			display: block
			min-width: 20px
			&:hover
				opacity: 0.8
	.backtotop
		transition: .2s ease
		&:hover
			margin-bottom: 10px
	@media (max-width: 540px)
		display: block
		text-align: center
		.social-icons
			margin: 2rem auto
			justify-content: center
	@media (max-width: 740px)

		justify-content: center
		*
			margin-right: 0!important
		.impressum
			flex: 1 0 30%
			min-width: 200px
		.social-icons
			flex: 1 0 20%
			text-align: center
			a
				margin: 0 10px
		.backtotop
			flex: 1 0 10%
			text-align: right
		

.modal
	position: fixed
	left: 0
	top: 0
	width: 100%
	height: 100%
	background: rgba(white, 0.97)
	z-index: 999999
	padding: 2.7rem 0 0 0
	overflow-y: auto
	display: none
	&.active
		display: block
	&--content
		width: 100%
		max-width: 50rem
		display: block
		margin: 0 auto
		.close
			cursor: pointer
			position: absolute
			right: 1rem
			top: 1rem
			font-weight: bold
			background: $blue
			color: white
			padding: 0.5rem 1rem
		img
			width: 100%
			box-shadow: 0 0 1rem rgba(black, 0.1)

