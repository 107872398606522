#about
    margin-bottom: 60px
    .mw-1600
        border-bottom: 1px solid #000
        padding-bottom: 60px
    h1
        img
            width: 500px
            max-width: 100%
    h4
        margin-top: 30px
        margin-bottom: 0px
        color: black
        font-size: 1.618rem
        font-family: $condensed-font
    p
        margin-top: 30px
        margin-bottom: 0px
        max-width: 1200px
#timeline
    margin-bottom: 60px
    .mw-1600
        text-align: center
    .year
        margin: 0 auto
        display: block
        position: relative
        &:before
            content: ""
            width: 2px
            height: 100%
            position: absolute
            left: 50%
            bottom: 0
            transform: translateX(-50%)
            background: $blue
        h2
            margin: 0
            font-size: 1.61rem
    .timestamp
        min-width: 100%
        font-size: 2rem
        font-family: $condensed-font
        position: relative
        display: inline-block
        padding: 0.3em 0.6em
        background: white
        &:before
            content: ""
            position: absolute
            left: 50%
            bottom: -10px
            transform: translateX(-50%)
            width: 15px
            height: 15px
            background: $blue
            border-radius: 50%
    .event
        display: flex
        flex-wrap: wrap
        justify-content: flex-end
        padding: 60px 10vw 60px 10vw
        text-align: right
        align-items: center
        margin-left: 0
        margin-right: 0
        position: relative
        flex: 1 0 50%
        max-width: 50%
        margin-bottom: 0
        > div
            order: 2
        img
            width: 100px
            height: 100px
            min-width: 100px
            min-height: 100px
            border-radius: 50%
            object-fit: cover
            margin: 10px
            order: 1
        strong
            font-weight: 600
            color: $blue
            font-family: $condensed-font
            font-size: 1.2rem
        &:before
            content: ""
            width: calc(10vw - 20px)
            height: 2px
            background: $blue
            position: absolute
            left: auto
            right: 0
            top: 50%
            transform: translateY(-50%)
        &.odd
            flex: 1 0 50%
            left: 50%
            margin-right: 0
            text-align: left
            justify-content: flex-start
            img
                order: 3


            &:before
                right: auto
                left: 0


@media (max-width: 740px)
    #timeline
        .mw-1600
            text-align: left
        
            .year
                display: inline-block
                min-width: 0
                margin: 0
                &:before
                    left: 40px
                    transform: translateX(0)

            .timestamp
                min-width: 0
                width: auto
            .event
                display: block
                max-width: 100%
                text-align: left
                left: 0
                margin-left: 40px
                > div
                    min-width: 100%
                    order: 1 !important
                img
                    order: 2!important
        
                &:before
                    left: 0
                    right: auto
                &.odd
                    text-align: left
                    left: 0

#storeslider
    width: calc(100% - 4rem)
    max-width: calc(100% - 4rem)
    margin-left: 2rem
    margin-right: 2rem
    margin-bottom: 3rem