.news__modal
	position: fixed
	left: 0
	top: 0
	height: 100%
	width: 100%
	background: rgba(black, 0.8)
	z-index: 999
	align-items: center
	padding: 2rem
	justify-content: center
	display: flex
	&.hidden
		display: none
	.modal__content
		width: 100%
		max-width: 70rem
		background: white
		box-shadow: 0 0 1rem rgba(black, 0.15)
		display: grid
		grid-template-areas: 'image' 'close' 'text'
		grid-template-columns: 1fr
		@media (min-width: 768px)
			grid-template-areas: 'image close' 'image text'
			grid-template-columns: 1fr 1fr
			grid-template-rows: auto auto
		.close
			grid-area: close
			text-align: right
			padding: 1rem 2rem
			p
				cursor: pointer
				display: inline-block
				margin-left: auto
				cursor: pointer
		.modal__image
			grid-area: image
			background-size: cover
			background-position: center 20%
			min-height: 15rem

			display: flex
			justify-content: flex-end
			align-items: flex-end
			padding: 20px
			color: white

			@media (min-width: 768px)
				min-height: 25rem
		.modal__text
			grid-area: text
			padding: 0 2rem 2rem
			.as-btn
				cursor: pointer
.modal__bewerbung#modal
	padding: 1rem
	.modal__content
		background: #e9e9e9
		background: #fff
		padding: 0
		position: relative
		overflow-y: auto
		padding-top: 2rem
		width: 100%
		max-width: 70rem
		max-height: 100%
		hr
			border: none
			background: $blue
			width: 100%
			margin: 1.5rem 0
			height: 1px
		.close 
			font-size: 3rem
			position: absolute
			top: 0.5rem
			right: 1rem
			cursor: pointer
			z-index: 10
			line-height: 1
		.content
			padding: 2rem
			padding-top: 0
			// padding-bottom: 6rem
			padding-bottom: 0
			position: relative
			margin: 0
			*
				position: relative
				z-index: 1

			h4
				font-size: 1.6rem
				font-weight: bold
				margin-bottom: 0
				line-height: 1.2
				display: block
			h3
				font-size: 2.4rem
				font-weight: 100
				font-family: $main-font
				line-height: 1.2
				margin-bottom: 1rem
				margin-top: 0.5rem
			p
				margin-bottom: 1rem
			strong, a
				display: block
				font-weight: bold
		img
			// position: relative!important
			// right: 0
			// bottom: -6rem
			display: block
			width: 100%
			height: auto
			z-index: 0
			margin-top: -2rem