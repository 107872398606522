#sehtest .headline--left,
#brille .headline--left,
#kontaktlinsen .headline--left,
#myopieprävention .headline--left,
#visualtraining .headline--left,
#visualtraining-img .headline--left
	font-size: 1rem
	color: #2e2e2e
	line-height: 1.6
	font-weight: 400
	h2
		text-align: left
		margin-left: unset
	strong
		font-weight: 600!important
		color: $blue
		font-size: 16px!important
	ul li
		font-size: 1rem
		color: #2e2e2e
		line-height: 1.6
		font-weight: 400
		margin-left: 15px