.marken--masonry
	padding: 0 30px
	margin: 0 auto 60px
	position: relative
	background: #fff
	.image--grid-item.image--wrapper
		p 
			z-index: 1
			align-self: flex-end
			margin-bottom: 0
			font-size: 1.4rem
			color: #fff
			font-style: italic
			line-height: 1.2
			font-weight: 300
		@media (max-width: 640px)
			padding: 15px
			p 
				font-size: 0.7rem
				margin-bottom: 5px


.pagination--link
	font-size: 1.2rem
	margin-right: 5px
	display: inline-block
	padding: 10px
	width: 40px
	color: white
	text-decoration: none
	background: $grey
	text-align: center
	margin-bottom: 10px
	cursor: pointer
	color: $blue
	transition: all 200ms ease
	&.active, &:hover
		color: white
		background: $blue
	&.all
		width: auto
	@media (max-width: 740px)
		padding: 0 20px
		.pagination--link
			width: 20px
			font-size: 1rem
			padding: 0 
.image--grid-item.image--slider
	padding: 0!important
	video
		max-width: 100%
		width: 100%
		height: 100%
		object-fit: cover
	img.lazy
		max-width: 100%!important

.grid--slider
	width: 100%

.hover__video
	cursor: url(../img/cursor.svg), auto
	