
#yoga--content
	display: block
	width: 100%
	max-width: 1400px
	margin: 0 auto
	padding: 0 1rem
	a.outline-btn
		border: 2px solid $blue
		color: $blue
		padding: 0.5rem 1rem
		text-decoration: none
		font-weight: bold
		margin-top: 1rem
		display: block
		transition: all 300ms ease
		&:hover
			background: $blue
			color: white
	

#highlights--content
	display: block
	text-align: center
	overflow-x: hidden
	width: 100vw

	
	.logos
		margin-top: 20px
	main
		display: flex
		flex-direction: column
		padding: 0 2rem
		width: 100%
		max-width: 30rem	
		margin: 0 auto
		.outline-btn
			border: 2px solid $blue
			color: $blue
			padding: 0.5rem 1rem
			text-decoration: none
			font-weight: bold
			margin-bottom: 1rem
			transition: all 300ms ease
			&:hover
				background: $blue
				color: white
	header
		padding: 0
		margin: 0
		background-size: cover
		width: 100%
		// max-width: 1400px
		// margin: 0 auto
		img
			width: 100%
			max-width: 100%

	.logo--seite
		text-align: left
		width: 50%
		max-width: 50%

		height: 0
		background: black
		position: relative
		display: block
		a
			text-align: left
			display: block
			left: 1rem
			// top: 1rem
			position: relative
		img
			position: relative
			z-index: 10
			max-width: 18vw
			width: 180px
			margin-right: auto
	main
		padding-top: 2rem!important
		padding-bottom: 2rem
		*
			text-align: left
	h3
		display: flex
		align-items: center
		margin-bottom: 20px
		font-family: Roboto, sans-serif
		img
			flex: 1
			max-width: 105px
			position: relative
			top: -1px
			margin-left: 4px
			@media (min-width: 741px)
				max-width: 150px
				top: -2px
				margin-left: 5px
	#highlights--container
		max-width: 80rem
		margin: 0 auto
		img
			width: 100%
			display: block
			height: auto
		p
			max-width: 100%
			margin-bottom: 20px
	.soft--row
		@media (min-width: 800px)
			display: flex
			align-items: center
			.left--content
				width: 60%
			.image--container
				width: 40%
				padding-left: 2rem
				margin: 0!important
				transform-origin: center
	.flex--container
		display: grid
		grid-template-columns: 1fr
		grid-template-areas: 'one' 'two' 'three' 'four' 'five' 'six'
		grid-gap: 10px
		margin-bottom: 20px
		@media (min-width: 560px)
			grid-template-areas: 'one two' 'three four' 'five six'
			grid-template-columns: 1fr 1fr
		@media (min-width: 900px)
			grid-template-areas: 'one two three' 'four five six'
			grid-template-columns: 1fr 1fr 1fr

	.info--box
		padding: 20px
		margin: 0
		background: $blue
		&:nth-child(1)
			grid-area: one
		&:nth-child(2)
			grid-area: two
		&:nth-child(3)
			grid-area: three
		&:nth-child(4)
			grid-area: four
		&:nth-child(5)
			grid-area: five
		&:nth-child(6)
			grid-area: six
		// min-width: 230px
		*
			color: white

		strong
			font-weight: bold
			margin-bottom: 10px
			display: block
			font-size: 20px
		ul
			margin-bottom: -10px
			li
				margin-left: 20px
				margin-bottom: 10px
				font-weight: normal
	
	.image--container
		margin-left: -2rem
		margin-right: -2rem
		margin-bottom: 20px
	.as-btn.phone
		padding-right: 20px
		&:after
			content: none