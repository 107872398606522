  /* roboto-condensed-700 - latin */
  @font-face {
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/roboto-condensed-v24-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/roboto-condensed-v24-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/roboto-condensed-v24-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/roboto-condensed-v24-latin-700.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/roboto-condensed-v24-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/roboto-condensed-v24-latin-700.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  }
  
/* roboto-100 - latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 100;
	src: url('../fonts/roboto-v29-latin-100.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/roboto-v29-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/roboto-v29-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/roboto-v29-latin-100.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/roboto-v29-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/roboto-v29-latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  
  /* roboto-300italic - latin */
  @font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 300;
	src: url('../fonts/roboto-v29-latin-300italic.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/roboto-v29-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/roboto-v29-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/roboto-v29-latin-300italic.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/roboto-v29-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/roboto-v29-latin-300italic.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  
  /* roboto-regular - latin */
  @font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/roboto-v29-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/roboto-v29-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/roboto-v29-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/roboto-v29-latin-regular.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/roboto-v29-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/roboto-v29-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  
  /* roboto-italic - latin */
  @font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 400;
	src: url('../fonts/roboto-v29-latin-italic.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/roboto-v29-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/roboto-v29-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/roboto-v29-latin-italic.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/roboto-v29-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/roboto-v29-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  
  /* roboto-700 - latin */
  @font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/roboto-v29-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/roboto-v29-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/roboto-v29-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/roboto-v29-latin-700.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/roboto-v29-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/roboto-v29-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
  }

  @font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/MaterialIcons-Regular.eot'); /* For IE6-8 */
	src: local('Material Icons'),
	  local('MaterialIcons-Regular'),
	  url('../fonts/MaterialIcons-Regular.woff2') format('woff2'),
	  url('../fonts/MaterialIcons-Regular.woff') format('woff'),
	  url('../fonts/MaterialIcons-Regular.ttf') format('truetype');
  }
  