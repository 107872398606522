@charset "UTF-8";
/* roboto-condensed-700 - latin */
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/roboto-condensed-v24-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-condensed-v24-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-condensed-v24-latin-700.woff2") format("woff2"), url("../fonts/roboto-condensed-v24-latin-700.woff") format("woff"), url("../fonts/roboto-condensed-v24-latin-700.ttf") format("truetype"), url("../fonts/roboto-condensed-v24-latin-700.svg#RobotoCondensed") format("svg");
  /* Legacy iOS */
}
/* roboto-100 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/roboto-v29-latin-100.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-v29-latin-100.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v29-latin-100.woff2") format("woff2"), url("../fonts/roboto-v29-latin-100.woff") format("woff"), url("../fonts/roboto-v29-latin-100.ttf") format("truetype"), url("../fonts/roboto-v29-latin-100.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-300italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/roboto-v29-latin-300italic.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-v29-latin-300italic.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v29-latin-300italic.woff2") format("woff2"), url("../fonts/roboto-v29-latin-300italic.woff") format("woff"), url("../fonts/roboto-v29-latin-300italic.ttf") format("truetype"), url("../fonts/roboto-v29-latin-300italic.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto-v29-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-v29-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v29-latin-regular.woff2") format("woff2"), url("../fonts/roboto-v29-latin-regular.woff") format("woff"), url("../fonts/roboto-v29-latin-regular.ttf") format("truetype"), url("../fonts/roboto-v29-latin-regular.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/roboto-v29-latin-italic.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-v29-latin-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v29-latin-italic.woff2") format("woff2"), url("../fonts/roboto-v29-latin-italic.woff") format("woff"), url("../fonts/roboto-v29-latin-italic.ttf") format("truetype"), url("../fonts/roboto-v29-latin-italic.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/roboto-v29-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-v29-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v29-latin-700.woff2") format("woff2"), url("../fonts/roboto-v29-latin-700.woff") format("woff"), url("../fonts/roboto-v29-latin-700.ttf") format("truetype"), url("../fonts/roboto-v29-latin-700.svg#Roboto") format("svg");
  /* Legacy iOS */
}
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/MaterialIcons-Regular.eot");
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url("../fonts/MaterialIcons-Regular.woff2") format("woff2"), url("../fonts/MaterialIcons-Regular.woff") format("woff"), url("../fonts/MaterialIcons-Regular.ttf") format("truetype");
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.as-slider, #header--slider, .slider-for, #testsieger {
  height: 100%;
  position: relative;
}
.as-slider .slick-list, .as-slider .slick-track, #header--slider .slick-list, #header--slider .slick-track, .slider-for .slick-list, .slider-for .slick-track, #testsieger .slick-list, #testsieger .slick-track {
  height: 100%;
}
.as-slider .slide, #header--slider .slide, .slider-for .slide, #testsieger .slide {
  position: relative;
  background-size: cover;
  background-position: center;
}
.as-slider .slider-1-1, #header--slider .slider-1-1, .slider-for .slider-1-1, #testsieger .slider-1-1 {
  background-image: url("../img/tamara_roscher.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
}
.as-slider .slider-1-2, #header--slider .slider-1-2, .slider-for .slider-1-2, #testsieger .slider-1-2 {
  background-image: url("../img/karl_amon.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
}
.as-slider .slider-1-3, #header--slider .slider-1-3, .slider-for .slider-1-3, #testsieger .slider-1-3 {
  background-image: url("../img/header1.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
}
.as-slider .slider-2-1, #header--slider .slider-2-1, .slider-for .slider-2-1, #testsieger .slider-2-1 {
  background-image: url("../img/brands/oliver-peoples-brand-01.jpg");
  background-size: cover;
  background-position: top;
  position: relative;
}
.as-slider .slider-2-2, #header--slider .slider-2-2, .slider-for .slider-2-2, #testsieger .slider-2-2 {
  background-image: url("../img/brands/oliver-peoples-brand-02.jpg");
  background-size: cover;
  background-position: top;
  position: relative;
}
.as-slider .slider-2-3, #header--slider .slider-2-3, .slider-for .slider-2-3, #testsieger .slider-2-3 {
  background-image: url("../img/brands/oliver-peoples-brand-03.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
}
.as-slider .slider-2-4, #header--slider .slider-2-4, .slider-for .slider-2-4, #testsieger .slider-2-4 {
  background-image: url("https://images.aceandtate.com/image/upload/c_scale,w_1200/:spree/public/spree/products/5546/original/russel-smoke-FACE-m.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
}
.as-slider .slick-prev, #header--slider .slick-prev, .slider-for .slick-prev, #testsieger .slick-prev {
  position: absolute;
  z-index: 99;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.as-slider .slick-prev i, #header--slider .slick-prev i, .slider-for .slick-prev i, #testsieger .slick-prev i {
  font-size: 5rem;
  color: white;
  opacity: 0.5;
  transition: 0.3s ease;
}
.as-slider .slick-prev:hover i, #header--slider .slick-prev:hover i, .slider-for .slick-prev:hover i, #testsieger .slick-prev:hover i {
  opacity: 0.8;
}
.as-slider .slick-next, #header--slider .slick-next, .slider-for .slick-next, #testsieger .slick-next {
  position: absolute;
  z-index: 99;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  cursor: pointer;
}
.as-slider .slick-next i, #header--slider .slick-next i, .slider-for .slick-next i, #testsieger .slick-next i {
  font-size: 5rem;
  color: white;
  opacity: 0.5;
  transition: 0.3s ease;
}
.as-slider .slick-next:hover i, #header--slider .slick-next:hover i, .slider-for .slick-next:hover i, #testsieger .slick-next:hover i {
  opacity: 0.8;
}
.as-slider .slider-comment, #header--slider .slider-comment, .slider-for .slider-comment, #testsieger .slider-comment {
  position: absolute;
  opacity: 0.7;
  bottom: 2em;
  right: 0;
  z-index: 9999;
  width: 70%;
  padding: 2em;
}
@media (max-width: 1040px) {
  .as-slider .slider-comment, #header--slider .slider-comment, .slider-for .slider-comment, #testsieger .slider-comment {
    width: 100%;
    bottom: 0;
  }
}
.as-slider .slider-comment .comment, #header--slider .slider-comment .comment, .slider-for .slider-comment .comment, #testsieger .slider-comment .comment {
  font-size: 1.4rem;
  color: white;
  font-style: italic;
  margin-bottom: 10px;
  line-height: 1.2;
  font-weight: 300;
}
@media (max-width: 1000px) {
  .as-slider .slider-comment .comment, #header--slider .slider-comment .comment, .slider-for .slider-comment .comment, #testsieger .slider-comment .comment {
    font-size: 1.5rem;
  }
}
@media (max-width: 600px) {
  .as-slider .slider-comment .comment, #header--slider .slider-comment .comment, .slider-for .slider-comment .comment, #testsieger .slider-comment .comment {
    font-size: 0.9rem;
  }
}
.as-slider .slider-comment .name, #header--slider .slider-comment .name, .slider-for .slider-comment .name, #testsieger .slider-comment .name {
  font-weight: 300;
  color: white;
  margin-bottom: 0;
  font-style: italic;
  font-size: 1rem;
  line-height: 1.2;
}
@media (max-width: 600px) {
  .as-slider .slider-comment .name, #header--slider .slider-comment .name, .slider-for .slider-comment .name, #testsieger .slider-comment .name {
    font-size: 0.9rem;
  }
}

#header--slider {
  background: url("../img/img-augenblick1.jpg") center;
  background-size: cover;
}

@media (max-width: 960px) {
  .testsieger--section .fw--image-container {
    min-height: 0 !important;
    height: auto !important;
  }
}

#xmas {
  background: #efefef;
  background: #003F8F;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
#xmas img {
  max-height: 100%;
  max-width: 100%;
}

#testsieger {
  background: url("../img/img-VisionCare_lounge.jpg") center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  transform: none;
  left: 0;
  top: 0;
}
#testsieger img {
  width: 500px;
  max-width: 60%;
}
@media (max-width: 960px) {
  #testsieger {
    height: auto;
  }
  #testsieger img {
    max-width: 50%;
  }
}

.glass-slider, .grid--slider {
  visibility: hidden !important;
  display: none !important;
}
.glass-slider.slick-initialized, .grid--slider.slick-initialized {
  display: block !important;
  visibility: visible !important;
}

.slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.slick-dots li {
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  background: white;
  border: 1px solid #003F8F;
  display: block;
  margin: 0 0.3rem;
  cursor: pointer;
}
.slick-dots li.slick-active {
  background: #003F8F;
}
.slick-dots li * {
  display: none;
}
.slick-dots li:marker {
  display: none;
}

.as-btn, .as-btn-white {
  font-weight: 100;
  color: white;
  background: #003F8F;
  padding: 14px 45px 14px 24px;
  text-decoration: none;
  position: relative;
  transition: 0.2s ease-in-out;
  margin-right: 30px;
  display: inline-block;
  font-size: 1rem;
  letter-spacing: 0;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  margin-right: 15px;
  margin-bottom: 15px;
}
@media (max-width: 500px) {
  .as-btn, .as-btn-white {
    margin-bottom: 20px;
    padding: 12px 38px 14px 16px;
    font-size: 0.8rem;
    margin-right: 11px;
  }
}
.as-btn::after, .as-btn-white::after {
  content: url("../img/arrow-right.svg");
  display: inline-block;
  position: absolute;
  right: 20px;
  height: 20px;
  width: 10px;
  top: 52%;
  transform: translateY(-10px);
  transition: 0.2s ease-in-out;
}
.as-btn:hover, .as-btn-white:hover {
  opacity: 0.9;
}
.as-btn:hover::after, .as-btn-white:hover::after {
  right: 15px;
}
.as-btn-white {
  background: white;
  color: #003F8F !important;
}
.as-btn-white::after {
  content: url("../img/arrow-right-blue.svg");
}

.two-liner {
  display: block;
  margin: 0 auto;
  max-width: 250px;
}

h1, .heading {
  font-weight: 100;
  color: #003F8F;
  font-size: 5.4rem;
  margin-bottom: 30px;
  line-height: 1;
  font-family: Roboto, sans-serif;
  font-weight: 100;
  text-align: left;
  text-transform: uppercase;
}
@media (max-width: 1300px) {
  h1, .heading {
    font-size: 4.236rem;
  }
}
@media (max-width: 700px) {
  h1, .heading {
    font-size: 2.618rem;
  }
}

.thin {
  font-weight: 100;
  letter-spacing: 0.05rem;
}

.bold {
  font-weight: 700 !important;
}

.uppercase {
  text-transform: uppercase;
}

.large__p {
  font-size: 1.4rem;
}

a {
  color: inherit;
  font: inherit;
}

h2:not(.heading) {
  font-family: Roboto, sans-serif;
  font-weight: 100;
  color: #003F8F;
  font-size: 2.618rem;
  margin: 80px auto;
  text-align: center;
  width: 300px;
  text-transform: uppercase;
}
@media (max-width: 700px) {
  h2:not(.heading) {
    font-size: 1.618rem;
    margin: 50px auto;
  }
}

h3 {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  color: #003F8F;
  font-size: 1.618rem;
}
@media (max-width: 740px) {
  h3 {
    font-size: 1.2rem;
  }
}
h3.caption {
  margin-bottom: 50px;
  position: relative;
  padding-left: 3rem;
}
@media (max-width: 1300px) {
  h3.caption {
    margin-bottom: 30px;
  }
}
@media (max-width: 740px) {
  h3.caption {
    padding-left: 0rem;
    margin-bottom: 10px;
  }
}
h3.caption::before {
  position: absolute;
  content: "";
  display: inline-block;
  background: #003F8F;
  width: 2rem;
  height: 3px;
  top: 14px;
  left: 0;
  margin: 3px 20px 3px 3px;
}
@media (max-width: 740px) {
  h3.caption::before {
    top: 8px;
    display: none;
  }
}

h4 {
  font-family: Roboto, sans-serif;
  font-weight: 100;
  color: #003F8F;
  font-size: 2.618rem;
  margin-bottom: 20px;
  line-height: 1.2;
  text-transform: uppercase;
}
@media (max-width: 940px) {
  h4 {
    font-size: 1.618rem !important;
  }
}

h5 {
  font-family: Roboto, sans-serif;
  font-weight: 100;
  color: #003F8F;
  font-size: 1.2rem;
  margin: 15px 0px 15px 15px;
  position: relative;
}
h5::after {
  content: url("../img/arrow-right-blue.svg");
  display: inline-block;
  position: absolute;
  right: 14px;
  height: 20px;
  width: 10px;
  top: 50%;
  transform: translateY(-10px);
  transition: 0.2s ease-in-out;
}

h6 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  text-align: center;
  color: white;
  margin-bottom: 20px;
  font-family: PT Sans Narrow, "Roboto Condensed", sans-serif;
}

p {
  font-size: 1rem;
  color: #2E2E2E;
  margin-bottom: 50px;
  line-height: 1.6;
  font-weight: 400;
  max-width: 700px;
}

.fs240 {
  font-size: 240%;
}

.top--nav {
  display: flex;
  padding: 0 4%;
  height: 80px;
  justify-content: space-between;
  border-bottom: 2px solid #003F8F;
  width: 100%;
  position: fixed;
  background: white;
  z-index: 99999;
  top: 0;
}
.top--nav .logos {
  display: flex;
  z-index: 99999999;
}
.top--nav .logos #amon-logo {
  margin-top: -3px;
  height: 145px;
}
@media (max-width: 1040px) {
  .top--nav .logos #amon-logo {
    height: 80px;
    top: 0;
  }
}
@media (max-width: 500px) {
  .top--nav .logos #amon-logo {
    height: 70px;
    top: 0;
  }
}
.top--nav .logos #amon-slogan {
  height: 24px;
  margin-top: 29px;
  margin-left: 20px;
}
@media (max-width: 1580px) {
  .top--nav .logos #amon-slogan {
    display: none;
  }
}
@media (max-width: 1830px) {
  .top--nav .logos #amon-slogan {
    display: block;
    left: 200px;
    height: 20px;
    margin-top: 28px;
    margin-left: 20px;
  }
}
@media (max-width: 500px) {
  .top--nav .logos #amon-slogan {
    display: block;
    left: 200px;
    height: 15px;
    margin-top: 20px;
    margin-left: 20px;
  }
}
@media (max-width: 500px) {
  .top--nav {
    height: 60px;
  }
}
.top--nav #nav-wrapper {
  display: inline-flex;
  align-items: flex-end;
  height: 100%;
}
@media (max-width: 1830px) {
  .top--nav #nav-wrapper {
    display: none;
  }
}
.top--nav #nav-wrapper .nav-left {
  display: flex;
  height: 100%;
  align-items: flex-end;
}
.top--nav #nav-wrapper .nav-left li {
  list-style: none;
  cursor: pointer;
  padding-bottom: 25px;
}
.top--nav #nav-wrapper .nav-left li a {
  color: black;
  text-decoration: none;
  padding: 30px 20px 15px 15px;
  margin-right: 5px;
  font-size: 1rem;
  font-weight: 300;
  transition: 0.2s ease;
  cursor: pointer;
  position: relative;
}
@media (max-width: 1370px) {
  .top--nav #nav-wrapper .nav-left li a {
    margin-right: 9px;
  }
}
.top--nav #nav-wrapper .nav-left li a:hover, .top--nav #nav-wrapper .nav-left li a.active {
  color: #003F8F;
}
.top--nav #nav-wrapper .nav-left li a.active {
  font-weight: 400;
}
.top--nav #nav-wrapper .nav-left li a::after {
  content: url("../img/arrow-down.svg");
  position: absolute;
  transition: all 200ms ease;
  transform-origin: center;
  top: 27px;
  padding-left: 5px;
}
.top--nav #nav-wrapper .nav-left li .subnav {
  overflow: hidden;
  display: flex;
  align-items: stretch;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  width: 100%;
  left: 0;
  z-index: 9999;
  position: fixed;
  background: #003F8F;
  justify-content: space-evenly;
  padding-left: 17vw;
  transition: all 300ms ease;
  top: 78px;
  transform: scaleY(0);
  transform-origin: top center;
}
@media (max-width: 1830px) {
  .top--nav #nav-wrapper .nav-left li .subnav {
    display: none;
  }
}
.top--nav #nav-wrapper .nav-left li .subnav a {
  flex: 1 0 0;
  color: white;
  padding: 20px 10px !important;
  text-decoration: none;
  transition: 0.3s ease;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #003F8F;
}
.top--nav #nav-wrapper .nav-left li .subnav a:hover {
  color: white;
  background: #275a9b;
}
.top--nav #nav-wrapper .nav-left li .subnav a::after {
  display: none;
}
.top--nav #nav-wrapper .nav-left li:last-child a {
  margin-right: 30px;
}
.top--nav #nav-wrapper .nav-left li.active .nav--item {
  color: #003F8F;
}
.top--nav #nav-wrapper .nav-left li.active .nav--item:after {
  transform: rotate(180deg);
  padding-right: 5px;
  top: 32px;
}
.top--nav #nav-wrapper .nav-left li.active .subnav {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transform: scaleY(1);
}
.top--nav #nav-wrapper .nav-right {
  display: flex;
  align-self: center;
}
.top--nav #nav-wrapper .nav-right a {
  padding: 15px 15px;
  margin-left: 2px;
  background: #003F8F;
  color: white;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  font-weight: 400;
}
.top--nav #nav-wrapper .nav-right a:hover {
  background: #004aa9;
}
.top--nav #nav-wrapper .nav-right a i {
  margin-right: 10px;
}
.top--nav .nav-burger {
  height: 30px;
  width: 20px;
  align-self: center;
  display: none;
  position: relative;
  cursor: pointer;
}
@media (max-width: 1830px) {
  .top--nav .nav-burger {
    display: block;
  }
}
.top--nav .nav-burger span {
  width: 100%;
  transition: all 300m ease;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(0);
  height: 2px;
  border-radius: 3px;
  background: #333;
  transition: all 300ms ease;
  transform-origin: center;
}
.top--nav .nav-burger span:first-child {
  top: 30%;
}
.top--nav .nav-burger span:nth-child(3) {
  top: 70%;
}

.link--pointer {
  cursor: pointer;
}

.nav-drill {
  z-index: 999999 !important;
  box-shadow: -10px 5px 10px rgba(0, 0, 0, 0.25);
}
.nav-drill .nav-items li {
  list-style: none;
}
.nav-drill .nav-items li a {
  text-decoration: none;
}

#nav-mobile-wrap {
  position: fixed;
  z-index: 9;
  width: 100vw;
  height: 100%;
}
#nav-mobile-wrap #nav-mobile {
  background: rgba(255, 255, 255, 0.9);
  display: none;
  transition: all 0.5s ease;
  height: 100vh;
}

.display--block {
  display: block !important;
}

.stop--scroll {
  overflow: hidden !important;
}

.mobile__phone {
  text-decoration: none;
  color: #003F8F;
  display: flex;
  align-items: center;
}
.mobile__phone i {
  margin-right: 0.5rem;
}
@media (min-width: 1830px) {
  .mobile__phone {
    display: none;
  }
}

.nav-drill {
  margin-top: 80px;
  transform: translateX(150%);
}

@media (max-width: 500px) {
  .nav-drill {
    margin-top: 60px;
  }
}
.nav-is-toggled .nav-drill {
  transform: translateX(0);
}
.nav-is-toggled::after {
  opacity: 1;
  visibility: visible;
}

.nav-drill {
  display: flex;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  width: 250px;
  height: 100vh;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  transition: 0.45s;
}
.nav-items {
  flex: 0 0 100%;
}
.nav-item:not(:last-child) {
  border-bottom: solid 1px #003F8F;
}
.nav-link {
  display: block;
  padding: 0.875em 1em;
  background-color: #fff;
  color: #003F8F;
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 300;
}
.nav-expand-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(100%);
  background-color: white;
  transition: 0.3s;
  visibility: hidden;
}
.nav-expand-content .nav-item:not(:last-child) {
  border-bottom: solid 1px #003F8F;
}
.nav-expand-content .nav-link {
  background-color: #8fc0ff;
}
.nav-expand-content .nav-back-link {
  display: flex;
  align-items: center;
  background-color: #003F8F !important;
  color: #fff;
}
.nav-expand-content .nav-back-link::before {
  content: "chevron_left";
  margin-right: 0.5em;
  font-family: "Material Icons";
}
.nav-expand-link {
  display: flex;
  justify-content: space-between;
}
.nav-expand-link::after {
  content: "chevron_right";
  flex: 0 1 auto;
  font-family: "Material Icons";
}
.nav-expand.active > .nav-expand-content {
  transform: translateX(0);
  visibility: visible;
}
.nav-expand .nav-expand-content {
  background-color: white;
}
.nav-expand .nav-expand-content .nav-link {
  background-color: white;
}
.nav-expand .nav-expand-content .nav-expand-content {
  background-color: #5ca4ff;
}
.nav-expand .nav-expand-content .nav-expand-content .nav-link {
  background-color: #5ca4ff;
}
.nav-expand .nav-expand-content .nav-expand-content .nav-expand-content {
  background-color: #2987ff;
}
.nav-expand .nav-expand-content .nav-expand-content .nav-expand-content .nav-link {
  background-color: #2987ff;
}
.nav-expand .nav-expand-content .nav-expand-content .nav-expand-content .nav-expand-content {
  background-color: #006cf5;
}
.nav-expand .nav-expand-content .nav-expand-content .nav-expand-content .nav-expand-content .nav-link {
  background-color: #006cf5;
}

.nav-interaction-items {
  border-bottom: 1px solid white !important;
}
.nav-interaction-items a {
  background-color: #003F8F !important;
  color: white;
}

.nav-is-toggled .nav-burger span:first-child {
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}
.nav-is-toggled .nav-burger span:nth-child(2) {
  width: 0;
}
.nav-is-toggled .nav-burger span:nth-child(3) {
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .nav-back-link:before {
    width: 0;
  }
}
.mobile-nav {
  display: flex;
}
.mobile-nav .nav-burger {
  margin-left: 45px;
}
.mobile-nav .mobile-nav-icons {
  display: none;
}
.mobile-nav .mobile-nav-icons a {
  background: #003F8F;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 60px;
  margin-right: 2px;
  text-decoration: none;
}
.mobile-nav .mobile-nav-icons a i {
  color: white;
  font-size: 1.2rem;
}
.mobile-nav .mobile-nav-icons a:hover {
  background: #004aa9;
}
@media (max-width: 1830px) {
  .mobile-nav .mobile-nav-icons {
    display: flex;
  }
}
@media (max-width: 550px) {
  .mobile-nav .nav-burger {
    margin-left: 30px;
  }
  .mobile-nav .mobile-nav-icons {
    display: flex;
  }
  .mobile-nav .mobile-nav-icons a {
    width: 40px;
    background: inherit;
  }
  .mobile-nav .mobile-nav-icons a i {
    color: #003F8F;
  }
}

.icon--plus {
  text-decoration: none;
  position: absolute;
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  top: -50%;
  left: -15%;
  text-align: center;
  cursor: pointer;
  transition: all 300ms ease;
  transform-origin: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon--plus span {
  font-size: 4em;
  font-weight: 600;
  color: #003F8F;
}
.icon--plus:hover {
  transform: scale(1.2);
  background: rgba(255, 255, 255, 0.9);
}
@media (max-width: 1040px) {
  .icon--plus {
    left: 15px;
    width: 50px;
    height: 50px;
    top: -60px;
  }
  .icon--plus span {
    font-size: 3em;
  }
}

footer {
  background: white;
  border-top: 2px solid #003F8F;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  position: relative;
  z-index: 1;
}
footer .impressum {
  margin-right: 40px;
}
footer .impressum a {
  text-decoration: none;
  color: #003F8F;
  transition: 0.2s ease;
}
footer .impressum a:hover {
  opacity: 0.8;
}
footer .social-icons {
  margin-right: 40px;
  display: flex;
}
footer .social-icons a {
  margin-right: 15px;
  text-decoration: none;
  display: block;
  min-width: 20px;
}
footer .social-icons a:hover {
  opacity: 0.8;
}
footer .backtotop {
  transition: 0.2s ease;
}
footer .backtotop:hover {
  margin-bottom: 10px;
}
@media (max-width: 540px) {
  footer {
    display: block;
    text-align: center;
  }
  footer .social-icons {
    margin: 2rem auto;
    justify-content: center;
  }
}
@media (max-width: 740px) {
  footer {
    justify-content: center;
  }
  footer * {
    margin-right: 0 !important;
  }
  footer .impressum {
    flex: 1 0 30%;
    min-width: 200px;
  }
  footer .social-icons {
    flex: 1 0 20%;
    text-align: center;
  }
  footer .social-icons a {
    margin: 0 10px;
  }
  footer .backtotop {
    flex: 1 0 10%;
    text-align: right;
  }
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.97);
  z-index: 999999;
  padding: 2.7rem 0 0 0;
  overflow-y: auto;
  display: none;
}
.modal.active {
  display: block;
}
.modal--content {
  width: 100%;
  max-width: 50rem;
  display: block;
  margin: 0 auto;
}
.modal--content .close {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-weight: bold;
  background: #003F8F;
  color: white;
  padding: 0.5rem 1rem;
}
.modal--content img {
  width: 100%;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
}

.check--list {
  margin: 0;
  list-style-type: none;
}
.check--list li {
  position: relative;
  margin-bottom: 15px;
  font-size: 1rem;
  font-weight: 400;
  margin-left: 30px;
}
.check--list li:before {
  content: "";
  background: url("../img/icn--check.svg") center no-repeat;
  width: 20px;
  height: 20px;
  background-size: contain;
  position: absolute;
  top: 0;
  left: -30px;
}
.check--list li strong {
  font-weight: 600;
  color: #003F8F;
}

.icon--list {
  margin: 30px 0;
  list-style-type: none;
}
.icon--list li {
  position: relative;
  margin-bottom: 2rem;
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.icon--list li strong {
  font-weight: 600;
  color: #003F8F;
}
.icon--list li .icon {
  position: relative;
  width: 60px;
  min-width: 60px;
  margin-right: 1rem;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .icon--list li .icon {
    width: auto;
    min-width: auto;
  }
}
@media (max-width: 740px) {
  .icon--list li {
    display: block;
  }
  .icon--list li .icon {
    margin: 10px auto;
    display: block;
    width: 80px;
  }
}
@media (max-width: 740px) and (-ms-high-contrast: none), (max-width: 740px) and (-ms-high-contrast: active) {
  .icon--list li .icon {
    width: auto;
  }
}

.plus--list {
  margin: 0;
  list-style-type: none;
  max-width: 1100px;
}
.plus--list li {
  position: relative;
  margin-bottom: 10px;
  font-size: 1rem;
  font-weight: 400;
  margin-left: 20px;
}
.plus--list li:before {
  content: "+";
  width: 20px;
  height: 20px;
  background-size: contain;
  position: absolute;
  top: 0;
  left: -20px;
}
.plus--list li strong {
  font-weight: 600;
  color: #003F8F;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: 300;
}

html, body {
  height: 100%;
  font-size: 100%;
  line-height: 1.4;
  background: white;
}

body {
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  body {
    display: block;
  }
}

.clearfix::after {
  content: ".";
  display: block;
  clear: both;
  font-size: 0;
  height: 0;
  visibility: hidden;
}

@media (min-width: 960px) {
  .mobile {
    display: none;
  }
}
.flex-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.col-1-3 {
  flex: 1 0 0;
}
@media (max-width: 800px) {
  .col-1-3 {
    min-width: calc(50% - 20px);
    max-width: calc(50% - 20px);
    margin-right: 10px !important;
    margin-bottom: 40px;
  }
}
@media (max-width: 600px) {
  .col-1-3 {
    min-width: 100%;
    max-width: 100%;
    margin-right: 0px !important;
    margin-bottom: 40px;
  }
}

.col-1-4 {
  flex: 1 0 20%;
  margin-right: 20px !important;
  margin-bottom: 20px;
}
@media (max-width: 1050px) {
  .col-1-4 {
    flex: 1 0 45%;
    margin-right: 20px !important;
  }
  .col-1-4:nth-child(even) {
    margin-right: 0 !important;
  }
}
@media (max-width: 540px) {
  .col-1-4 {
    flex: 1 0 100%;
    margin-right: 0px !important;
  }
}

.col-1 {
  flex: 1 0 0;
}

.col-2 {
  flex: 2 0 0;
}

.col-3 {
  flex: 3 0 0;
  min-width: 430px;
}
@media (max-width: 500px) {
  .col-3 {
    min-width: 320px;
  }
}

.col-4 {
  flex: 4 0 0;
  min-width: 550px;
}
@media (max-width: 500px) {
  .col-4 {
    min-width: 320px;
  }
}

.subheadline {
  position: relative;
  text-align: center;
}
.subheadline::before {
  content: "";
  position: absolute;
  height: 1px;
  background: #003F8F;
  top: 52%;
  left: 0;
  right: 0;
  z-index: -999;
}
.subheadline h2 {
  z-index: 1;
  position: relative;
  display: inline-block;
  width: auto;
}
.subheadline h2:before {
  z-index: -9990;
  content: "";
  position: absolute;
  width: calc(100% + 20px);
  height: 100%;
  left: -10px;
  top: 0;
  background: white;
}
@media (max-width: 600px) {
  .subheadline h2 {
    font-size: 2.4rem;
  }
}

.mw-1200 {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}

.mw-1600 {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}

.mw-1800 {
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}

.no-align {
  align-items: stretch;
}

.news-box {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.centered {
  margin: 0 auto;
  text-align: center;
  position: relative;
}
.centered p {
  margin-left: auto;
  margin-right: auto;
}

.mt6 {
  margin-top: 6em;
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}
.mx-20.mw-1600 {
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1600px) {
  .mx-20.mw-1600 {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.p8 {
  padding: 8em 0;
}

.fullwidth {
  width: 100%;
}

.centered--content {
  margin: 0 auto;
  padding: 60px 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.centered--content h1, .centered--content p {
  max-width: 900px;
  margin: 0 center;
  margin-bottom: 0;
  text-align: center;
  position: relative;
}
.centered--content p {
  margin-bottom: 0;
}
@media (max-width: 960px) {
  .centered--content {
    align-items: flex-start;
  }
  .centered--content h1, .centered--content p {
    text-align: left;
  }
}

.mw-900 {
  max-width: 900px;
}
.mw-900 p {
  max-width: 100%;
}

.white {
  background: #fff;
  position: relative;
}

.headline--left {
  padding: 5vw 30px;
  max-width: 1800px;
}
.headline--left h1, .headline--left .h1 {
  margin: 0;
  max-width: 1100px;
  margin-bottom: 20px;
}
.headline--left p {
  margin-top: 40px;
  margin-bottom: 0;
  max-width: 1100px;
}
@media (max-width: 740px) {
  .headline--left {
    padding: 5vw 20px;
  }
  .headline--left h1, .headline--left .h1 {
    margin-top: 30px;
  }
}

.headline--centered {
  padding: 5vw 30px;
  max-width: 1800px;
  text-align: center;
}
.headline--centered h1 {
  margin: 0 auto;
  max-width: 1100px;
  text-align: center;
  margin-bottom: 20px;
}
.headline--centered strong {
  font-weight: 600;
}
.headline--centered p {
  margin: 40px auto 0;
  max-width: 1100px;
}
@media (max-width: 740px) {
  .headline--centered {
    padding: 5vw 20px;
  }
  .headline--centered h1 {
    margin-top: 30px;
  }
}

.divider {
  height: 80px;
}

.divider--line {
  margin: 40px auto 80px;
  width: 70%;
  max-width: 600px;
  height: 2px;
  background: #eaeaea;
}

.mw-100 {
  min-width: 100%;
}

.delay-100 {
  transition-delay: 100ms !important;
}

.grey {
  background: #f7f7f7;
}

.blue {
  background: #003F8F;
}

.image--slider-thumb {
  padding: 20px;
  position: relative;
  display: flex;
  align-items: center;
  background: #eaeaea;
}
.image--slider-thumb img {
  width: 100%;
}

.mr-20 {
  margin-right: 40px;
}

.placeholder {
  position: relative;
}
.placeholder:after {
  content: "Platzhalter";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: orangered;
  color: white;
  padding: 10px 15px;
}

@media (max-width: 500px) {
  #content {
    padding-top: 60px !important;
  }
}

.target-relative {
  position: relative;
}
.target-relative .target {
  position: absolute;
  left: 0;
  top: -80px;
}
@media (max-width: 500px) {
  .target-relative .target {
    top: -60px;
  }
}

.fw--image-left, .fw--image-right {
  width: 100%;
  max-width: 100vw;
  display: flex;
  flew-wrap: wrap;
  min-height: 100vh;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fw--image-left, .fw--image-right {
    min-height: auto !important;
  }
}
.fw--image-left.intro--section, .fw--image-right.intro--section {
  min-height: 800px;
  height: calc(100vh - 1px);
}
.fw--image-left .fw--image-container, .fw--image-right .fw--image-container {
  flex: 1 0 58%;
  overflow: hidden;
  position: relative;
}
.fw--image-left .fw--image-container .fw--image, .fw--image-left .fw--image-container iframe, .fw--image-right .fw--image-container .fw--image, .fw--image-right .fw--image-container iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-size: cover;
  background-position: center;
  transform-origin: center;
  transition: all 1000ms ease;
}
.fw--image-left .fw--image-container .fw--image.alter, .fw--image-left .fw--image-container iframe.alter, .fw--image-right .fw--image-container .fw--image.alter, .fw--image-right .fw--image-container iframe.alter {
  background-image: url(../img/img-alterssichtigkeit.jpg);
}
.fw--image-left .fw--image-container .fw--image.colors, .fw--image-left .fw--image-container iframe.colors, .fw--image-right .fw--image-container .fw--image.colors, .fw--image-right .fw--image-container iframe.colors {
  background-image: url(../img/img-lustaufmode.jpg);
}
.fw--image-left .fw--image-container .fw--image.gl, .fw--image-left .fw--image-container iframe.gl, .fw--image-right .fw--image-container .fw--image.gl, .fw--image-right .fw--image-container iframe.gl {
  background-image: url(../img/gleitsichtexperte.jpg);
}
.fw--image-left .fw--image-container .fw--image.dryeye, .fw--image-left .fw--image-container iframe.dryeye, .fw--image-right .fw--image-container .fw--image.dryeye, .fw--image-right .fw--image-container iframe.dryeye {
  background-image: url(../img/dryeye.jpg);
}
.fw--image-left .fw--image-container .fw--image.dryeye1, .fw--image-left .fw--image-container iframe.dryeye1, .fw--image-right .fw--image-container .fw--image.dryeye1, .fw--image-right .fw--image-container iframe.dryeye1 {
  background-image: url(../img/dryeye-intro.jpg);
}
.fw--image-left .fw--image-container .fw--image.kinderoptometrie, .fw--image-left .fw--image-container iframe.kinderoptometrie, .fw--image-right .fw--image-container .fw--image.kinderoptometrie, .fw--image-right .fw--image-container iframe.kinderoptometrie {
  background-image: url(../img/kinderoptometrie/0S9A5464-kopie-3.jpg);
}
.fw--image-left .fw--image-container .fw--image.scaled, .fw--image-left .fw--image-container iframe.scaled, .fw--image-right .fw--image-container .fw--image.scaled, .fw--image-right .fw--image-container iframe.scaled {
  width: 150%;
}
.fw--image-left .fw--text-wrapper, .fw--image-right .fw--text-wrapper {
  flex: 1 0 42%;
  padding: 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 960px) {
  .fw--image-left .fw--text-wrapper, .fw--image-right .fw--text-wrapper {
    padding: 2em;
  }
}
@media (min-width: 960px) {
  .fw--image-left .fw--text-wrapper, .fw--image-right .fw--text-wrapper {
    max-width: 42%;
  }
}
.fw--image-left .fw--text-wrapper .fw--text-content, .fw--image-right .fw--text-wrapper .fw--text-content {
  max-width: 600px;
  width: 100%;
  text-align: left;
  position: relative;
}
.fw--image-left .fw--text-wrapper .fw--text-content h2, .fw--image-left .fw--text-wrapper .fw--text-content h1, .fw--image-right .fw--text-wrapper .fw--text-content h2, .fw--image-right .fw--text-wrapper .fw--text-content h1 {
  text-align: left;
  margin: 0;
  margin-bottom: 30px;
  font-size: 5rem;
  width: 100%;
  line-height: 1 !important;
}
@media (max-width: 1040px) {
  .fw--image-left .fw--text-wrapper .fw--text-content h2, .fw--image-left .fw--text-wrapper .fw--text-content h1, .fw--image-right .fw--text-wrapper .fw--text-content h2, .fw--image-right .fw--text-wrapper .fw--text-content h1 {
    font-size: 2.2rem;
  }
}
@media (max-width: 960px) {
  .fw--image-left, .fw--image-right {
    display: block;
    min-height: 0;
    max-height: none;
    height: auto;
  }
  .fw--image-left .fw--image-container, .fw--image-right .fw--image-container {
    min-height: 320px;
    max-height: 600px;
    height: 80vw;
    order: 1 !important;
  }
  .fw--image-left.intro--section, .fw--image-right.intro--section {
    min-height: 0;
    max-height: none;
    height: auto;
  }
  .fw--image-left.intro--section .fw--image-container, .fw--image-right.intro--section .fw--image-container {
    max-height: none;
    min-height: calc(100vh - 80px);
    height: calc(100vh - 80px);
  }
}
@media (max-width: 960px) and (max-width: 960px) {
  .fw--image-left.intro--section .fw--image-container, .fw--image-right.intro--section .fw--image-container {
    min-height: 50vh;
    height: 50vh;
  }
}

.fw--image-right .fw--image-container {
  order: 2;
}
.fw--image-right .fw--text-wrapper {
  align-items: flex-end;
  order: 1;
}
@media (max-width: 960px) {
  .fw--image-right .fw--text-wrapper {
    align-items: center;
    padding: 2em 1em;
  }
}
.fw--image-right .fw--text-wrapper .fw--text-content {
  max-width: 600px;
}

.fw--image-left .fw--image-container {
  order: 1;
}
.fw--image-left .fw--text-wrapper {
  align-items: flex-start;
  order: 2;
}
@media (max-width: 960px) {
  .fw--image-left .fw--text-wrapper {
    align-items: center;
    padding: 2em 1em;
  }
}
.fw--image-left .fw--text-wrapper .fw--text-content {
  max-width: 600px;
}

.fullheight--header {
  height: calc(100vh - 80px);
  width: 100%;
  background-position: center center;
  background-size: cover;
  position: relative;
}
.fullheight--header .scroll--down {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 740px) {
  .fullheight--header .scroll--down {
    bottom: 30px;
  }
}
.fullheight--header .scroll--down span {
  transform: rotate(45deg) translate(-50%, -50%);
  border: 2px solid #003F8F;
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  border-left: transparent;
  border-top: transparent;
  left: 50%;
  transition: 300ms ease;
}
.fullheight--header .scroll--down span:first-child {
  top: 30%;
}
.fullheight--header .scroll--down span:last-child {
  top: 70%;
  transition-delay: 50ms;
}
.fullheight--header .scroll--down.white {
  background: transparent;
}
.fullheight--header .scroll--down.white span {
  border-color: white;
}
.fullheight--header .scroll--down:hover span:first-child {
  top: 50%;
}
.fullheight--header .scroll--down:hover span:last-child {
  top: 90%;
}
.fullheight--header.ambiente {
  background-image: url("../img/img-store.jpg");
}
.fullheight--header.anpassung {
  background-image: url("../img/img-VisionCare_lounge.jpg");
}
.fullheight--header.twen {
  background-image: url("../img/img-twen25-hero.jpg");
}
.fullheight--header.sportbrille {
  background-image: url("../img/b_sport-header.jpg");
}
.fullheight--header.about {
  background-image: url("../img/philosophie.jpg");
}
.fullheight--header.garantie {
  background-image: url("../img/img-garantie.jpg");
}
.fullheight--header.marken {
  background-image: url("../img/img-marken.jpg");
}
.fullheight--header.gleitsicht {
  background-image: url("../img/Geraet_beim_Optiker.jpg");
  background-position: left 40% top;
}
.fullheight--header.werkstatt {
  background-image: url("../img/img-manufactur.jpg");
}
.fullheight--header.jobs {
  background-image: url("../img/header-jobs.jpg");
}
.fullheight--header.sonnenbrille {
  background-image: url("../img/b_sonnenbrille-header.jpg");
}
.fullheight--header.augenuntersuchung {
  background-image: url("../img/o_augenuntersuchung-header.jpg");
  background-position: right;
}
.fullheight--header.arbeitsplatz {
  background-image: url("../img/img-arbeitsbrille.jpg");
  background-position: top;
}
.fullheight--header.traenenfilm {
  background-image: url("../img/img-traenenfilm.jpg");
}
.fullheight--header.visual-training {
  background-image: url("../img/o_visual-training.jpg");
}
.fullheight--header.visioncare {
  background-image: url("../img/logo-visioncare.svg"), url("../img/img-vision_care.jpg");
  background-position: left 4% bottom 30px, top 10% center;
  background-size: 240px, cover;
  background-repeat: no-repeat;
}
@media (max-width: 740px) {
  .fullheight--header.visioncare {
    background-size: 120px, cover;
  }
}
@media (max-width: 740px) {
  .fullheight--header {
    height: 50vh;
  }
}

.line--top {
  position: relative;
}
.line--top:after {
  content: "";
  background: #003F8F;
  height: 20px;
  width: 50%;
  position: absolute;
  left: 0;
  top: -20px;
}

.info--container .info--wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background: #fff;
  max-width: 1800px;
  margin: 0 auto;
}
.info--container .info--wrapper .info--teaser-content {
  background: #003F8F;
  flex: 1 0 40%;
  padding: 5vw 30px;
  display: flex;
  justify-content: center;
  position: relative;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .info--container .info--wrapper .info--teaser-content {
    flex: 2 0 10%;
  }
  .info--container .info--wrapper .info--teaser-content h4, .info--container .info--wrapper .info--teaser-content h3 {
    max-width: 300px;
  }
}
.info--container .info--wrapper .info--teaser-content .constrained--content {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.info--container .info--wrapper .info--teaser-content .constrained--content h3 {
  color: white;
  font-family: Roboto, sans-serif;
  font-size: 2.618rem;
  font-weight: 200;
  text-align: left;
  padding: 0;
  display: block;
}
.info--container .info--wrapper .info--teaser-content .constrained--content h3:before {
  content: none;
}
.info--container .info--wrapper .info--teaser-content .constrained--content .info--icon {
  width: 260px;
  height: 260px;
  border-radius: 50%;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.info--container .info--wrapper .info--teaser-content .constrained--content .info--icon img {
  width: 60%;
}
.info--container .info--wrapper .info--teaser-content .constrained--content .info--image {
  max-width: 660px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.info--container .info--wrapper .info--teaser-content .constrained--content .info--image img {
  width: 100%;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .info--container .info--wrapper .info--teaser-content .constrained--content .info--image {
    max-width: 300px;
    height: 100%;
  }
}
.info--container .info--text-content {
  background: #eaeaea;
  flex: 2 0 60%;
  padding: 30px 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .info--container .info--text-content {
    flex: 2 0 10%;
  }
}
.info--container .info--text-content h4 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.618rem;
  margin-top: 60px;
  margin-bottom: 10px;
}
.info--container .info--text-content p {
  margin-bottom: 0;
}
@media (max-width: 740px) {
  .info--container .info--wrapper {
    display: block;
  }
  .info--container .info--wrapper .info--teaser-content .constrained--content h3 {
    font-size: 2rem;
  }
  .info--container .info--wrapper .info--teaser-content .constrained--content h3 br {
    display: none;
  }
  .info--container .info--wrapper .info--teaser-content .constrained--content .info--icon {
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
  }
  .info--container .info--wrapper .info--text-content {
    padding: 30px;
  }
  .info--container .info--wrapper .info--text-content .constrained--content p {
    margin-bottom: 0;
  }
}

.content {
  max-width: 1800px;
  margin: 40px auto;
  position: relative;
  padding: 30px;
}
@media (max-width: 740px) {
  .content {
    margin: 0 auto;
  }
}
.content .content--header {
  padding-right: 30px;
}
.content .content--header h2 {
  width: 100%;
  max-width: none;
  text-align: left;
  margin: 0;
  font-size: 2.618rem;
  margin-bottom: 20px;
  line-height: 1.2;
}
@media (max-width: 740px) {
  .content .content--header h2 {
    font-size: 2rem;
  }
}
.content .content--header h4 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.618rem;
}

.content--double h4 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.4rem;
}
.content--double ol {
  margin: 20px 0;
}
@media (max-width: 1400px) {
  .content--double .flex-row {
    display: block;
  }
}

.image--wrapper {
  width: 100%;
  position: relative;
  min-width: 250px;
  margin-bottom: 20px;
  overflow: hidden;
  padding-bottom: 100%;
}
.image--wrapper:after {
  content: "";
  width: 61.8%;
  height: 6px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #003F8F;
}
.image--wrapper img {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transition: all 800ms ease;
  transform: translate(-50%, -50%);
}
.image--wrapper img.scaled {
  width: 150%;
}
.image--wrapper span {
  position: absolute;
  left: 30px;
  bottom: 30px;
  font-size: 2rem;
  color: #003F8F;
}

.counter--reset {
  counter-reset: section;
  margin-right: -30px;
}
.counter--reset div[class^=col-] {
  padding-right: 40px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .counter--reset .col-1 {
    min-width: 280px;
  }
}

ol {
  list-style-type: none;
  margin: 20px 0;
}
ol li {
  margin-left: 25px;
  margin-bottom: 20px;
  position: relative;
  font-weight: 400;
}
ol li:before {
  counter-increment: section;
  content: counters(section, ". ") ". ";
  color: #003F8F;
  position: absolute;
  left: -25px;
  top: -0.2rem;
  font-weight: 600;
  font-size: 1.2rem;
}
ol li em {
  color: #003F8F;
  font-style: normal;
  font-weight: 400;
}

.content--large-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.content--large-content img {
  width: 100%;
  max-width: 400px;
  height: auto;
  margin-top: -150px;
  align-self: flex-end;
  transition: all 800ms ease;
  transition-delay: 0ms;
  transform: translateX(0);
  position: relative;
}
.content--large-content img.out {
  transform: translateX(300px);
}
.content--large-content h3 {
  font-family: Roboto, sans-serif;
  margin: 0;
  padding: 0;
  font-weight: 300;
  font-size: 2rem;
  max-width: 300px;
  align-self: flex-start;
  position: relative;
  z-index: 1;
}
.content--large-content h3:before {
  content: none;
}
@media (max-width: 1100px) {
  .content--large-content {
    margin-top: 30px;
    min-width: 100%;
  }
  .content--large-content img {
    margin-top: -5vw;
  }
  .content--large-content h3 {
    max-width: 650px;
    font-size: 1.4rem;
    width: 100%;
  }
}

.grey--container {
  padding: 30px;
  margin-top: 30px;
  width: 100%;
  background: #eaeaea;
}
.grey--container p {
  width: 100%;
  margin: 0;
  max-width: 1000px;
}

.image--grid {
  width: 100%;
  margin-top: 20px;
  position: relative;
}
.image--grid .fas.fa-info-circle {
  cursor: pointer;
  display: none;
  z-index: 2;
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 1.7rem;
  color: white;
}
.image--grid .fas.fa-info-circle:hover + .brand--overlay-description-popup, .image--grid .fas.fa-info-circle:active + .brand--overlay-description-popup {
  display: block;
}
@media (max-width: 640px) {
  .image--grid .fas.fa-info-circle:hover + .brand--overlay-description-popup, .image--grid .fas.fa-info-circle:active + .brand--overlay-description-popup {
    display: none;
  }
}
@media (max-width: 640px) {
  .image--grid .fas.fa-info-circle {
    display: block;
  }
}
.image--grid .brand--overlay-description-popup {
  display: none;
}
.image--grid .brand--overlay-description-popup p {
  color: white;
  margin-bottom: 0;
  font-size: 0.8rem;
}
.image--grid .brand--overlay-description-popup .popup--close {
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 1.7rem;
  color: white;
  color: #003F8F;
  cursor: pointer;
  font-weight: bold;
  line-height: 0;
}
@media (max-width: 640px) {
  .image--grid .brand--overlay-description-popup {
    z-index: 10000;
    padding: 3rem;
    background: rgba(0, 63, 143, 0.95);
    position: fixed;
    top: 100px;
    overflow-y: auto;
    align-items: center;
    left: 20px;
    right: 20px;
    bottom: 20px;
    box-shadow: 0 0 5rem 5rem white;
  }
  .image--grid .brand--overlay-description-popup.active {
    display: flex;
  }
}
@media (max-width: 540px) {
  .image--grid .brand--overlay-description-popup {
    padding: 2rem;
  }
}
.image--grid .image--grid-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.image--grid .image--grid-row:nth-child(3n+2) .image--slider {
  order: 3;
}
.image--grid .image--grid-row:nth-child(3n+2) .brand-item {
  order: 1;
}
.image--grid .image--grid-row:nth-child(3n+2) .image--wrapper {
  order: 2;
}
.image--grid .image--grid-row:nth-child(3n+3) .image--slider {
  order: 2;
}
.image--grid .image--grid-row:nth-child(3n+3) .brand-item {
  order: 3;
}
.image--grid .image--grid-row:nth-child(3n+3) .image--wrapper {
  order: 1;
}
@media (max-width: 640px) {
  .image--grid .image--grid-row {
    margin-bottom: 20px;
  }
}
.image--grid .image--grid-row .image--grid-item {
  flex: 1 0 30%;
  max-width: 33.333333333%;
  background: #eaeaea;
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media (min-width: 1240px) {
  .image--grid .image--grid-row .image--grid-item {
    height: 30vw;
    max-height: 500px;
  }
}
@media (max-width: 1300px) {
  .image--grid .image--grid-row .image--grid-item.image--slider {
    order: 3;
    flex: 1 0 100%;
    min-width: 100%;
    max-width: 100%;
  }
  .image--grid .image--grid-row .image--grid-item.brand-item {
    order: 1;
    flex: 1 0 50%;
    max-width: 100%;
    height: 40vw;
    min-height: 300px;
  }
  .image--grid .image--grid-row .image--grid-item.brand-item p {
    font-size: 0.925rem;
  }
}
@media (max-width: 1300px) and (max-width: 1000px) {
  .image--grid .image--grid-row .image--grid-item.brand-item p {
    font-size: 0.8rem;
  }
}
@media (max-width: 1300px) {
  .image--grid .image--grid-row .image--grid-item.image--wrapper {
    order: 2;
    flex: 1 0 50%;
    max-width: 100%;
    height: 40vw;
    min-height: 300px;
  }
}
@media (max-width: 640px) {
  .image--grid .image--grid-row .image--grid-item.image--slider {
    order: 3;
    flex: 1 0 100%;
    min-width: 100%;
    max-width: 100%;
  }
  .image--grid .image--grid-row .image--grid-item.brand-item {
    order: 1;
    flex: 1 0 25%;
    max-width: 100%;
    min-height: 150px;
    height: 40vw;
  }
  .image--grid .image--grid-row .image--grid-item.brand-item .plus-grid {
    display: none;
  }
  .image--grid .image--grid-row .image--grid-item.image--wrapper {
    order: 2;
    flex: 1 0 25%;
    max-width: 100%;
    min-height: 150px;
    height: 40vw;
  }
}
.image--grid .image--grid-row .image--grid-item .grid--brille {
  max-width: 100%;
  width: 100%;
}
.image--grid .image--grid-row .image--grid-item .grid--brille img {
  max-width: 100%;
}
.image--grid .image--grid-row .image--grid-item .grid--slider {
  max-width: 100%;
  position: relative;
}
.image--grid .image--grid-row .image--grid-item .grid--slider .slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 10;
}
.image--grid .image--grid-row .image--grid-item .grid--slider .slick-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 10;
}
.image--grid .image--grid-row .image--grid-item figcaption {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
}
.image--grid .image--grid-row .image--grid-item figcaption .left--content {
  flex: 2 0 0;
}
.image--grid .image--grid-row .image--grid-item figcaption .left--content .name {
  font-family: Roboto, sans-serif;
  color: #707070;
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: uppercase;
}
.image--grid .image--grid-row .image--grid-item figcaption .left--content .desc {
  display: inline-block;
  margin-bottom: 0;
}
.image--grid .image--grid-row .image--grid-item figcaption .right--content {
  flex: 1 0 0;
  text-align: right;
}
.image--grid .image--grid-row .image--grid-item figcaption .right--content .preis {
  font-weight: 400;
  font-style: italic;
  font-size: 2rem;
  text-align: right;
}
.image--grid .image--grid-row .brand-item {
  background: #003F8F;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
}
.image--grid .image--grid-row .brand-item .plus-grid {
  opacity: 0.5;
  color: white;
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  border: 2px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image--grid .image--grid-row .brand-item .plus-grid span {
  font-weight: 400;
  font-size: 3rem;
  position: relative;
  top: -3px;
}
@media (max-width: 740px) {
  .image--grid .image--grid-row .brand-item .plus-grid {
    width: 40px;
    height: 40px;
  }
  .image--grid .image--grid-row .brand-item .plus-grid span {
    font-size: 2rem;
    top: 0;
  }
}
.image--grid .image--grid-row .brand-item img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 70%;
  max-height: 40%;
}
@media (max-width: 640px) {
  .image--grid .image--grid-row .brand-item img {
    max-width: 80%;
    max-height: 60%;
  }
}
.image--grid .image--grid-row .brand-item .brand--overlay-description {
  background: #003F8F;
  position: relative;
  padding: 20px;
  opacity: 0;
  left: 50px;
  height: 100%;
  width: 100%;
  transition: all 600ms ease;
  display: flex;
  align-items: center;
}
@media (min-width: 960px) {
  .image--grid .image--grid-row .brand-item .brand--overlay-description {
    padding: 20px 40px;
  }
}
@media (max-width: 740px) {
  .image--grid .image--grid-row .brand-item .brand--overlay-description {
    display: none !important;
  }
}
.image--grid .image--grid-row .brand-item .brand--overlay-description p {
  color: white;
  margin: 0;
}
.image--grid .image--grid-row .brand-item:active .brand--overlay-description, .image--grid .image--grid-row .brand-item:hover .brand--overlay-description {
  opacity: 1;
  left: 0;
  width: 100%;
}
.image--grid .image--grid-row .image--wrapper {
  min-width: 0;
  margin-bottom: 0;
}
.image--grid .image--grid-row .image--wrapper:after {
  content: none;
}

.popup--open {
  pointer-events: none;
}

.iframe--wrapper-video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
}
.iframe--wrapper-video iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.news-box .news--content-container .news--image {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 62%;
  background-position: center;
  background-size: cover;
  margin-bottom: 1em;
  position: relative;
}
.news-box .news--content-container .news--image p {
  position: absolute;
  bottom: 10px;
  right: 10px;
  margin-bottom: 0 !important;
  color: white;
  font-size: 0.8rem;
}
.news-box .news--content-container h4 {
  font-size: 2rem;
}

.slick-slider.glass-slider {
  margin: 0 auto;
  margin-bottom: 40px;
  max-width: 70%;
}
.slick-slider.glass-slider .slick-arrow {
  position: absolute;
  top: 50%;
}
.slick-slider.glass-slider .slick-arrow i {
  font-size: 35px;
}
.slick-slider.glass-slider .slick-prev {
  z-index: 999;
  left: 0;
}
.slick-slider.glass-slider .slick-next {
  right: 0;
  z-index: 999;
}
@media (max-width: 740px) {
  .slick-slider.glass-slider {
    max-width: 100%;
  }
}

@media (max-width: 740px) {
  .as-btn.full--btn, .full--btn.as-btn-white {
    display: block;
  }
}

#rundgang-frame {
  height: 800px;
}

@media (max-width: 800px) {
  .news--slider .col-1-3 {
    min-width: auto !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
  }
}
@media (max-width: 600px) {
  .news--slider .col-1-3 {
    min-width: auto !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
  }
}

.container {
  padding: 2rem;
}

.image--slider {
  width: 45vw;
  max-width: 45%;
  padding: 0 2rem;
  min-width: 280px;
}
.image--slider .slick-prev,
.image--slider .slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.image--slider .slick-prev {
  left: 0;
}
.image--slider .slick-next {
  right: 0;
}
.image--slider img {
  width: 100%;
  max-width: 100%;
}

.slick-slider {
  position: relative;
  max-width: 100%;
}

#kontaktlinsenvielfalt {
  padding: 2rem 0;
}
#kontaktlinsenvielfalt * {
  color: white;
}
#kontaktlinsenvielfalt .linsen--teaser {
  margin-top: 2rem;
}
#kontaktlinsenvielfalt hr {
  margin: 0 2rem;
}
#kontaktlinsenvielfalt p {
  margin-bottom: 1rem;
}
#kontaktlinsenvielfalt h3 {
  margin-bottom: 1rem;
}
#kontaktlinsenvielfalt .flex-row {
  align-items: flex-start;
}
#kontaktlinsenvielfalt .col-1 img {
  max-width: 100%;
  display: block;
  margin-left: 1rem;
}
#kontaktlinsenvielfalt .borderleft {
  padding-left: 2rem;
  border-left: 1px solid white;
}

.linsen--teaser {
  margin: 2rem 0 0;
}
.linsen--teaser p, .linsen--teaser h3 {
  margin-bottom: 1rem !important;
}
.linsen--teaser img {
  max-width: 100%;
  margin-left: 1rem;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.flex-column img {
  width: 250px;
  max-width: 80%;
}
@media (max-width: 740px) {
  .flex-column {
    flex-direction: row !important;
    flex-wrap: wrap;
    display: flex;
    padding: 0rem !important;
    max-width: 100% !important;
    width: 100%;
  }
  .flex-column img {
    max-width: 30%;
    margin: 1rem;
  }
}

.video--container {
  width: 100%;
  max-width: 800px;
  margin: 2rem auto 0;
}
.video--container video {
  width: 100%;
}

@media (min-width: 768px) {
  .flex-row-tablet {
    display: flex;
    align-items: center;
  }
}
.flex-row-tablet.reverse > *:first-child {
  order: 2;
}
.flex-row-tablet.reverse > *:last-child {
  order: 1;
}

.p20 {
  padding: 20px;
}

#modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 999999999999;
  background: rgba(0, 63, 143, 0.98);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}
#modal .modal__content {
  padding: 1rem 2rem 2rem;
  background: #fff;
  border-radius: 0.2rem;
}
#modal .modal__content p {
  margin: 0;
}
#modal .modal__content a {
  font-weight: bold;
  text-decoration: none;
}
#modal .modal__content h3 {
  margin: 1rem 0;
}

#usercentrics-button #uc-consents-info-modal .uc-consents-info-modal-wrapper .uc-modal-header {
  background-color: #003F8F !important;
}
#usercentrics-button #uc-consents-info-modal .uc-consents-info-modal-wrapper .uc-modal-header .uc-info-modal-search-bar input {
  background-color: #fff !important;
  color: black !important;
}
#usercentrics-button #uc-consents-info-modal .uc-consents-info-modal-wrapper .uc-modal-header .uc-info-modal-search-bar input::placeholder {
  color: black !important;
}
#usercentrics-button #uc-center-modal .uc-powered-by-footer {
  display: none !important;
}
#usercentrics-button .uc-powered-by-footer {
  display: none !important;
}
#usercentrics-button #uc-consents-info-modal .uc-consents-info-modal-wrapper .uc-info-modal-sidebar .uc-powered-by {
  display: none !important;
}
#usercentrics-button #uc-consents-info-modal .uc-consents-info-modal-wrapper .uc-info-modal-sidebar .uc-info-modal-sidebar-nav {
  background: #cfcfcf;
}
#usercentrics-button .uc-banner-links * {
  font-size: 12px !important;
}

#uc-btn-close-main-corner-modal svg use, #uc-btn-close-main-corner-modal svg #fingerprint {
  fill: #003F8F !important;
}

#uc-btn-deny-banner,
#uc-btn-more-info-banner {
  margin-top: 1rem;
  font-size: 14px !important;
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: all 300ms ease;
}
#uc-btn-deny-banner:hover,
#uc-btn-more-info-banner:hover {
  background: white !important;
  color: #003F8F !important;
}

#uc-btn-accept-banner {
  font-weight: bold;
  font-size: 14px !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 300ms ease;
}
#uc-btn-accept-banner:hover {
  background: #facb5b !important;
}

.info--section {
  background: #003F8F;
}
.info--section .container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}
.info--section .container * {
  color: white;
  text-align: center;
}
.info--section .container h2 {
  color: white;
  font-weight: bold;
  width: 100%;
  max-width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.info--section .container .info--text {
  font-size: 1.6rem;
  margin: 0 auto;
  text-transform: uppercase;
  font-weight: bold;
}
.info--section .container p {
  margin: 2rem auto 1rem;
}

.df-btn {
  z-index: 99999999999999 !important;
  max-height: 100vh;
  overflow-y: auto;
}
@media (max-width: 720px) {
  .df-btn:not(.df-closed) {
    padding-top: 110px !important;
    padding-bottom: 60px !important;
  }
}
.df-btn:not(.df-closed) .df-btn-text:before {
  background-image: url("https://chat.occx.de/dist/assets/close.svg") !important;
}

.df-btn-text {
  padding: 0.5rem 1rem 0.5rem 0.5rem !important;
  font-size: 0.825rem !important;
  height: 40px !important;
}
.df-btn-text:before {
  min-width: 40px !important;
  background-image: url("../img/icon-chat.svg") !important;
  background-size: 20px !important;
}

.news__modal {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  align-items: center;
  padding: 2rem;
  justify-content: center;
  display: flex;
}
.news__modal.hidden {
  display: none;
}
.news__modal .modal__content {
  width: 100%;
  max-width: 70rem;
  background: white;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);
  display: grid;
  grid-template-areas: "image" "close" "text";
  grid-template-columns: 1fr;
}
@media (min-width: 768px) {
  .news__modal .modal__content {
    grid-template-areas: "image close" "image text";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }
}
.news__modal .modal__content .close {
  grid-area: close;
  text-align: right;
  padding: 1rem 2rem;
}
.news__modal .modal__content .close p {
  cursor: pointer;
  display: inline-block;
  margin-left: auto;
  cursor: pointer;
}
.news__modal .modal__content .modal__image {
  grid-area: image;
  background-size: cover;
  background-position: center 20%;
  min-height: 15rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 20px;
  color: white;
}
@media (min-width: 768px) {
  .news__modal .modal__content .modal__image {
    min-height: 25rem;
  }
}
.news__modal .modal__content .modal__text {
  grid-area: text;
  padding: 0 2rem 2rem;
}
.news__modal .modal__content .modal__text .as-btn, .news__modal .modal__content .modal__text .as-btn-white {
  cursor: pointer;
}

.modal__bewerbung#modal {
  padding: 1rem;
}
.modal__bewerbung#modal .modal__content {
  background: #e9e9e9;
  background: #fff;
  padding: 0;
  position: relative;
  overflow-y: auto;
  padding-top: 2rem;
  width: 100%;
  max-width: 70rem;
  max-height: 100%;
}
.modal__bewerbung#modal .modal__content hr {
  border: none;
  background: #003F8F;
  width: 100%;
  margin: 1.5rem 0;
  height: 1px;
}
.modal__bewerbung#modal .modal__content .close {
  font-size: 3rem;
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  cursor: pointer;
  z-index: 10;
  line-height: 1;
}
.modal__bewerbung#modal .modal__content .content {
  padding: 2rem;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  margin: 0;
}
.modal__bewerbung#modal .modal__content .content * {
  position: relative;
  z-index: 1;
}
.modal__bewerbung#modal .modal__content .content h4 {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 0;
  line-height: 1.2;
  display: block;
}
.modal__bewerbung#modal .modal__content .content h3 {
  font-size: 2.4rem;
  font-weight: 100;
  font-family: Roboto, sans-serif;
  line-height: 1.2;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
.modal__bewerbung#modal .modal__content .content p {
  margin-bottom: 1rem;
}
.modal__bewerbung#modal .modal__content .content strong, .modal__bewerbung#modal .modal__content .content a {
  display: block;
  font-weight: bold;
}
.modal__bewerbung#modal .modal__content img {
  display: block;
  width: 100%;
  height: auto;
  z-index: 0;
  margin-top: -2rem;
}

#as-augenblick .as-textbox, #as-lustaufmode .as-textbox, #as-liebling .as-textbox {
  align-self: center;
}
@media (max-width: 979px) {
  #as-augenblick .as-textbox, #as-lustaufmode .as-textbox, #as-liebling .as-textbox {
    order: 2;
  }
}
#as-augenblick .as-textbox .flex-inner, #as-lustaufmode .as-textbox .flex-inner, #as-liebling .as-textbox .flex-inner {
  margin: 10%;
}
#as-augenblick .as-slider, #as-augenblick .background-img, #as-lustaufmode .as-slider, #as-lustaufmode .background-img, #as-liebling .as-slider, #as-liebling .background-img {
  overflow: hidden;
  height: 100vh;
}
@media (max-width: 979px) {
  #as-augenblick .as-slider, #as-augenblick .background-img, #as-lustaufmode .as-slider, #as-lustaufmode .background-img, #as-liebling .as-slider, #as-liebling .background-img {
    order: 1;
  }
}
#as-augenblick .as-slider img, #as-augenblick .background-img img, #as-lustaufmode .as-slider img, #as-lustaufmode .background-img img, #as-liebling .as-slider img, #as-liebling .background-img img {
  display: block;
  height: 100%;
}

#as-lustaufmode {
  flex-direction: row-reverse;
}
#as-lustaufmode .background-img {
  background: url("../img/colors.jpg") center no-repeat;
  background-size: cover;
}

#as-liebling .brillen {
  margin-top: 50px;
}

#as-news, #as-tools {
  padding: 0 20px;
}

#as-news .news-box {
  margin-right: 50px;
}
#as-news .news-box:last-child {
  margin-right: 0;
}
#as-news .news-box img {
  max-width: 100%;
  border: 1px solid #003F8F;
  display: block;
  margin-bottom: 20px;
}
#as-news .news-box p {
  margin-bottom: 30px;
}

#as-tools .online-tools {
  border: 1px solid #003F8F;
}
#as-tools .online-tools img {
  max-width: 100%;
  display: block;
}
#as-tools .online-tools .as-btn, #as-tools .online-tools .as-btn-white {
  margin: 30px 10px 10px 0;
  float: right;
}

#as-newsletter {
  margin-top: 80px;
  padding: 30px 4%;
  background: #003F8F;
  color: white;
}
#as-newsletter .newsletter-inner {
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 0;
}
#as-newsletter .newsletter-inner h2 {
  text-align: center;
  color: white;
  margin: 0px auto 30px;
  font-size: 3rem;
}
#as-newsletter .newsletter-inner #newsletter-form {
  text-align: center;
  position: relative;
}
#as-newsletter .newsletter-inner #newsletter-form .newsletter--form-group {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: no-wrap;
  margin-bottom: 20px;
}
#as-newsletter .newsletter-inner #newsletter-form #mce-EMAIL {
  height: 50px;
  border: none;
  padding: 15px;
  border-radius: 3px 0 0 3px;
  flex: 1 0 0;
  margin: 0;
  font-weight: 300;
  display: block;
  outline: none;
}
#as-newsletter .newsletter-inner #newsletter-form #send {
  position: relative;
  visibility: visible;
  transition: 0.3s ease;
  background: #eee;
  border-radius: 0 3px 3px 0;
  border: 0;
  width: 60px;
  height: 50px;
  margin: 0;
  display: block;
  cursor: pointer;
}
#as-newsletter .newsletter-inner #newsletter-form #send::after {
  content: "";
  background: url("../img/mail.svg") center no-repeat;
  background-size: contain;
  width: 27px;
  height: 22px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: all 300ms ease;
}
#as-newsletter .newsletter-inner #newsletter-form #send:hover {
  background: #f6f6f6;
}
#as-newsletter .newsletter-inner #newsletter-form #send:hover:after {
  left: 60%;
}
#as-newsletter .newsletter-inner #newsletter-form label {
  font-size: 0.925rem;
  text-align: center;
}
#as-newsletter .newsletter-inner #newsletter-form label a {
  color: white;
  font-size: inherit;
}
@media (max-width: 500px) {
  #as-newsletter .newsletter-inner {
    padding: 0;
  }
  #as-newsletter .newsletter-inner h6 {
    font-size: 1rem;
  }
}

#as-kontakt .kontakt-map {
  overflow: hidden;
}
@media (max-width: 980px) {
  #as-kontakt .kontakt-map {
    margin-bottom: 30px;
  }
}
#as-kontakt .col-3 {
  align-self: center;
}
#as-kontakt .col-3 .kontakt-text {
  margin: 0 40px;
}
#as-kontakt .col-3 .kontakt-text p {
  margin-bottom: 30px;
}
#as-kontakt .col-3 .kontakt-text #adress-form {
  position: relative;
  width: 250px;
}
#as-kontakt .col-3 .kontakt-text #adress-form #adresse {
  width: 250px;
  padding: 20px;
  border: 2px solid #003F8F;
}
#as-kontakt .col-3 .kontakt-text #adress-form #send {
  position: absolute;
  right: 0;
  top: 0;
  box-shadow: none;
  background: #003F8F;
  border: 0px;
  height: 100%;
  width: 60px;
}
#as-kontakt .col-3 .kontakt-text #adress-form #send::after {
  content: url("../img/send.svg");
  width: 20px;
  height: 20px;
  display: inline-block;
  visibility: visible;
  cursor: pointer;
  height: 100%;
  width: 60px;
  line-height: 5.5;
}

#as-bottom .mehr-sehen {
  padding: 0 20px;
  margin: 0 auto;
  margin-top: 80px;
  max-width: 700px;
}
#as-bottom .mehr-sehen h4 {
  font-size: 2.2rem;
}
#as-bottom .mehr-sehen h4::before {
  background: url("../img/amon_claim.svg") bottom center no-repeat;
  background-size: contain;
  display: inline-block;
  width: 240px;
  height: 33px;
  content: "";
  margin-right: 15px;
}
#as-bottom .as-mitarbeiter img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

@media (max-width: 979px) {
  .disable {
    visibility: hidden;
  }
  .disable h2 {
    margin: 0;
  }
}

.slider-nav {
  margin-top: 30px;
}

#map {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: url("../img/karte_platzhalter.jpg") center;
  background-size: cover;
}

.search--group {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.search--group #start {
  height: 50px;
  border: 1px solid #003F8F;
  padding: 15px;
  border-radius: 3px 0 0 3px;
  flex: 1 0 0;
  margin: 0;
  font-weight: 300;
  display: block;
  outline: 0;
}
.search--group #searchZip {
  position: relative;
  visibility: visible;
  transition: 0.3s ease;
  background: #003F8F;
  color: transparent;
  border-radius: 0 3px 3px 0;
  border: 0;
  width: 50px;
  height: 50px;
  margin: 0;
  display: block;
  cursor: pointer;
}
.search--group #searchZip:after {
  content: "";
  background: url(../img/icon--standortsuche.svg) center no-repeat;
  background-size: auto;
  background-size: contain;
  width: 27px;
  height: 22px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: all 0.3s ease;
}

#msg b {
  display: block;
  color: orangered;
  margin: 10px 0 20px;
}

#routeLink {
  position: relative;
  top: 50px;
  transition: all 300ms ease;
  opacity: 0;
  pointer-events: none;
}
#routeLink.active {
  top: 0;
  opacity: 1;
  pointer-events: all;
}

.fw--video {
  width: 100%;
  height: calc(100vh - 1px);
  position: relative;
  overflow: hidden;
}
.fw--video video {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
}
@media (max-width: 1040px) {
  .fw--video {
    width: 100%;
    height: auto;
  }
  .fw--video video {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
    width: 100%;
    height: auto;
  }
}

#azubis {
  background: #000;
  margin-top: 2rem;
  margin-bottom: -80px;
}
#azubis * {
  color: white;
  text-align: center;
}
#azubis img {
  display: block;
  max-width: 100%;
}
#azubis h3 {
  font-size: 4rem;
  font-family: Roboto, sans-serif;
}
#azubis h4 {
  text-transform: none;
}
#azubis .azubis-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  margin-bottom: 20px;
  padding: 0 2rem;
  margin-bottom: 2rem;
  gap: 1rem;
}
@media (min-width: 380px) {
  #azubis .azubis-row {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 640px) {
  #azubis .azubis-row {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 940px) {
  #azubis .azubis-row {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 1050px) {
  #azubis .azubis-row {
    grid-template-columns: repeat(5, 1fr);
  }
}
#azubis .azubis-row .col-1-4 {
  width: auto;
  margin: 0 !important;
  height: 100%;
}
#azubis .azubis-row .col-1-4 .azubi {
  border: 2px solid white;
  background: white;
  height: 100%;
}
#azubis .azubis-row .col-1-4 .azubi img {
  object-fit: cover;
  object-position: top;
  width: 100%;
  aspect-ratio: 5/6;
  height: auto;
}
#azubis .azubis-row .col-1-4 .azubi .title {
  background: white;
  padding: 10px;
  min-height: 90px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
#azubis .azubis-row .col-1-4 .azubi .title h3, #azubis .azubis-row .col-1-4 .azubi .title p, #azubis .azubis-row .col-1-4 .azubi .title a {
  margin-bottom: 0;
  color: black;
  text-align: left;
  text-transform: uppercase;
}
#azubis .azubis-row .col-1-4 .azubi .title h3 {
  font-size: 1.618rem;
}
@media (max-width: 740px) {
  #azubis .azubis-row .col-1-4 .azubi .title h3 {
    font-size: 1rem;
  }
}
#azubis .azubis-row .col-1-4:last-child {
  grid-column: span 2;
}
@media (min-width: 640px) {
  #azubis .azubis-row .col-1-4:last-child {
    grid-column: span 1;
  }
}
#azubis .azubis-row .col-1-4:last-child .azubi p, #azubis .azubis-row .col-1-4:last-child .azubi a {
  text-transform: unset;
  line-height: 1.1;
  font-weight: bold;
  font-size: 0.95rem;
}
#azubis.jobs h3 {
  font-size: 1.5rem;
}

.news--page .divider--line {
  width: 100%;
  max-width: 100%;
  border: none;
  background: #ececec;
  height: 1px;
}
.news--page .flex--container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.news--page .flex--container .col--1 {
  flex: 1 0 35%;
}
.news--page .flex--container .col--1 img {
  padding-right: 20px;
  max-width: 100%;
}
.news--page .flex--container .col--2 {
  flex: 1 0 65%;
}
.news--page .flex--container .col--2 h5 {
  margin-bottom: 0 !important;
}
.news--page .fw--image-left {
  min-height: calc(100vh - 80px);
  transition: none;
}
.news--page .fw--image-left * {
  transition: none !important;
}
.news--page .fw--image-left .fw--text-content {
  padding-bottom: 30px;
}
.news--page .fw--image-left .fw--text-content strong {
  font-size: inherit;
}
.news--page .fw--image-left .fw--text-content h2 {
  font-size: 4rem;
}
@media (max-width: 1040px) {
  .news--page .fw--image-left .fw--text-content h2 {
    font-size: 2.2rem;
  }
}
.news--page .fw--image-left .fw--text-content h5 {
  margin: 0;
  font-weight: bold;
  color: #2e2e2e;
}
.news--page .fw--image-left .fw--text-content h5:after {
  content: none;
}
.news--page .fw--image-left .fw--text-content h4 {
  margin-bottom: 0;
  font-weight: bold;
}
.news--page .fw--image-left .fw--text-content ul {
  margin-bottom: 20px;
}
.news--page .fw--image-left .fw--text-content ul li {
  margin-left: 20px;
  margin-bottom: 10px;
  font-weight: normal;
}
.news--page .fw--image-left .fw--text-content .angebotskasten {
  display: flex;
  align-items: center;
  margin-right: 0;
  background: #dde9ee;
  position: relative;
  margin-left: -1em;
  padding: 20px;
}
@media (min-width: 640px) {
  .news--page .fw--image-left .fw--text-content .angebotskasten {
    padding: 0;
    margin-left: -3em;
  }
}
.news--page .fw--image-left .fw--text-content .angebotskasten:after {
  content: "";
  position: absolute;
  left: 100%;
  top: 0;
  width: 100%;
  height: 100%;
  background: inherit;
}
.news--page .fw--image-left .fw--text-content .angebotskasten.black {
  background: #000;
  padding: 2em;
  padding-right: 1em;
}
@media (min-width: 640px) {
  .news--page .fw--image-left .fw--text-content .angebotskasten.black {
    padding: 2em 20px 3em 3em;
  }
}
.news--page .fw--image-left .fw--text-content .angebotskasten.black * {
  color: white !important;
}
.news--page .fw--image-left .fw--text-content .angebotskasten.black img.mobile--image {
  max-width: 100% !important;
}
.news--page .fw--image-left .fw--text-content .angebotskasten.black .angebot {
  position: relative;
  margin-bottom: 4rem;
}
@media (min-width: 640px) {
  .news--page .fw--image-left .fw--text-content .angebotskasten.black .angebot {
    margin-bottom: 4rem;
    margin-left: -2em;
    top: 4em;
  }
}
.news--page .fw--image-left .fw--text-content .angebotskasten.black .slanted {
  margin-top: -3em;
  transform-origin: right top;
  transform: skewY(-10deg);
}
@media (min-width: 640px) {
  .news--page .fw--image-left .fw--text-content .angebotskasten.black .slanted {
    margin-top: 3rem;
  }
}
.news--page .fw--image-left .fw--text-content .angebotskasten.female {
  background: white;
  margin-left: 0;
}
@media (min-width: 640px) {
  .news--page .fw--image-left .fw--text-content .angebotskasten.female {
    margin-right: -3em;
  }
}
.news--page .fw--image-left .fw--text-content .angebotskasten img.hero {
  flex: 1 1 50%;
  max-width: 50%;
  display: none;
}
@media (min-width: 640px) {
  .news--page .fw--image-left .fw--text-content .angebotskasten img.hero {
    display: block;
  }
}
.news--page .fw--image-left .fw--text-content .angebotskasten .angebot {
  padding: 2em 0;
}
.news--page .fw--image-left .fw--text-content .angebotskasten .angebot p {
  margin-bottom: 10px;
}
.news--page .fw--image-left .fw--text-content .angebotskasten .angebot h4 {
  font-size: 1.6rem;
  margin-bottom: 10px;
}
.news--page .fw--image-left .fw--text-content .angebotskasten .angebot .mobile--image {
  display: block;
}
@media (min-width: 640px) {
  .news--page .fw--image-left .fw--text-content .angebotskasten .angebot .mobile--image {
    display: none;
  }
}
.news--page .fw--image-left .fw--text-content .angebotskasten .angebot img {
  max-width: 70%;
}
.news--page .fw--image-left .fw--text-content .angebotskasten .angebot .pricetag {
  font-size: 2.2rem;
  margin-bottom: 0;
}
.news--page .fw--image-left .fw--text-content .info--box {
  background: #efefef;
  padding: 20px;
  margin: 0;
  position: relative;
}
.news--page .fw--image-left .fw--text-content .info--box.orange {
  background: #FECB44;
}
.news--page .fw--image-left .fw--text-content .info--box.orange * {
  color: black;
}
.news--page .fw--image-left .fw--text-content .info--box.aif {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news--page .fw--image-left .fw--text-content .info--box .infobox--content {
  position: relative;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}
@media (min-width: 700px) {
  .news--page .fw--image-left .fw--text-content .info--box .infobox--content {
    width: 60%;
  }
}
.news--page .fw--image-left .fw--text-content .info--box .wolke {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 120%;
  max-width: 700px;
}
.news--page .fw--image-left .fw--image {
  background-position: center;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 20px;
  color: white;
}
@media (max-width: 1199px) {
  .news--page .fw--image-left {
    display: block;
  }
  .news--page .fw--image-left .fw--text-wrapper {
    max-width: 100%;
  }
  .news--page .fw--image-left .fw--image-container {
    min-height: 320px;
    max-height: 650px;
    height: 80vw;
  }
  .news--page .fw--image-left .fw--text-content,
.news--page .fw--image-left .info--box {
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .news--page .fw--image-left .fw--image {
    position: fixed;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    transform: none;
  }
  .news--page .fw--image-left .fw--image-container,
.news--page .fw--image-left .fw--text-wrapper {
    width: 50%;
    max-width: 50%;
  }
}
.news--page .caption {
  padding-left: 0;
}
.news--page .caption:before {
  content: none;
}
.news--page .breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
  align-items: center;
}
.news--page .breadcrumbs a {
  font-size: 1rem;
  display: block;
  padding: 0.2em 1.2em 0.2em 0.8em;
  text-decoration: none;
  background: #eaeaea;
  color: black;
  padding-right: 1.5em;
  position: relative;
  margin: 0;
}
.news--page .breadcrumbs a:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 15px;
  z-index: 1;
  background: url("../../assets/img/breadcrumb-divider.svg") right center no-repeat;
  background-size: contain;
}
.news--page .breadcrumbs a:last-child {
  padding-right: 0.6em;
}
.news--page .breadcrumbs a:last-child:after {
  content: none;
}
.news--page strong {
  font-weight: 800;
}
.news--page .news--subline {
  font-size: 2rem;
  margin-bottom: 0.5rem !important;
  display: block;
}
.news--page .news--subline + h5 {
  display: block;
  margin-bottom: 1.5rem !important;
}
.news--page .news--dividerline {
  color: #003F8F !important;
  text-transform: uppercase;
  font-size: 1.8rem;
  margin-bottom: 1rem !important;
}
.news--page .news--image {
  width: 100%;
  max-width: 100%;
  display: block;
  margin: 20px 0;
}
.news--page .news--image.right--small {
  max-width: 15rem;
  margin-left: auto;
}
.news--page .news--image.center--small {
  max-width: 15rem;
  margin: 0 auto;
}
.news--page .news--image.center--medium {
  max-width: 25rem;
  margin: -2rem auto 0;
  position: relative;
}
@media (min-width: 500px) {
  .news--page .news--flex-row {
    display: flex;
    align-items: center;
  }
  .news--page .news--flex-row p {
    margin-bottom: 0;
    padding-right: 20px;
  }
}
.news--page .breaking--news {
  background: white;
  padding: 20px;
  margin: 80px 0;
}
.news--page .breaking--news p {
  margin-bottom: 20px;
}
.news--page .breaking--news h5 {
  text-align: center;
  color: #003F8F !important;
}

.news--content {
  max-width: 1200px;
  width: 100%;
  margin: 6em auto;
  padding: 2rem;
}
.news--content .news--article {
  width: 100%;
  margin-bottom: 20px;
  background: #f7f7f7;
  display: flex;
  align-items: center;
}
.news--content .news--article .news--article-image {
  display: block;
}
.news--content .news--article .news--article-image img {
  max-width: 20rem;
  width: 30vw;
  display: block;
}
.news--content .news--article .news--article-content {
  padding: 2rem;
  flex: 2 0 0;
}
.news--content .news--article .news--article-content h3 {
  font-size: 2.4em;
  font-weight: 100 !important;
  font-family: Roboto, sans-serif;
  margin-bottom: 10px;
}
.news--content .news--article .news--article-content h4 {
  font-size: 1.6em;
}
.news--content .news--article .news--article-content p {
  margin-bottom: 1rem;
}
.news--content .news--article .news--article-content .as-btn, .news--content .news--article .news--article-content .as-btn-white {
  margin-bottom: 0;
}
@media (max-width: 700px) {
  .news--content {
    padding: 1rem;
  }
  .news--content .news--article .news--article-image {
    display: none;
  }
  .news--content .news--article .news--article-content {
    padding: 1rem;
  }
  .news--content .news--article .news--article-content h3 {
    font-size: 1.6rem;
  }
}

#news-12 .fw--image {
  background-position: top 80px center !important;
}
@media (max-width: 1199px) {
  #news-12 .fw--image {
    background-position: top center !important;
  }
}

.panorama--image {
  height: 50vw;
  max-height: 500px;
  position: relative;
  width: 100%;
  margin-top: 60px;
  overflow: hidden;
}
.panorama--image img {
  position: absolute;
  left: 0;
  transform: translateX(-25%);
  top: 0;
  height: 100%;
}
.panorama--image #image-range {
  appearance: none;
  position: absolute;
  left: 0;
  bottom: 8px;
  width: 100%;
  background: transparent;
  outline: none;
  height: 12px;
  opacity: 1;
}
.panorama--image .custom--slider {
  width: 100%;
  height: 21px;
  position: absolute;
  left: 0;
  bottom: 0px;
  border-bottom: 7px solid white;
}
.panorama--image .custom--slider .slidebar {
  height: 100%;
  position: absolute;
  left: 0;
  width: 50%;
  background: #003F8F;
  pointer-events: none;
}

#image-range {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #0055c2;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
#image-range:hover, #image-range:active, #image-range:target, #image-range:focus {
  opacity: 1;
}
#image-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: url("../img/icon-move.svg"), #0055c2;
  background-size: 14px, cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #006cf5;
}
#image-range::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: url("../img/icon-move.svg"), #0055c2;
  background-size: 14px, cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #006cf5;
}

.mitarbeiter--container {
  padding: 0px 20px 40px 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  grid-template-columns: repeat(4, 1fr);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .mitarbeiter--container {
    display: flex;
    padding: 0px 0 40px 20px;
  }
}

.mitarbeiter {
  text-decoration: none;
  position: relative;
  width: 18%;
  max-width: calc(20% - 1rem);
  flex: 1 0 18%;
  margin-right: 1rem;
  margin-bottom: 10px;
  border-bottom: 6px solid #003F8F;
  overflow: hidden;
}
@media (max-width: 1400px) {
  .mitarbeiter {
    flex: 1 0 21%;
    width: 21%;
    max-width: calc(25% - 1rem);
  }
}
@media (max-width: 1000px) {
  .mitarbeiter {
    flex: 1 0 27%;
    width: 27%;
    max-width: calc(33.3333333333% - 1rem);
  }
}
@media (max-width: 768px) {
  .mitarbeiter {
    flex: 1 0 35%;
    width: 35%;
    max-width: calc(50% - 1rem);
  }
}
@media (max-width: 468px) {
  .mitarbeiter {
    flex: 1 0 100%;
    width: 100%;
    margin-right: 0;
    max-width: 100%;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .mitarbeiter {
    max-width: 100%;
    min-width: 31%;
    flex: 1 0 0;
    margin-right: 20px;
    margin-bottom: 10px;
  }
}
.mitarbeiter .mitarbeiter--image {
  display: block;
  width: 100%;
  transition: 0.2s ease-in-out;
  z-index: 1;
  height: 100%;
}
.mitarbeiter .mitarbeiter--image.bild--overlay {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}
.mitarbeiter .mitarbeiter--image .plus-small {
  border: 2px solid rgba(255, 255, 255, 0.8);
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  right: 20px;
  bottom: 20px;
  text-decoration: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 300ms ease;
  cursor: pointer;
}
.mitarbeiter .mitarbeiter--image .plus-small span {
  font-size: 1.4rem;
  color: white;
  line-height: 1;
  font-weight: 400;
  text-align: center;
  position: relative;
  top: -1px;
}
.mitarbeiter:hover .bild--overlay, .mitarbeiter:active .bild--overlay, .mitarbeiter:target .bild--overlay, .mitarbeiter:focus .bild--overlay {
  opacity: 1;
}
.mitarbeiter:hover .mitarbeiter--caption, .mitarbeiter:active .mitarbeiter--caption, .mitarbeiter:target .mitarbeiter--caption, .mitarbeiter:focus .mitarbeiter--caption {
  transform: translateY(0);
}
.mitarbeiter:hover .mitarbeiter--hover, .mitarbeiter:active .mitarbeiter--hover, .mitarbeiter:target .mitarbeiter--hover, .mitarbeiter:focus .mitarbeiter--hover {
  opacity: 1;
}
.mitarbeiter .mitarbeiter--hover {
  position: absolute;
  top: 0;
  z-index: 0;
}
.mitarbeiter .mitarbeiter--hover img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  transition: all 300ms ease;
}
.mitarbeiter:hover .plus-small, .mitarbeiter:active .plus-small, .mitarbeiter:target .plus-small, .mitarbeiter:focus .plus-small {
  background: rgba(255, 255, 255, 0.3);
  border-width: 4px;
}
.mitarbeiter .mitarbeiter--caption {
  position: absolute;
  bottom: 0%;
  padding: 1rem;
  transform: translateY(100%);
  transition: 0.2s ease-in-out;
  z-index: 3;
}
@media (max-width: 640px) {
  .mitarbeiter .mitarbeiter--caption {
    padding: 1rem;
  }
}
.mitarbeiter .mitarbeiter--caption .mitarbeiter--name {
  color: white;
  font-size: 1rem !important;
  font-weight: bold;
  text-transform: inherit;
  margin: 0;
}
.mitarbeiter .mitarbeiter--caption p {
  margin-bottom: 0;
  font-size: 0.825rem;
  color: white;
  margin-bottom: 10px;
}
.mitarbeiter .mitarbeiter--caption .mitarbeiter--email {
  color: #003F8F;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 0.8725rem;
  background: white;
  display: inline-block;
  text-decoration: none;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .mitarbeiter .mitarbeiter--caption p {
    margin-bottom: 20px;
  }
}

#overlay .mitarbeiter--overlay {
  transition: all 300ms ease;
  position: fixed;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
}
#overlay .mitarbeiter--overlay .mitarbeiter--image {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-size: cover !important;
  background-position: left 30% center !important;
}
#overlay .mitarbeiter--overlay .mitarbeiter--caption {
  position: absolute;
  width: 50%;
  top: 0;
  left: 50%;
  padding: 40px;
  padding-top: 10vw;
  min-height: 60%;
  display: flex;
  align-items: center;
}
#overlay .mitarbeiter--overlay .mitarbeiter--caption .close {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 2rem;
  cursor: pointer;
  color: white;
  text-decoration: none;
}
#overlay .mitarbeiter--overlay .mitarbeiter--caption .mitarbeiter--text-content {
  max-width: 600px;
  position: relative;
  margin: 0 auto;
}
#overlay .mitarbeiter--overlay .mitarbeiter--caption .mitarbeiter--name {
  text-align: left;
  margin: 0;
  text-transform: lowercase;
  width: 100%;
  font-size: 4rem;
  line-height: 1;
  margin-bottom: 20px;
  color: white;
}
#overlay .mitarbeiter--overlay .mitarbeiter--caption h4 {
  color: white;
  line-height: 1.4;
  font-size: 1.6rem;
  font-style: italic;
  font-weight: 300;
  margin-top: 30px;
}
#overlay .mitarbeiter--overlay .mitarbeiter--caption p {
  color: white;
  font-weight: 200;
  margin-bottom: 0;
}
@media (max-width: 840px) {
  #overlay .mitarbeiter--overlay {
    overflow-y: auto;
    background: #003F8F;
  }
  #overlay .mitarbeiter--overlay .mitarbeiter--image {
    position: relative;
    width: 100%;
    height: 50%;
    background-position: left 20% center !important;
  }
  #overlay .mitarbeiter--overlay .mitarbeiter--caption {
    position: relative;
    left: 0;
    width: 100%;
    min-height: 0;
    background: #003F8F;
  }
  #overlay .mitarbeiter--overlay .mitarbeiter--caption .close {
    right: 20px;
    top: 0px;
  }
  #overlay .mitarbeiter--overlay .mitarbeiter--caption .mitarbeiter--text-content .mitarbeiter--name {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  #overlay .mitarbeiter--overlay .mitarbeiter--caption .mitarbeiter--text-content h4 {
    font-size: 1.2rem;
  }
  #overlay .mitarbeiter--overlay .mitarbeiter--caption .mitarbeiter--text-content p {
    line-height: 1.6;
    font-size: 0.875rem;
  }
}

input[type=range]::-ms-track {
  width: 100%;
  height: 50px !important;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
  overflow: visible;
}

input[type=range]::-ms-fill-lower {
  background: #003f8f;
  border: 0px solid #010101;
  border-radius: 2.6px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  opacity: 0;
}

input[type=range]::-ms-fill-upper {
  background: #003f8f;
  border: 0px solid #010101;
  border-radius: 2.6px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  opacity: 0;
}

input[type=range]::-ms-thumb {
  width: 25px;
  height: 25px;
  background: url("../img/icon-move.svg"), #0055c2;
  background-size: 14px, cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #006cf5;
}

.philosophie {
  padding: 2rem;
  margin-top: 2rem;
  display: inline-block;
}
.philosophie h2 {
  margin: 0 0 1rem !important;
  text-align: left;
  width: 100%;
}

.sports--bg {
  background-color: #fff;
  background-image: url("../img/bg-sports.jpg"), url("../img/bg-sports.jpg");
  background-size: 40%, 40%;
  background-repeat: no-repeat, no-repeat;
  background-position: top -10% right -10%, bottom -10% left -10%;
  padding-bottom: 60px;
}
.sports--bg .flex-row.no-align .col-1 {
  margin-right: 10px;
}
.sports--bg .flex-row.no-align .col-1 .flex-row img {
  flex: 1 0 0;
  max-width: 200px;
}
@media (max-width: 940px) {
  .sports--bg .flex-row.no-align {
    display: block;
  }
  .sports--bg .flex-row.no-align .col-1 .flex-row img {
    flex: 1 0 0;
  }
}
.sports--bg h1 {
  margin: 40px 0;
}
.sports--bg .brillen {
  padding: 30px;
  display: grid;
  grid-template-areas: "rad ski golf" "segel lauf leer";
}
@media (max-width: 1240px) {
  .sports--bg .brillen {
    grid-template-areas: "rad ski" "golf segel" "lauf leer";
    padding: 20px;
  }
}
@media (max-width: 760px) {
  .sports--bg .brillen {
    grid-template-areas: "rad" "ski" "golf" "segel" "lauf" "leer";
  }
}

.info--box {
  flex: 1 0 30%;
  margin: 10px;
}
.info--box:nth-child(1) {
  grid-area: rad;
}
.info--box:nth-child(2) {
  grid-area: ski;
}
.info--box:nth-child(3) {
  grid-area: golf;
}
.info--box:nth-child(4) {
  grid-area: segel;
}
.info--box:nth-child(5) {
  grid-area: lauf;
}
.info--box h3 {
  margin-bottom: 10px;
}
.info--box .image--wrapper {
  padding-bottom: 60%;
}
.info--box p {
  margin-bottom: 20px;
}

.twen {
  display: flex;
  align-items: flex-end;
}
.twen p {
  z-index: 1;
  margin-left: 30px;
  margin-bottom: 30px;
  font-size: 1.4rem;
  color: #fff;
  font-style: italic;
  line-height: 1.2;
  font-weight: 300;
}
@media (max-width: 640px) {
  .twen p {
    font-size: 0.7rem;
    margin-left: 20px;
  }
}

#twen h1 {
  font-size: 2.4rem;
  line-height: 1.2;
  text-transform: none;
}
@media (max-width: 960px) {
  #twen h1 {
    font-size: 1.6rem;
  }
}
@media (max-width: 760px) {
  #twen h1 {
    font-size: 1.4rem;
  }
}

#twen-info, #fashionabo-info {
  position: relative;
  padding: 2em;
}
#twen-info .kids--row, #fashionabo-info .kids--row {
  display: flex;
  align-items: center;
  margin-right: 20px !important;
}
#twen-info *, #fashionabo-info * {
  color: white;
}
#twen-info .flex-row, #fashionabo-info .flex-row {
  align-items: center;
  justify-content: center;
}
#twen-info p, #fashionabo-info p {
  margin-bottom: 1rem;
}
#twen-info p:last-child, #fashionabo-info p:last-child {
  margin-bottom: 0;
}
#twen-info h3, #fashionabo-info h3 {
  max-width: 600px;
  font-size: 2.4rem;
}
@media (max-width: 760px) {
  #twen-info h3, #fashionabo-info h3 {
    font-size: 1.4rem;
  }
}
#twen-info .divider, #fashionabo-info .divider {
  width: 1px;
  margin: 0 60px;
  height: 200px;
  background: #fff;
}
#twen-info .col-1, #fashionabo-info .col-1 {
  max-width: 600px;
}
#twen-info .col-1:first-child, #fashionabo-info .col-1:first-child {
  margin-right: 60px;
}
#twen-info .col-1:last-child, #fashionabo-info .col-1:last-child {
  padding-left: 60px;
  border-left: 1px solid white;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #twen-info .col-1, #fashionabo-info .col-1 {
    max-width: 100%;
  }
}
@media (max-width: 760px) {
  #twen-info .flex-row, #fashionabo-info .flex-row {
    display: block;
  }
  #twen-info .flex-row .col-1, #fashionabo-info .flex-row .col-1 {
    margin: 0;
  }
  #twen-info .flex-row .col-1 h3, #fashionabo-info .flex-row .col-1 h3 {
    margin-bottom: 30px;
  }
  #twen-info .flex-row .col-1:last-child, #fashionabo-info .flex-row .col-1:last-child {
    padding-left: 20px;
  }
  #twen-info .flex-row .col-1:last-child a, #fashionabo-info .flex-row .col-1:last-child a {
    margin-bottom: 0;
  }
}

.logo--grid {
  display: flex;
  flex-wrap: wrap;
  max-width: 1600px;
  margin: 3em auto;
  align-items: center;
}
.logo--grid .logo--item {
  flex: 1 0 20%;
  margin: 10px;
  text-align: center;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .logo--grid .logo--item {
    width: 100%;
    height: 100%;
  }
}
.logo--grid .logo--item img {
  width: 100%;
  max-width: 250px;
  max-height: 150px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .logo--grid .logo--item img {
    width: auto;
  }
}

#fashion .fw--image.fashion {
  background: url("../img/fashion.jpg") top center no-repeat;
  background-size: cover;
}

@media (max-width: 1330px) {
  #twen {
    min-height: 0;
  }
}
#twen .fw--image.twen {
  background: url("../img/aktion/header.jpg") top 10% center no-repeat;
  background-size: cover;
}

.marken--grid {
  margin: 0 auto 0;
  display: flex;
  flex-wrap: wrap;
}
.marken--grid .fw--image-container {
  flex: 1 0 30%;
  position: relative;
  height: 0;
  padding-bottom: 40%;
  overflow: hidden;
  background-position: center;
  background-size: cover;
}
.marken--grid img {
  flex: 1 0 30%;
  width: 30%;
  height: 100%;
}
.marken--grid img .fw--image {
  transition: all 300ms ease;
  position: absolute;
  width: 99.5%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-size: cover;
  background-position: center center;
}
.marken--grid img .fw--image.big-image {
  width: 99.7%;
  margin-top: 3px;
}
.marken--grid img .platzhalter {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  color: white;
  background: #003F8F;
  padding: 0.3em 0.6em;
}
@media (min-width: 768px) {
  .marken--grid .fw--image-container, .marken--grid img {
    flex: 1 0 15%;
  }
}

#kids-info .stoerer {
  margin-left: 1rem;
  position: relative;
  width: 10rem;
  height: 10rem;
  min-width: 10rem;
  min-height: 10rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffcf00;
  border-radius: 50%;
  padding: 10px;
  text-align: center;
  color: black;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
  transform: rotate(-10deg);
}
#kids-info .stoerer strong {
  font-weight: 700;
  color: black;
  font-size: 1.2rem;
}
@media (max-width: 540px) {
  #kids-info .stoerer {
    margin-right: -1rem;
    width: 8rem;
    height: 8rem;
    min-width: 8rem;
    min-height: 8rem;
    font-size: 0.8rem;
  }
}
#kids-info .fw--image.kids {
  background: url("../img/img-kids-1.jpg") center no-repeat;
  background-size: cover;
}
#kids-info .fw--image.kids2 {
  background: url("../img/img-kids-2.jpg") center no-repeat;
  background-size: cover;
}
#kids-info .fw--image.kids3 {
  background: url("../img/img-kids-3.jpg") top center no-repeat;
  background-size: cover;
}

.fw-image-slider .kids2 {
  opacity: 0;
  animation: fadingGallery forwards infinite ease 15s;
}
.fw-image-slider .kids3 {
  opacity: 0;
  animation: fadingGallery2 forwards infinite ease 15s;
}

@keyframes fadingGallery {
  28.333% {
    opacity: 0;
  }
  33.333%, 61.33% {
    opacity: 1;
  }
  66.666% {
    opacity: 0;
  }
}
@keyframes fadingGallery2 {
  61.33% {
    opacity: 0;
  }
  66.666% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
#fashion h3, #kids-info h3, #twen h3 {
  font-weight: 100;
  font-size: 2rem;
  font-family: Roboto, sans-serif;
}

@media (max-width: 740px) {
  #garantie--abbinder .flex-row {
    display: block;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .garantien .info--text-content {
    display: block;
  }
}

#about {
  margin-bottom: 60px;
}
#about .mw-1600 {
  border-bottom: 1px solid #000;
  padding-bottom: 60px;
}
#about h1 img {
  width: 500px;
  max-width: 100%;
}
#about h4 {
  margin-top: 30px;
  margin-bottom: 0px;
  color: black;
  font-size: 1.618rem;
  font-family: "Roboto Condensed", sans-serif;
}
#about p {
  margin-top: 30px;
  margin-bottom: 0px;
  max-width: 1200px;
}

#timeline {
  margin-bottom: 60px;
}
#timeline .mw-1600 {
  text-align: center;
}
#timeline .year {
  margin: 0 auto;
  display: block;
  position: relative;
}
#timeline .year:before {
  content: "";
  width: 2px;
  height: 100%;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  background: #003F8F;
}
#timeline .year h2 {
  margin: 0;
  font-size: 1.61rem;
}
#timeline .timestamp {
  min-width: 100%;
  font-size: 2rem;
  font-family: "Roboto Condensed", sans-serif;
  position: relative;
  display: inline-block;
  padding: 0.3em 0.6em;
  background: white;
}
#timeline .timestamp:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  width: 15px;
  height: 15px;
  background: #003F8F;
  border-radius: 50%;
}
#timeline .event {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 60px 10vw 60px 10vw;
  text-align: right;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  flex: 1 0 50%;
  max-width: 50%;
  margin-bottom: 0;
}
#timeline .event > div {
  order: 2;
}
#timeline .event img {
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin: 10px;
  order: 1;
}
#timeline .event strong {
  font-weight: 600;
  color: #003F8F;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.2rem;
}
#timeline .event:before {
  content: "";
  width: calc(10vw - 20px);
  height: 2px;
  background: #003F8F;
  position: absolute;
  left: auto;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
#timeline .event.odd {
  flex: 1 0 50%;
  left: 50%;
  margin-right: 0;
  text-align: left;
  justify-content: flex-start;
}
#timeline .event.odd img {
  order: 3;
}
#timeline .event.odd:before {
  right: auto;
  left: 0;
}

@media (max-width: 740px) {
  #timeline .mw-1600 {
    text-align: left;
  }
  #timeline .mw-1600 .year {
    display: inline-block;
    min-width: 0;
    margin: 0;
  }
  #timeline .mw-1600 .year:before {
    left: 40px;
    transform: translateX(0);
  }
  #timeline .mw-1600 .timestamp {
    min-width: 0;
    width: auto;
  }
  #timeline .mw-1600 .event {
    display: block;
    max-width: 100%;
    text-align: left;
    left: 0;
    margin-left: 40px;
  }
  #timeline .mw-1600 .event > div {
    min-width: 100%;
    order: 1 !important;
  }
  #timeline .mw-1600 .event img {
    order: 2 !important;
  }
  #timeline .mw-1600 .event:before {
    left: 0;
    right: auto;
  }
  #timeline .mw-1600 .event.odd {
    text-align: left;
    left: 0;
  }
}
#storeslider {
  width: calc(100% - 4rem);
  max-width: calc(100% - 4rem);
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 3rem;
}

#visualtraining h2,
#visualtraining-img h2 {
  margin-left: unset;
  text-align: left;
  width: 350px;
}
#visualtraining p,
#visualtraining-img p {
  max-width: 100%;
  margin-bottom: unset;
  column-gap: 4em;
  column-fill: balance;
}
#visualtraining p strong,
#visualtraining-img p strong {
  font-weight: 600 !important;
  color: #003F8F;
  font-size: 16px !important;
}
@media (max-width: 960px) {
  #visualtraining p,
#visualtraining-img p {
    column-count: 1;
  }
}

#visualtraining-img {
  display: grid;
  grid-template-areas: "img img" "content content";
}
@media (min-width: 1200px) {
  #visualtraining-img {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "img content";
  }
}
#visualtraining-img .visualtraining-div {
  padding: 5vw 30px;
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
  grid-area: content;
}
#visualtraining-img .visualtraining-img-div {
  grid-area: img;
  max-width: 100%;
}
#visualtraining-img .visualtraining-img-div img {
  max-width: 100%;
}

#visualtraining .images {
  display: flex;
  flex-wrap: wrap;
}
#visualtraining .images img {
  width: 100%;
}
@media (min-width: 1200px) {
  #visualtraining .images img {
    width: 50%;
  }
}

.marken--masonry {
  padding: 0 30px;
  margin: 0 auto 60px;
  position: relative;
  background: #fff;
}
.marken--masonry .image--grid-item.image--wrapper p {
  z-index: 1;
  align-self: flex-end;
  margin-bottom: 0;
  font-size: 1.4rem;
  color: #fff;
  font-style: italic;
  line-height: 1.2;
  font-weight: 300;
}
@media (max-width: 640px) {
  .marken--masonry .image--grid-item.image--wrapper {
    padding: 15px;
  }
  .marken--masonry .image--grid-item.image--wrapper p {
    font-size: 0.7rem;
    margin-bottom: 5px;
  }
}

.pagination--link {
  font-size: 1.2rem;
  margin-right: 5px;
  display: inline-block;
  padding: 10px;
  width: 40px;
  color: white;
  text-decoration: none;
  background: #eaeaea;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
  color: #003F8F;
  transition: all 200ms ease;
}
.pagination--link.active, .pagination--link:hover {
  color: white;
  background: #003F8F;
}
.pagination--link.all {
  width: auto;
}
@media (max-width: 740px) {
  .pagination--link {
    padding: 0 20px;
  }
  .pagination--link .pagination--link {
    width: 20px;
    font-size: 1rem;
    padding: 0;
  }
}

.image--grid-item.image--slider {
  padding: 0 !important;
}
.image--grid-item.image--slider video {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image--grid-item.image--slider img.lazy {
  max-width: 100% !important;
}

.grid--slider {
  width: 100%;
}

.hover__video {
  cursor: url(../img/cursor.svg), auto;
}

#glasdickenrechner {
  padding: 5vw 0;
}
#glasdickenrechner .centered {
  padding: 30px;
}
@media (max-width: 1100px) {
  #glasdickenrechner .centered {
    text-align: left;
  }
}
#glasdickenrechner p {
  max-width: 1100px;
}
#glasdickenrechner .iframe--wrapper {
  text-align: left;
  position: relative;
  max-width: 1100px;
  height: 0;
  margin: 0 auto;
  padding-bottom: 920px;
}
@media (max-width: 960px) {
  #glasdickenrechner .iframe--wrapper {
    padding-bottom: 90vw;
  }
}
@media (max-width: 670px) {
  #glasdickenrechner .iframe--wrapper {
    padding-bottom: 100vw;
  }
}
@media (max-width: 460px) {
  #glasdickenrechner .iframe--wrapper {
    padding-bottom: 140vw;
  }
}
#glasdickenrechner .iframe--wrapper iframe {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
#glasdickenrechner .centered--content-small {
  padding: 20px;
  margin: 0 auto;
}

#alterssichtigkeit .col-1 {
  margin: 0 0 20px;
  min-width: 280px;
}
#alterssichtigkeit .col-1:first-child {
  padding-right: 20px;
}
#alterssichtigkeit p {
  max-width: 900px;
  margin-bottom: 20px;
}

.content--container {
  max-width: 900px;
  margin: 0px auto;
  padding: 3em 2em;
}
.content--container p {
  max-width: 100%;
}
.content--container h3 {
  margin-bottom: 30px;
}
.content--container strong {
  font-weight: 600;
}

#wimpern h2, #sicca h2 {
  font-size: 1.6rem;
  font-family: "Roboto Condensed", sans-serif;
  line-height: 1.2 !important;
}
#wimpern strong, #sicca strong {
  font-weight: 600;
}

.preise--content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 3em;
}
.preise--content .artikel {
  flex: 1 0 30%;
  margin: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  position: relative;
}
.preise--content .artikel .artikel--image-container {
  position: relative;
}
.preise--content .artikel .konservierung {
  position: absolute;
  right: 0px;
  bottom: 5px;
  background: #003F8F;
  color: white;
  padding: 0.3em 0.6em;
  display: block;
}
.preise--content .artikel .artikel--image {
  max-width: 100%;
}
.preise--content .artikel .artikel--desc {
  padding: 0.6em 2em 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.preise--content .artikel .artikel--desc h3 {
  margin: 0;
  font-size: 1rem;
  font-family: Roboto, sans-serif;
  color: black;
  flex: 1 0 0;
}
.preise--content .artikel .artikel--desc .artikel--preis {
  text-align: right;
  flex: 1 0 0;
  font-weight: 600;
  font-style: normal;
  display: inline-block;
  color: #003F8F;
  font-size: 2rem;
  letter-spacing: -0.05rem;
}
.preise--content .artikel .artikel--desc .artikel--preis span {
  font-size: 1.2rem;
  font-weight: inherit;
}
@media (max-width: 960px) {
  .preise--content .artikel {
    flex: 1 0 45%;
  }
}
@media (max-width: 560px) {
  .preise--content .artikel {
    flex: 1 0 90%;
  }
  .preise--content .artikel .artikel--desc {
    padding: 1em;
  }
}

.fullheight--header.augenuntersuchung {
  background-image: url("../img/header-optometrie.jpg");
  background-position: right bottom;
}
.fullheight--header.augenuntersuchung .large__p {
  color: white;
  font-size: 3vw !important;
  font-weight: bold;
  width: 30vw;
  line-height: 1.2;
}
.fullheight--header.augenuntersuchung .mw-1800 {
  padding: 0 30px;
  height: 100%;
  display: flex;
  align-items: center;
}
@media (max-width: 740px) {
  .fullheight--header.augenuntersuchung .mw-1800 {
    padding: 0 20px;
  }
}

#optometrie-ekg img {
  width: 100%;
  min-height: 290px;
  object-fit: cover;
}

#optometrie-team .fw--image-container .fw--image {
  background-repeat: no-repeat;
  background-size: contain;
}

#optometrie--steps .mw-1800 {
  padding: 0 30px;
}
@media (max-width: 740px) {
  #optometrie--steps .mw-1800 {
    padding: 0 20px;
  }
}
#optometrie--steps .mw-1800 h4 {
  margin-bottom: 40px;
  font-size: 2.618rem;
}
#optometrie--steps .steps {
  width: 100%;
  background: #2665b5;
  padding: 30px 0;
}
#optometrie--steps .steps.steps__header {
  background: #003F8F;
}
#optometrie--steps .steps p {
  margin: 0;
  color: white;
}
#optometrie--steps .steps:nth-of-type(3) {
  background: #3d73b8;
}
#optometrie--steps .steps:nth-of-type(4) {
  background: #5f8eca;
}
#optometrie--steps .step--headline {
  color: white;
  font-size: 1.61rem;
  font-family: "Roboto Condensed", sans-serif;
  margin: 0;
  margin-bottom: 10px;
}
#optometrie--steps .step--headline:after, #optometrie--steps .step--headline:before {
  content: none;
}
#optometrie--steps ul {
  margin-top: 20px;
}
#optometrie--steps ul li {
  color: white;
}
#optometrie--steps .full {
  max-width: 100%;
  display: block;
}

#optometrie--glossar .pagination {
  margin-top: 40px;
}
#optometrie--glossar > .mw-1800 {
  padding: 60px 30px;
}
#optometrie--glossar > .mw-1800 h4 {
  margin-bottom: 30px;
  font-size: 2.618rem;
}
#optometrie--glossar article {
  padding: 40px 0;
  border-bottom: 1px solid #eaeaea;
}
#optometrie--glossar article h5 {
  font-size: 1.61rem;
  margin: 0;
  margin-bottom: 10px;
}
#optometrie--glossar article h5:after, #optometrie--glossar article h5:before {
  content: none;
}
#optometrie--glossar article p {
  margin: 0;
  max-width: 1100px;
}

@media (max-width: 768px) {
  #optometrie-intro .flex-row {
    display: block;
  }
  #optometrie-intro .flex-row .col-1 {
    margin-bottom: 1rem;
  }
  #optometrie-intro .flex-row .col-1 .image--slider {
    width: 100%;
  }
}

.price {
  flex: 1 0 45%;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2), 1px 1px 1.5rem rgba(0, 0, 0, 0.15);
  margin-bottom: 2rem;
}
.price:first-child {
  margin-right: 2rem;
}
.price--body {
  flex: 1 0 80%;
  padding: 1rem;
  background: white;
}
.price--body * {
  color: black !important;
}
.price--body h5 {
  color: #003F8F !important;
}
.price--footer {
  background: white;
  background: #003F8F;
  flex: 1 0 100px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.price--footer strong {
  font-weight: bold;
  width: 100%;
  display: block;
  font-size: 1.61rem;
}
.price--footer * {
  text-align: center;
}
.price--footer span {
  line-height: 1.3 !important;
  font-weight: normal;
  display: block;
}

.price--row {
  display: flex;
  align-items: flex-start;
}
@media (max-width: 768px) {
  .price--row {
    display: block;
  }
  .price--row .price:first-child {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

.dark {
  background: #1C1C1B;
}
.dark * {
  color: white;
}

.padding-large {
  padding: 5vw 30px;
}

.grid-2-4 {
  margin: 4rem auto;
  display: grid;
  grid-template-areas: "g1 g2" "g3 g4";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: calc(50vw - 3rem) calc(50vw - 3rem);
  justify-content: center;
  grid-gap: 1rem;
}
@media (min-width: 768px) {
  .grid-2-4 {
    grid-template-areas: "g1 g2 g3 g4";
    grid-template-columns: repeat(4, 170px);
    grid-template-rows: 170px;
  }
}
@media (min-width: 1200px) {
  .grid-2-4 {
    grid-gap: 2rem;
    grid-template-columns: repeat(4, 245px);
    grid-template-rows: 245px;
  }
}
.grid-2-4 > *:nth-child(1) {
  grid-area: g1;
}
.grid-2-4 > *:nth-child(2) {
  grid-area: g2;
}
.grid-2-4 > *:nth-child(3) {
  grid-area: g3;
}
.grid-2-4 > *:nth-child(4) {
  grid-area: g4;
}

.white__box {
  background: white;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.white__box * {
  color: black;
  padding: 0;
  margin: 0 !important;
}

.blue__bubble {
  margin-top: 30px;
  background: #003F8F;
  padding: 40px;
  border-radius: 0 1000px 1000px 1000px;
  width: 420px;
  height: 420px;
}
@media (max-width: 440px) {
  .blue__bubble {
    width: calc(100vw - 20px);
    height: auto;
    min-height: calc(100vw - 20px);
    padding: 20px;
    padding-right: 10px;
    padding-bottom: 70px;
  }
  .blue__bubble li, .blue__bubble p {
    font-size: 0.9rem;
  }
}
@media (min-width: 961px) and (max-width: 1200px) {
  .blue__bubble {
    width: 360px;
    height: 360px;
    padding: 20px;
  }
  .blue__bubble li, .blue__bubble p {
    font-size: 0.9rem;
  }
}
.blue__bubble * {
  color: white;
}

#visioncare--info {
  margin-bottom: 40px;
}
#visioncare--info .info--text-content {
  padding-bottom: 60px;
  padding-top: 0;
}
@media (max-width: 740px) {
  #visioncare--info .info--text-content {
    padding-bottom: 150px;
  }
}
#visioncare--info .info--text-content ul.last {
  max-width: 70%;
}
@media (max-width: 740px) {
  #visioncare--info .info--text-content ul.last {
    max-width: 100%;
  }
}
#visioncare--info .kl--geschenk {
  position: absolute;
  right: -20px;
  bottom: -20px;
  width: 30%;
  min-width: 200px;
}
@media (max-width: 1950px) {
  #visioncare--info .kl--geschenk {
    right: 0px;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #visioncare--info .info--teaser-content .constrained--content .info--image {
    height: auto;
  }
}
@media (max-width: 1100px) {
  #visioncare--info .info--wrapper {
    display: block;
  }
  #visioncare--info .info--wrapper .info--teaser-content .constrained--content {
    max-width: 100%;
    display: flex;
    flex-direction: row;
  }
  #visioncare--info .info--wrapper .info--teaser-content .constrained--content .info--icon {
    display: none;
    min-width: 260px;
    margin: 20px;
  }
  #visioncare--info .info--wrapper .info--teaser-content .constrained--content .info--image {
    margin: 30px 0;
    max-width: 60vw;
    min-width: 150px;
  }
}
@media (max-width: 740px) {
  #visioncare--info .info--wrapper {
    display: block;
  }
  #visioncare--info .info--wrapper .info--teaser-content .constrained--content {
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }
  #visioncare--info .info--wrapper .info--teaser-content .constrained--content .info--icon {
    display: none;
  }
  #visioncare--info .info--wrapper .info--teaser-content .constrained--content .info--image {
    margin: 30px 0;
    max-width: 150px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .icon-linse {
    width: auto !important;
    text-align: center;
    max-width: 140px;
  }
}
.pricebox {
  padding: 1rem;
  background: white;
  margin-top: 3rem;
}
.pricebox h5 {
  margin: 0 0 1rem 0;
  font-weight: bold !important;
}
.pricebox h5:after {
  content: none;
}
.pricebox strong {
  font-weight: bold !important;
}
@media (min-width: 768px) {
  .pricebox ul {
    max-width: 80%;
  }
}
.pricebox ul li {
  margin-left: 20px;
  font-weight: normal;
  margin-top: 0.5rem;
}
.pricebox .mw500 {
  max-width: 500px;
}

#service .mw-1800 {
  padding: 60px 30px;
}
#service .mw-1800 h3 {
  max-width: 900px;
}
#service .mw-1800 .flex-row {
  margin: 30px 0;
}
#service .mw-1800 .flex-row .col-1 {
  margin-right: 20px;
}
#service .mw-1800 .flex-row .col-1:last-child {
  margin-right: 0;
}
@media (max-width: 860px) {
  #service .mw-1800 .flex-row {
    display: block;
  }
  #service .mw-1800 .flex-row .col-1 {
    margin-right: 0;
  }
}
#service .mw-1800 .image--wrapper {
  padding-bottom: 60%;
}
#service h1 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.61rem;
  text-transform: none;
  line-height: 1.4;
}
@media (max-width: 740px) {
  #service h1 {
    font-size: 1.2rem;
  }
}
#service .flexbox {
  display: flex;
  max-width: 1600px;
  margin: 0em auto;
  flex-wrap: wrap;
}
#service .flexbox > div {
  flex: 1 0 30%;
  display: flex;
  align-items: center;
}
#service .flexbox > div .fw--image {
  height: 100%;
  background-size: cover;
  background: #000;
  background-position: center;
  display: flex;
  align-items: center;
}
#service .flexbox > div .fw--image img {
  display: block;
  max-width: 100%;
}
#service .flexbox > div .fw--text-wrapper {
  padding: 10%;
}
#service .flexbox .fw--image.big-image {
  height: 100vh;
  background-size: cover;
}
@media (max-width: 900px) {
  #service .flexbox > div {
    min-height: 550px;
    min-width: 250px;
  }
  #service .flexbox > div:last-of-type {
    min-width: 100%;
    min-height: 200px;
  }
  #service .flexbox > div:last-of-type .fw--text-wrapper {
    padding: 50px;
  }
  #service .flexbox .fw--image.big-image {
    height: 400px;
    background-size: cover;
  }
}

.manufaktur-slider {
  width: 50%;
  max-width: 50%;
  padding: 0 2rem;
  height: auto;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .manufaktur-slider {
    width: 100%;
    max-width: 100%;
  }
}
.manufaktur-slider .slick-prev, .manufaktur-slider .slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  cursor: pointer;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  color: black;
}
.manufaktur-slider .slick-prev .fa, .manufaktur-slider .slick-next .fa {
  font-size: 35px;
}
.manufaktur-slider .slick-prev {
  left: 0;
}
.manufaktur-slider .slick-next {
  right: 0;
}

#toenungssimulator .mw-1800 {
  padding: 5vw 30px;
}
@media (max-width: 740px) {
  #toenungssimulator .mw-1800 .flex-row {
    display: block;
  }
}
#toenungssimulator .mw-1800 .col-1 {
  padding-top: 30px;
  padding-right: 30px;
}
#toenungssimulator .grey {
  background: #eaeaea;
  padding: 30px;
}
#toenungssimulator .grey img {
  margin: 20px 0;
  max-width: 100%;
}

#arbeitsplatzbrillen--info h4 {
  color: white;
}
#arbeitsplatzbrillen--info .constrained--content {
  justify-content: center;
}
#arbeitsplatzbrillen--info .info--icon img {
  width: 40%;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #arbeitsplatzbrillen--info .info--icon img {
    width: auto;
    height: auto;
    max-width: 85px;
  }
}

.reverse {
  flex-wrap: wrap-reverse;
}

.arbeitsplatzbrillen .col-1 p, .arbeitsplatzbrillen .col-1 h3 {
  width: 80%;
}
@media (max-width: 1200px) {
  .arbeitsplatzbrillen .col-1 p, .arbeitsplatzbrillen .col-1 h3 {
    width: 100%;
  }
}
.arbeitsplatzbrillen h2 {
  font-size: 2.618rem !important;
}
@media (max-width: 740px) {
  .arbeitsplatzbrillen h2 {
    font-size: 2rem;
  }
}
.arbeitsplatzbrillen .fw--text-wrapper {
  padding-left: 30px;
}
.arbeitsplatzbrillen .arbeitsplatz2 {
  background-image: url(../img/b_macht-vor-dem-bildschirm.jpg);
  background-position: bottom !important;
  background-size: cover;
}

.arbeitsplatz1 {
  background-image: url(../img/b_arbeitsplatzbrille.jpg);
  padding-bottom: 0;
  background-position: center 25%;
  background-size: cover;
  min-height: 500px;
  min-width: 400px;
}
@media (max-width: 480px) {
  .arbeitsplatz1 {
    min-width: 280px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .intro--arbeitsplatz .col-1 {
    min-width: 280px;
  }
}

#arbeitsplatzbrillen--info .info--wrapper {
  max-width: 100%;
}

#termin {
  margin: 40px auto;
  padding: 20px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #termin {
    margin: 80px auto;
  }
}

#yoga--content {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 1rem;
}
#yoga--content a.outline-btn {
  border: 2px solid #003F8F;
  color: #003F8F;
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-weight: bold;
  margin-top: 1rem;
  display: block;
  transition: all 300ms ease;
}
#yoga--content a.outline-btn:hover {
  background: #003F8F;
  color: white;
}

#highlights--content {
  display: block;
  text-align: center;
  overflow-x: hidden;
  width: 100vw;
}
#highlights--content .logos {
  margin-top: 20px;
}
#highlights--content main {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  width: 100%;
  max-width: 30rem;
  margin: 0 auto;
}
#highlights--content main .outline-btn {
  border: 2px solid #003F8F;
  color: #003F8F;
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 1rem;
  transition: all 300ms ease;
}
#highlights--content main .outline-btn:hover {
  background: #003F8F;
  color: white;
}
#highlights--content header {
  padding: 0;
  margin: 0;
  background-size: cover;
  width: 100%;
}
#highlights--content header img {
  width: 100%;
  max-width: 100%;
}
#highlights--content .logo--seite {
  text-align: left;
  width: 50%;
  max-width: 50%;
  height: 0;
  background: black;
  position: relative;
  display: block;
}
#highlights--content .logo--seite a {
  text-align: left;
  display: block;
  left: 1rem;
  position: relative;
}
#highlights--content .logo--seite img {
  position: relative;
  z-index: 10;
  max-width: 18vw;
  width: 180px;
  margin-right: auto;
}
#highlights--content main {
  padding-top: 2rem !important;
  padding-bottom: 2rem;
}
#highlights--content main * {
  text-align: left;
}
#highlights--content h3 {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-family: Roboto, sans-serif;
}
#highlights--content h3 img {
  flex: 1;
  max-width: 105px;
  position: relative;
  top: -1px;
  margin-left: 4px;
}
@media (min-width: 741px) {
  #highlights--content h3 img {
    max-width: 150px;
    top: -2px;
    margin-left: 5px;
  }
}
#highlights--content #highlights--container {
  max-width: 80rem;
  margin: 0 auto;
}
#highlights--content #highlights--container img {
  width: 100%;
  display: block;
  height: auto;
}
#highlights--content #highlights--container p {
  max-width: 100%;
  margin-bottom: 20px;
}
@media (min-width: 800px) {
  #highlights--content .soft--row {
    display: flex;
    align-items: center;
  }
  #highlights--content .soft--row .left--content {
    width: 60%;
  }
  #highlights--content .soft--row .image--container {
    width: 40%;
    padding-left: 2rem;
    margin: 0 !important;
    transform-origin: center;
  }
}
#highlights--content .flex--container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "one" "two" "three" "four" "five" "six";
  grid-gap: 10px;
  margin-bottom: 20px;
}
@media (min-width: 560px) {
  #highlights--content .flex--container {
    grid-template-areas: "one two" "three four" "five six";
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 900px) {
  #highlights--content .flex--container {
    grid-template-areas: "one two three" "four five six";
    grid-template-columns: 1fr 1fr 1fr;
  }
}
#highlights--content .info--box {
  padding: 20px;
  margin: 0;
  background: #003F8F;
}
#highlights--content .info--box:nth-child(1) {
  grid-area: one;
}
#highlights--content .info--box:nth-child(2) {
  grid-area: two;
}
#highlights--content .info--box:nth-child(3) {
  grid-area: three;
}
#highlights--content .info--box:nth-child(4) {
  grid-area: four;
}
#highlights--content .info--box:nth-child(5) {
  grid-area: five;
}
#highlights--content .info--box:nth-child(6) {
  grid-area: six;
}
#highlights--content .info--box * {
  color: white;
}
#highlights--content .info--box strong {
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
  font-size: 20px;
}
#highlights--content .info--box ul {
  margin-bottom: -10px;
}
#highlights--content .info--box ul li {
  margin-left: 20px;
  margin-bottom: 10px;
  font-weight: normal;
}
#highlights--content .image--container {
  margin-left: -2rem;
  margin-right: -2rem;
  margin-bottom: 20px;
}
#highlights--content .as-btn.phone, #highlights--content .phone.as-btn-white {
  padding-right: 20px;
}
#highlights--content .as-btn.phone:after, #highlights--content .phone.as-btn-white:after {
  content: none;
}

#sehtest .headline--left,
#brille .headline--left,
#kontaktlinsen .headline--left,
#myopieprävention .headline--left,
#visualtraining .headline--left,
#visualtraining-img .headline--left {
  font-size: 1rem;
  color: #2e2e2e;
  line-height: 1.6;
  font-weight: 400;
}
#sehtest .headline--left h2,
#brille .headline--left h2,
#kontaktlinsen .headline--left h2,
#myopieprävention .headline--left h2,
#visualtraining .headline--left h2,
#visualtraining-img .headline--left h2 {
  text-align: left;
  margin-left: unset;
}
#sehtest .headline--left strong,
#brille .headline--left strong,
#kontaktlinsen .headline--left strong,
#myopieprävention .headline--left strong,
#visualtraining .headline--left strong,
#visualtraining-img .headline--left strong {
  font-weight: 600 !important;
  color: #003F8F;
  font-size: 16px !important;
}
#sehtest .headline--left ul li,
#brille .headline--left ul li,
#kontaktlinsen .headline--left ul li,
#myopieprävention .headline--left ul li,
#visualtraining .headline--left ul li,
#visualtraining-img .headline--left ul li {
  font-size: 1rem;
  color: #2e2e2e;
  line-height: 1.6;
  font-weight: 400;
  margin-left: 15px;
}