.panorama--image
    height: 50vw
    max-height: 500px
    position: relative
    width: 100%
    margin-top: 60px
    overflow: hidden
    img
        position: absolute
        left: 0
        transform: translateX(-25%)
        top: 0
        height: 100%
    #image-range
        appearance: none
        position: absolute
        left: 0
        bottom: 8px
        width: 100%
        background: transparent
        outline: none
        height: 12px
        opacity: 1
    
   

    .custom--slider
        width: 100%
        height: 21px
        position: absolute
        left: 0
        bottom: 0px
        border-bottom: 7px solid white
        .slidebar
            height: 100%
            position: absolute
            left: 0
            width: 50%
            background: $blue
            pointer-events: none
            
            // &:after
            //     pointer-events: none
            //     content: ""
            //     position: absolute
            //     right: -10px
            //     top: 50%
            //     transform: translate(0, -50%)
            //     width: 20px
            //     height: 20px
            //     background: red
            //     border-radius: 50%
            //     cursor: pointer




#image-range 
    -webkit-appearance: none
    width: 100%
    height: 25px
    background: lighten($blue, 10%)
    outline: none
    opacity: 0.7
    -webkit-transition: .2s
    transition: opacity .2s
    &:hover, &:active, &:target, &:focus
        opacity: 1

    &::-webkit-slider-thumb 
        -webkit-appearance: none
        appearance: none
        width: 25px
        height: 25px
        background: url('../img/icon-move.svg'), lighten($blue, 10%)
        background-size: 14px, cover
        background-repeat: no-repeat
        background-position: center
        cursor: pointer
        border-radius: 50%
        border: 1px solid lighten($blue, 20%)


    &::-moz-range-thumb 
        width: 25px
        height: 25px
        background: url('../img/icon-move.svg'), lighten($blue, 10%)
        background-size: 14px, cover
        background-repeat: no-repeat
        background-position: center
        cursor: pointer
        border-radius: 50%
        border: 1px solid lighten($blue, 20%)



.mitarbeiter--container    
    padding: 0px 20px 40px 20px
    width: 100%
    //display: grid
    display: flex
    flex-wrap: wrap
    justify-content: flex-start
    grid-template-columns: repeat(4, 1fr)
    //grid-gap: 20px
    // @media (max-width: 1200px)
    //     grid-template-columns: repeat(3, 1fr)
    // @media (max-width: 1040px)
    //     grid-template-columns: repeat(2, 1fr)
    // @media (max-width: 870px)
    //     grid-template-columns: repeat(2, 1fr)
    // @media (max-width: 640px)
    //     grid-template-columns: repeat(2, 1fr)
    // @media (max-width: 520px)
    //     grid-template-columns: repeat(1, 1fr)
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
        display: flex
        padding: 0px 0 40px 20px
 
.mitarbeiter
    text-decoration: none
    position: relative
    width: 18%
    max-width: calc(100% / 5 - 1rem)
    // min-width: 31%
    flex: 1 0 18%
    margin-right: 1rem
    margin-bottom: 10px
    border-bottom: 6px solid $blue
    overflow: hidden
    @media (max-width: 1400px)
        flex: 1 0 21%
        width: 21%
        max-width: calc(100% / 4 - 1rem)
    @media (max-width: 1000px)
        flex: 1 0 27%
        width: 27%
        max-width: calc(100% / 3 - 1rem)
    @media (max-width: 768px)
        flex: 1 0 35%
        width: 35%
        max-width: calc(100% / 2 - 1rem)
    @media (max-width: 468px)
        flex: 1 0 100%
        width: 100%
        margin-right: 0
        max-width: 100%
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
        max-width: 100%
        min-width: 31%
        flex: 1 0 0
        margin-right: 20px
        margin-bottom: 10px
    .mitarbeiter--image
        display: block
        width: 100%        
        transition: .2s ease-in-out
        z-index: 1
        height: 100%
        &.bild--overlay
            position: absolute
            width: 100%
            //height: 100%
            left: 0
            top: 0
            opacity: 0
        
        .plus-small
            border: 2px solid rgba(white, 0.8)
            display: block
            width: 35px
            height: 35px
            position: absolute
            right: 20px
            bottom: 20px
            text-decoration: none
            border-radius: 50%
            display: flex
            align-items: center
            justify-content: center
            transition: all 300ms ease
            cursor: pointer
            span
                font-size: 1.4rem
                color: white
                line-height: 1
                font-weight: 400
                text-align: center
                position: relative
                top: -1px
    &:hover, &:active, &:target, &:focus
        .bild--overlay
            opacity: 1
        .mitarbeiter--caption
            transform: translateY(0)
        .mitarbeiter--hover
            opacity: 1
    .mitarbeiter--hover
        position: absolute
        top: 0
        z-index: 0



       
        img
            position: absolute
            left: 50%
            top: 50%
            transform: translate(-50%,-50%)
            height: 100%
            transition: all 300ms ease
    &:hover, &:active, &:target, &:focus
        .plus-small
            background: rgba(white, 0.3)
            border-width: 4px
    .mitarbeiter--caption
        // margin-top: 20px
        position: absolute
        bottom: 0%
        padding: 1rem
        transform: translateY(100%)
        transition: .2s ease-in-out
        z-index: 3
        @media (max-width: 640px)
            padding: 1rem
        .mitarbeiter--name
            color: white
            font-size: 1rem !important
            font-weight: bold
            text-transform: inherit
            margin: 0
        p
            margin-bottom: 0
            font-size: 0.825rem
            color: white
            margin-bottom: 10px
        .mitarbeiter--email
            color: $blue
            padding: 2px 10px
            border-radius: 4px
            font-weight: bold
            font-size: 0.8725rem
            background: white
            display: inline-block
            text-decoration: none
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
            p
                margin-bottom: 20px
        // @media (max-width:)

#overlay
    .mitarbeiter--overlay
        //transform: translateY(120vw)
        transition: all 300ms ease
        //visibility: hidden
        position: fixed
        top: 80px
        bottom: 0
        left: 0
        right: 0
        background: white
  
        .mitarbeiter--image
            width: 100%
            position: absolute
            left: 0
            top: 0
            height: 100%
            background-size: cover!important
            background-position: left 30% center!important
        .mitarbeiter--caption
            position: absolute
            width: 50%
            top: 0
            left: 50%
            padding: 40px
            padding-top: 10vw    
            min-height: 60%
            display: flex
            align-items: center
            .close
                position: absolute
                top: 30px
                right: 30px
                font-size: 2rem
                cursor: pointer
                color: white
                text-decoration: none
            .mitarbeiter--text-content
                max-width: 600px
                position: relative
                margin: 0 auto
            .mitarbeiter--name
                text-align: left
                margin: 0
                text-transform: lowercase
                width: 100%
                font-size: 4rem
                line-height: 1
                margin-bottom: 20px
                color: white
            h4
                color: white
                line-height: 1.4
                font-size: 1.6rem
                font-style: italic
                font-weight: 300
                margin-top: 30px
            p
                color: white
                font-weight: 200
                margin-bottom: 0
    @media (max-width: 840px)
        .mitarbeiter--overlay
            overflow-y: auto
            background: $blue
            .mitarbeiter--image
                position: relative         
                width: 100%
                height: 50%
                background-position: left 20% center!important

            .mitarbeiter--caption
                position: relative
                left: 0
                width: 100%
                min-height: 0
                background: $blue
                .close
                    right: 20px
                    top: 0px
                .mitarbeiter--text-content
                    .mitarbeiter--name
                        font-size: 2rem
                        margin-bottom: 10px
                    h4
                        font-size: 1.2rem
                    p
                        line-height: 1.6
                        font-size: 0.875rem





input[type=range]::-ms-track 
    width: 100%
    height: 50px!important
    cursor: pointer
    background: transparent
    border-color: transparent
    color: transparent
    overflow: visible

input[type=range]::-ms-fill-lower 
    background: #003f8f
    border: 0px solid #010101
    border-radius: 2.6px
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d
    opacity: 0

input[type=range]::-ms-fill-upper 
    background: #003f8f
    border: 0px solid #010101
    border-radius: 2.6px
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d
    opacity: 0

input[type=range]::-ms-thumb 
    width: 25px
    height: 25px
    background: url('../img/icon-move.svg'), lighten($blue, 10%)
    background-size: 14px, cover
    background-repeat: no-repeat
    background-position: center
    cursor: pointer
    border-radius: 50%
    border: 1px solid lighten($blue, 20%)

.philosophie
    padding: 2rem
    margin-top: 2rem
    display: inline-block
    h2
        margin: 0 0 1rem!important
        text-align: left
        width: 100%