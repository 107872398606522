#arbeitsplatzbrillen--info
    h4 
        color: white
    .constrained--content
        justify-content: center
        
    .info--icon 
        img
            width: 40%
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
                width: auto
                height: auto
                max-width: 85px

.reverse
    flex-wrap: wrap-reverse

.arbeitsplatzbrillen
    .col-1
        p, h3 
            width: 80%
            @media (max-width: 1200px)
                width: 100%
    h2             
        font-size: 2.618rem!important
        @media (max-width: 740px)
            font-size: 2rem
    .fw--text-wrapper
        padding-left: 30px
    .arbeitsplatz2
        background-image: url(../img/b_macht-vor-dem-bildschirm.jpg)
        background-position: bottom!important
        background-size: cover
.arbeitsplatz1
    background-image: url(../img/b_arbeitsplatzbrille.jpg)
    // max-width: 800px
    padding-bottom: 0
    background-position: center 25%
    background-size: cover
    min-height: 500px
    min-width: 400px
    @media (max-width: 480px)
        min-width: 280px

.intro--arbeitsplatz
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
        .col-1
            min-width: 280px
#arbeitsplatzbrillen--info
    .info--wrapper
        max-width: 100%