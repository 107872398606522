

#usercentrics-button 
    #uc-consents-info-modal 
        .uc-consents-info-modal-wrapper 
            .uc-modal-header
                background-color: $blue!important
                .uc-info-modal-search-bar input
                    background-color: #fff!important
                    color: black!important
                    &::placeholder
                        color: black!important

    #uc-center-modal .uc-powered-by-footer
        display: none!important

    .uc-powered-by-footer
        display: none!important

    #uc-consents-info-modal 
        .uc-consents-info-modal-wrapper 
            .uc-info-modal-sidebar 
                .uc-powered-by
                    display: none!important

                .uc-info-modal-sidebar-nav
                    background: #cfcfcf
    .uc-banner-links
        *
            font-size: 12px!important

#uc-btn-close-main-corner-modal svg 
    use, #fingerprint
        fill: $blue!important

#uc-btn-deny-banner,
#uc-btn-more-info-banner
    margin-top: 1rem
    font-size: 14px!important
    border: 1px solid rgba(white, 0.3)
    transition: all 300ms ease
    &:hover
        background: white!important
        color: $blue!important
#uc-btn-accept-banner
    font-weight: bold
    font-size: 14px!important
    text-transform: uppercase
    letter-spacing: 1px
    transition: all 300ms ease
    &:hover
        background: lighten(#f9bb29, 10%)!important