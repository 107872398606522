.as-btn
    font-weight: 100
    color: white
    background: $blue
    padding: 14px 45px 14px 24px
    text-decoration: none
    position: relative
    transition: 0.2s ease-in-out
    margin-right: 30px
    display: inline-block
    font-size: 1rem
    letter-spacing: 0
    font-family: $main-font
    font-weight: 400
    margin-right: 15px
    margin-bottom: 15px
    @media (max-width: 500px)
        margin-bottom: 20px
        padding: 12px 38px 14px 16px
        font-size: .8rem
        margin-right: 11px
    &::after 
        content: url('../img/arrow-right.svg')
        display: inline-block
        position: absolute
        right: 20px
        height: 20px
        width: 10px
        top: 52%
        transform: translateY(-10px)
        transition: .2s ease-in-out
    &:hover 
        opacity: 0.9
        &::after
            right: 15px
    &-white
        @extend .as-btn
        background: white
        color: $blue!important
        &::after 
            content: url('../img/arrow-right-blue.svg')
.two-liner
    display: block
    margin: 0 auto
    max-width: 250px

