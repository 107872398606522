.as-slider, #header--slider, .slider-for, #testsieger
	height: 100%
	position: relative
	.slick-list, .slick-track
		height: 100%
	.slide
		//height: 100%
		position: relative
		// background-image: url('../img/tamara_roscher.jpg')
		background-size: cover
		background-position: center

	.slider-1-1
		background-image: url('../img/tamara_roscher.jpg')
		background-size: cover
		background-position: center
		position: relative
	.slider-1-2
		background-image: url('../img/karl_amon.jpg')
		background-size: cover
		background-position: center
		position: relative
	.slider-1-3
		background-image: url('../img/header1.jpg')
		background-size: cover
		background-position: center
		position: relative
	.slider-2-1
		background-image: url('../img/brands/oliver-peoples-brand-01.jpg')
		background-size: cover
		background-position: top
		position: relative
	.slider-2-2
		background-image: url('../img/brands/oliver-peoples-brand-02.jpg')
		background-size: cover
		background-position: top
		position: relative
	.slider-2-3
		background-image: url('../img/brands/oliver-peoples-brand-03.jpg')
		background-size: cover
		background-position: center
		position: relative
	.slider-2-4
		background-image: url('https://images.aceandtate.com/image/upload/c_scale,w_1200/:spree/public/spree/products/5546/original/russel-smoke-FACE-m.jpg')
		background-size: cover
		background-position: center
		position: relative

	.slick-prev
		position: absolute
		z-index: 99
		left: 30px
		top: 50%
		transform: translateY(-50%)
		cursor: pointer
		i
			font-size: 5rem
			color: white
			opacity: 0.5
			transition: 0.3s ease
		&:hover
			i
				opacity: 0.8
	.slick-next
		position: absolute
		z-index: 99
		top: 50%
		transform: translateY(-50%)
		right: 30px
		cursor: pointer
		i
			font-size: 5rem
			color: white
			opacity: 0.5
			transition: 0.3s ease
		&:hover
			i
				opacity: 0.8
	.slider-comment
		position: absolute
		// background: white
		opacity: 0.7
		bottom: 2em
		right: 0
		z-index: 9999
		width: 70%
		padding: 2em
		@media (max-width: 1040px)
			width: 100%
			bottom: 0

		.comment
			font-size: 1.4rem
			color: white
			font-style: italic
			margin-bottom: 10px
			line-height: 1.2
			font-weight: 300
			@media (max-width: 1000px)
				font-size: 1.5rem
			@media (max-width: 600px)
				font-size: .9rem
		.name
			font-weight: 300
			color: white
			margin-bottom: 0
			font-style: italic
			font-size: 1rem
			line-height: 1.2
			@media (max-width: 600px)
				font-size: 0.9rem

#header--slider
	background: url('../img/img-augenblick1.jpg') center
	background-size: cover
.testsieger--section
	@media (max-width: 960px)
		.fw--image-container
			min-height: 0!important
			height: auto!important

#xmas
	// background: url('../img/xmas.jpg') top center
	background: #efefef
	background: $blue
	background-size: cover
	display: flex
	align-items: center
	justify-content: center
	img
		max-height: 100%
		max-width: 100%
	
#testsieger
	background: url('../img/img-VisionCare_lounge.jpg') center
	background-size: cover
	display: flex
	align-items: center
	justify-content: center
	padding: 2rem
	transform: none
	left: 0
	top: 0

	img
		width: 500px
		max-width: 60%
	@media (max-width: 960px)
		height: auto
		img
			max-width: 50%
.glass-slider, .grid--slider
	visibility: hidden!important
	display: none!important
	&.slick-initialized
		display: block!important
		visibility: visible!important

.slick-dots
	display: flex
	justify-content: center
	align-items: center
	margin-top: 1rem
	li
		width: 0.7rem
		height: 0.7rem
		border-radius: 50%
		background: white
		border: 1px solid $blue
		display: block
		margin: 0 0.3rem
		cursor: pointer
		&.slick-active
			background: $blue
		*
			display: none
		&:marker
			display: none