.fullheight--header.augenuntersuchung
	background-image: url('../img/header-optometrie.jpg')
	background-position: right bottom
	// min-height: 20rem
	.large__p
		color: white
		font-size: 3vw!important
		font-weight: bold
		width: 30vw
		line-height: 1.2
	.mw-1800 
		padding: 0 30px
		height: 100%
		display: flex
		align-items: center
		@media (max-width: 740px)
			padding: 0 20px
#optometrie-ekg
	img 
		width: 100%
		min-height: 290px
		object-fit: cover

#optometrie-team
	.fw--image-container
		.fw--image
			background-repeat: no-repeat
			background-size: contain

#optometrie--steps
	.mw-1800
		padding: 0 30px
		@media (max-width: 740px)
			padding: 0 20px
		h4
			margin-bottom: 40px
			font-size: 2.618rem
	.steps
		width: 100%
		background: desaturate(lighten($blue, 15%), 35%)
		padding: 30px 0
		&.steps__header
			background: $blue
		p
			margin: 0
			color: white
		&:nth-of-type(3)
			background: desaturate(lighten($blue, 20%), 50%)
		&:nth-of-type(4)
			background: desaturate(lighten($blue, 30%), 50%)
		
	.step--headline
		color: white
		font-size: 1.61rem
		font-family: $condensed-font
		&:after, &:before
			content: none
		margin: 0
		margin-bottom: 10px
	ul
		margin-top: 20px
		li
			color: white
	// .summe
	//	 width: 100%
	//	 max-width: 100%
	//	 padding-top: 1rem
	//	 display: block
	//	 margin-top: 1rem!important
	//	 display: block
	//	 border-top: 3px double #fff
	//	 strong
	//		 font-weight: bold
	.full
		max-width: 100%
		display: block
#optometrie--glossar
	.pagination
		margin-top: 40px
	&>.mw-1800
		h4
			margin-bottom: 30px
			font-size: 2.618rem

		padding: 60px 30px
	article
		h5
			font-size: 1.61rem
			margin: 0
			margin-bottom: 10px
			&:after, &:before
				content: none
		padding: 40px 0 
		p
			margin: 0
			max-width: 1100px
		border-bottom: 1px solid $grey
#optometrie-intro
	.flex-row
		@media (max-width: 768px)
			display: block
			.col-1
				margin-bottom: 1rem
				.image--slider
					width: 100%

.price
	flex: 1 0 45%
	&:first-child
		margin-right: 2rem
	box-shadow: 0 0 0.5rem rgba(black, 0.20), 1px 1px 1.5rem rgba(black, 0.15)
	margin-bottom: 2rem
	&--body
		flex: 1 0 80%
		padding: 1rem
		background: white
		*
			color: black!important
		h5
			color: $blue!important
	&--footer
		background: white
		background: $blue
		flex: 1 0 100px
		padding: 1rem
		display: flex
		align-items: center
		justify-content: center
		strong
			font-weight: bold
			width: 100%
			display: block
			// color: $blue
			font-size: 1.61rem
		*
			text-align: center
		span
			// color: black!important
			line-height: 1.3!important
			font-weight: normal
			display: block
.price--row
	display: flex
	align-items: flex-start
	@media (max-width: 768px)
		display: block
		.price
			&:first-child
				margin-right: 0
				margin-bottom: 2rem

.dark
	background: #1C1C1B
	// padding: 60px 20px
	*
		color: white
.padding-large
	padding: 5vw 30px
.grid-2-4
	margin: 4rem auto

	display: grid
	grid-template-areas: "g1 g2" "g3 g4"
	grid-template-columns: 1fr 1fr
	grid-template-rows: calc(50vw - 3rem) calc(50vw - 3rem)
	justify-content: center
	grid-gap: 1rem
	@media  (min-width: 768px)
		grid-template-areas: "g1 g2 g3 g4"
		grid-template-columns: repeat(4, 170px)
		grid-template-rows: 170px
	@media  (min-width: 1200px)
		grid-gap: 2rem
		grid-template-columns: repeat(4, 245px)
		grid-template-rows: 245px
	> *
		&:nth-child(1)
			grid-area: g1
		&:nth-child(2)
			grid-area: g2
		&:nth-child(3)
			grid-area: g3
		&:nth-child(4)
			grid-area: g4
.white__box
	background: white
	padding: 2rem
	display: flex
	align-items: center
	justify-content: center

	*
		color: black
		padding: 0
		margin: 0!important
.blue__bubble
	margin-top: 30px
	background: $blue
	padding: 40px
	border-radius: 0 1000px 1000px 1000px
	width: 420px
	height: 420px
	@media (max-width: 440px)
		width: calc(100vw - 20px)
		height: auto
		min-height: calc(100vw - 20px)
		padding: 20px
		padding-right: 10px
		padding-bottom: 70px
		li, p
			font-size: 0.9rem
	@media (min-width: 961px) and (max-width: 1200px)
		width: 360px
		height: 360px
		padding: 20px
		li, p
			font-size: 0.9rem
	*
		color: white
	