#visualtraining,
#visualtraining-img
    h2
        margin-left: unset
        text-align: left
        width: 350px
    p
        // padding-top: 2em
        max-width: 100%
        // column-count: 2
        margin-bottom: unset
        column-gap: 4em
        column-fill: balance
        strong
            font-weight: 600!important
            color: $blue
            font-size: 16px!important
    @media (max-width: 960px)
        p
            column-count: 1
#visualtraining-img
    display: grid
    // grid-template-columns: 1fr 1fr
    grid-template-areas: "img img" "content content"
    @media( min-width: 1200px )
        grid-template-columns: 1fr 1fr
        grid-template-areas: "img content"
    .visualtraining-div
        padding: 5vw 30px
        max-width: 1800px
        margin-left: auto
        margin-right: auto
        grid-area: content
    .visualtraining-img-div
        grid-area: img
        max-width: 100%
        img
            max-width: 100%
#visualtraining
    .images
        display: flex
        flex-wrap: wrap
        img
            width: 100%
            @media( min-width: 1200px )
                width: 50%