.icon--plus
    text-decoration: none
    position: absolute
    width: 80px
    height: 80px
    background: rgba(white, 0.7)
    border-radius: 50%
    top: -50%
    left: -15%
    text-align: center
    cursor: pointer
    transition: all 300ms ease
    transform-origin: center
    display: flex
    align-items: center
    justify-content: center
    span
        font-size: 4em
        font-weight: 600
        color: $blue

    &:hover
        transform: scale(1.2)
        background: rgba(white,0.9)
    @media (max-width: 1040px)
        left: 15px
        width: 50px
        height: 50px
        top: -60px
        span
            font-size: 3em