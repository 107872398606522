#toenungssimulator
    .mw-1800
        padding: 5vw 30px
        .flex-row
            @media (max-width: 740px)
                display: block
        .col-1
            padding-top: 30px
            padding-right: 30px
    .grey
        background: $grey
        padding: 30px
        img
            margin: 20px 0
            max-width: 100%

