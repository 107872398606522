.news--page
	.divider--line
		width: 100%
		max-width: 100%
		border: none
		background: #ececec
		height: 1px
	.flex--container
		display: flex
		align-items: center
		margin-bottom: 20px
		.col--1
			flex: 1 0 35%
			img
				padding-right: 20px
				max-width: 100%
		.col--2
			flex: 1 0 65%
			h5
				margin-bottom: 0!important
	.fw--image-left
		min-height: calc(100vh - 80px)
		transition: none
		*
			transition: none!important
		.fw--text-content
			padding-bottom: 30px
			strong
				font-size: inherit
			h2
				font-size: 4rem
				@media (max-width: 1040px)
					font-size: 2.2rem
			h5
				margin: 0
				font-weight: bold
				color: #2e2e2e
				&:after
					content: none
			h4
				margin-bottom: 0
				font-weight: bold
			ul
				margin-bottom: 20px
				li
					margin-left: 20px
					margin-bottom: 10px
					font-weight: normal
			.angebotskasten
				display: flex
				align-items: center
				margin-right: 0
				background: #dde9ee
				position: relative
				margin-left: -1em
				padding: 20px
				@media (min-width: 640px)
					padding: 0
					margin-left: -3em
				&:after
					content: ""
					position: absolute
					left: 100%
					top: 0
					width: 100%
					height: 100%
					background: inherit
				&.black
					background: #000
					padding: 2em
					padding-right: 1em
					
					@media (min-width: 640px)
						padding: 2em 20px 3em 3em
						// padding-left: 3em
					*
						color: white!important
					img.mobile--image
						max-width: 100%!important
					.angebot
						position: relative
						margin-bottom: 4rem
						@media (min-width: 640px)
							margin-bottom: 4rem
							margin-left: -2em
							top: 4em
						// max-width: 25em
					.slanted
						margin-top: -3em
						@media (min-width: 640px)
							margin-top: 3rem
						transform-origin: right top
						transform: skewY(-10deg)

				&.female
					background: white
					margin-left: 0
					@media (min-width: 640px)
						margin-right: -3em


				img.hero
					flex: 1 1 50%
					max-width: 50%
					display: none
					@media (min-width: 640px)
						display: block
				.angebot
					padding: 2em 0
					p
						margin-bottom: 10px
					h4
						font-size: 1.6rem
						margin-bottom: 10px
					.mobile--image
						display: block
						@media (min-width: 640px)
							display: none
					img
						max-width: 70%
					.pricetag
						font-size: 2.2rem
						margin-bottom: 0
			.info--box
				background: #efefef
				padding: 20px
				margin: 0
				position: relative
				&.orange
					background: #FECB44
					*
						color: black
				&.aif
					display: flex
					align-items: center
					justify-content: center
				.infobox--content
					position: relative
					width: 100%
					text-align: center
					margin: 0 auto
					@media (min-width: 700px)
						width: 60%
				.wolke
					position: absolute
					left: 50%
					top: 50%
					transform: translate(-50%,-50%)
					height: 120%
					max-width: 700px
		.fw--image
			background-position: center
			display: flex
			justify-content: flex-end
			align-items: flex-end
			padding: 20px
			color: white
		@media (max-width: 1199px)
			display: block
			.fw--text-wrapper
				max-width: 100%
			.fw--image-container
				min-height: 320px
				max-height: 650px
				height: 80vw
			.fw--text-content,
			.info--box
				max-width: 100%
		@media (min-width: 1200px)
			.fw--image
				position: fixed
				left: 0
				top: 0
				width: 50%
				height: 100%
				transform: none
			.fw--image-container,
			.fw--text-wrapper
				width: 50%
				max-width: 50%
				// height: 100%
				// display: block
				// overflow: auto
	.caption
		&:before
			content: none
		padding-left: 0
	.breadcrumbs
		display: flex
		flex-wrap: wrap
		margin-bottom: 1em
		align-items: center
		a
			font-size: 1rem
			display: block
			//text-transform: lowercase
			padding: 0.2em 1.2em 0.2em 0.8em
			text-decoration: none
			background: $grey
			color: black
			padding-right: 1.5em
			position: relative
			margin: 0
			&:after
				content: ""
				position: absolute
				right: 0
				top: 0
				height: 100%
				width: 15px
				z-index: 1
				background: url('../../assets/img/breadcrumb-divider.svg') right center no-repeat
				background-size: contain
			&:last-child
				padding-right: 0.6em
				&:after
					content: none
	strong 
		font-weight: 800    
	.news--subline
		font-size: 2rem
		margin-bottom: 0.5rem!important
		display: block
		& + h5
			display: block
			margin-bottom: 1.5rem!important
	.news--dividerline
		color: $blue!important
		text-transform: uppercase
		font-size: 1.8rem
		margin-bottom: 1rem!important
	.news--image
		width: 100%
		max-width: 100%
		display: block
		margin: 20px 0
		&.right--small
			max-width: 15rem
			margin-left: auto
		&.center--small
			max-width: 15rem
			margin: 0 auto
		&.center--medium
			max-width: 25rem
			margin: -2rem auto 0
			position: relative
	.news--flex-row
		@media (min-width: 500px)
			display: flex
			align-items: center
			p
				margin-bottom: 0
				padding-right: 20px
	.breaking--news
		background: white
		padding: 20px
		margin: 80px 0
		p
			margin-bottom: 20px
		h5
			text-align: center
			color: $blue!important

// News Übersichtsseite
.news--content
	max-width: 1200px
	width: 100%
	margin: 6em auto
	padding: 2rem
	.news--article
		width: 100%
		margin-bottom: 20px
		background: lighten($grey, 5%)
		display: flex
		align-items: center
		// padding: 2rem
		.news--article-image
			//flex: 1 0 0
			display: block
			img
				max-width: 20rem
				width: 30vw
				display: block
		.news--article-content
			padding: 2rem
			flex: 2 0 0
			h3
				font-size: 2.4em
				font-weight: 100!important
				font-family: $main-font
				margin-bottom: 10px
			h4
				font-size: 1.6em
			p
				margin-bottom: 1rem
			.as-btn
				margin-bottom: 0
	@media (max-width: 700px)
		// display: block
		padding: 1rem
		.news--article
			.news--article-image
				display: none
			.news--article-content
				padding: 1rem
				h3
					font-size: 1.6rem
#news-12
	.fw--image
		background-position: top 80px center!important
		@media (max-width: 1199px)
			background-position: top center!important