#modal
    position: fixed
    left: 0
    top: 0
    right: 0
    bottom: 0
    overflow-y: auto
    z-index: 999999999999
    background: rgba($blue, 0.98)
    display: flex
    align-items: center
    justify-content: center
    padding: 2rem

    .modal__content
        padding: 1rem 2rem 2rem
        background: #fff
        border-radius: 0.2rem
        p
            margin: 0
        a
            font-weight: bold
            text-decoration: none
        h3
            margin: 1rem 0
