#glasdickenrechner
    padding: 5vw 0
    .centered
        padding: 30px
        @media (max-width: 1100px)
            text-align: left
    p
        max-width: 1100px
    .iframe--wrapper
        text-align: left
        position: relative
        max-width: 1100px
        height: 0
        margin: 0 auto
        padding-bottom: 920px
        @media (max-width: 960px)
            padding-bottom: 90vw
        @media (max-width: 670px)
            padding-bottom: 100vw
        @media (max-width: 460px)
            padding-bottom: 140vw

        iframe
            position: absolute
            left: 0
            top: 0
            height: 100%
            width: 100%
    .centered--content-small
        padding: 20px
        margin: 0 auto

#alterssichtigkeit
    .col-1
        margin: 0 0 20px
        min-width: 280px
        &:first-child
            padding-right: 20px
    p
        max-width: 900px
        margin-bottom: 20px