
*
  margin: 0
  padding: 0
  box-sizing: border-box
  font-family: $main-font
  font-size: 15px
  font-weight: 300


html, body
  height: 100%
  font-size: 100%
  line-height: 1.4
  //transition: 0.5s ease
  background: white
body
  width: 100%
  overflow-x: hidden
  min-height: 100vh
  display: flex
  flex-direction: column
  justify-content: space-between
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
    display: block

.clearfix::after
  content: "."
  display: block
  clear: both
  font-size: 0
  height: 0
  visibility: hidden
@media (min-width: 960px)
  .mobile
    display: none

.flex-row
  display: flex
  flex-wrap: wrap
  align-items: center
.col-1-3
  flex: 1 0 0
  @media (max-width: 800px)
    min-width: calc(50% - 20px)
    max-width: calc(50% - 20px)
    margin-right: 10px !important
    margin-bottom: 40px
  @media (max-width: 600px)
    min-width: 100%
    max-width: 100%
    margin-right: 0px !important
    margin-bottom: 40px
.col-1-4
  flex: 1 0 20%
  margin-right: 20px!important
  margin-bottom: 20px
  @media (max-width: 1050px)
    flex: 1 0 45%
    margin-right: 20px!important
    &:nth-child(even)
      margin-right: 0!important
   
  @media (max-width: 540px)
    flex: 1 0 100%
    margin-right: 0px !important

.col-1
  flex: 1 0 0
.col-2
  flex: 2 0 0
.col-3
  flex: 3 0 0
  min-width: 430px
  @media (max-width: 500px)
    min-width: 320px
.col-4
  flex: 4 0 0
  min-width: 550px
  @media (max-width: 500px)
    min-width: 320px

.subheadline
  position: relative
  text-align: center
  &::before
    content: ""
    position: absolute
    height: 1px
    background: $blue
    top: 52%
    left: 0
    right: 0
    z-index: -999
  h2
    z-index: 1
    position: relative
    display: inline-block
    width: auto
    &:before
      z-index: -9990
      content: ""
      position: absolute
      width: calc(100% + 20px)
      height: 100%
      left: -10px
      top: 0
      background: white
    @media (max-width: 600px)
      font-size: 2.4rem

.mw-1200
  max-width: 1200px
  margin-left: auto
  margin-right: auto
  padding: 0
.mw-1600
  max-width: 1600px
  margin-left: auto
  margin-right: auto
  padding: 0
.mw-1800
  max-width: 1800px
  margin-left: auto
  margin-right: auto
  padding: 0

.no-align
  align-items: stretch
.news-box
  display: flex
  justify-content: space-between
  // flex-wrap: wrap
  flex-direction: column

.centered
  margin: 0 auto
  text-align: center
  position: relative
  p
    margin-left: auto
    margin-right: auto
.mt6
  margin-top: 6em
.mx-20
  margin-left: 20px 
  margin-right: 20px 
  &.mw-1600
    margin-left: auto
    margin-right: auto
    @media (max-width: 1600px)
      margin-left: 20px
      margin-right: 20px
.p8
  padding: 8em 0

.fullwidth
    width: 100%
.centered--content
    margin: 0 auto
    padding: 60px 20px
    text-align: center
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    
    h1, p
      max-width: 900px
      margin: 0 center
      margin-bottom: 0
      text-align: center
      position: relative
    p
      margin-bottom: 0
    @media (max-width: 960px)
      align-items: flex-start
      h1, p
        text-align: left
.mw-900
  max-width: 900px
  p
    max-width: 100%

.white
  background: #fff
  position: relative
.headline--left
  padding: 5vw 30px
  max-width: 1800px
  
  h1, .h1
    margin: 0
    max-width: 1100px
    margin-bottom: 20px
  p
    margin-top: 40px
    margin-bottom: 0
    max-width: 1100px
  @media (max-width: 740px)
    padding: 5vw 20px
    h1, .h1
      margin-top: 30px
.headline--centered
  padding: 5vw 30px
  max-width: 1800px
  text-align: center
  h1
    margin: 0 auto
    max-width: 1100px
    text-align: center
    margin-bottom: 20px
  strong
    font-weight: 600
  p
    margin: 40px auto 0
    max-width: 1100px
  @media (max-width: 740px)
    padding: 5vw 20px
    h1
      margin-top: 30px

.divider
  height: 80px
.divider--line
  margin: 40px auto 80px
  width: 70%
  max-width: 600px
  height: 2px
  background: $grey 

.mw-100
  min-width: 100%

.delay-100
  transition-delay: 100ms!important

.grey
  background: lighten($grey, 5%)
.blue
  background: $blue

.image--slider-thumb
  padding: 20px
  position: relative
  display: flex
  align-items: center
  background: $grey
  img
    width: 100%

.mr-20
  margin-right: 40px


.placeholder
  position: relative
  &:after
    content: "Platzhalter"
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%,-50%)
    background: orangered
    color: white
    padding: 10px 15px

#content
  @media (max-width: 500px)
      padding-top: 60px!important
      

.target-relative 
  position: relative
  .target 
    position: absolute
    left: 0
    top: -80px
    @media (max-width: 500px)
      top: -60px