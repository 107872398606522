#visioncare--info
    margin-bottom: 40px
    .info--text-content
        padding-bottom: 60px
        padding-top: 0
        @media (max-width: 740px)
            padding-bottom: 150px
        ul
            &.last
                max-width: 70%
                @media (max-width: 740px)
                    max-width: 100%
    .kl--geschenk
        position: absolute
        right: -20px
        bottom: -20px
        width: 30%
        min-width: 200px
        @media (max-width: 1950px)
            right: 0px

    .info--teaser-content
        .constrained--content
            .info--image
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
                    height: auto


    @media (max-width: 1100px)
        .info--wrapper
            display: block
            .info--teaser-content
                .constrained--content
                    max-width: 100%
                    display: flex
                    flex-direction: row
                    .info--icon
                        display: none
                        min-width: 260px
                        margin: 20px
                    .info--image
                        margin: 30px 0
                        max-width: 60vw
                        min-width: 150px

    @media (max-width: 740px)
        .info--wrapper
            display: block
            .info--teaser-content
                .constrained--content
                    max-width: 100%
                    display: flex
                    flex-direction: column
                    .info--icon
                       display: none
                    .info--image
                        margin: 30px 0
                        max-width: 150px

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
    .icon-linse
        width: auto!important
        text-align: center
        max-width: 140px

.pricebox
    padding: 1rem
    background: white
    margin-top: 3rem
    h5
        margin: 0 0 1rem 0
        font-weight: bold!important
        &:after
            content: none
    strong
        font-weight: bold!important
    ul
        @media (min-width: 768px)
            max-width: 80%
        li
            margin-left: 20px
            font-weight: normal
            margin-top: 0.5rem
    .mw500 
        max-width: 500px