.df-btn
	z-index: 99999999999999!important
	max-height: 100vh
	overflow-y: auto
	&:not(.df-closed)
		@media (max-width: 720px)
			padding-top: 110px!important
			padding-bottom: 60px!important
		.df-btn-text
			&:before
				background-image: url('https://chat.occx.de/dist/assets/close.svg')!important

.df-btn-text
	padding: 0.5rem 1rem 0.5rem 0.5rem!important
	font-size: 0.825rem!important
	height: 40px!important
	&:before
		min-width: 40px!important
		background-image: url('../img/icon-chat.svg')!important
		background-size: 20px!important
	// @media (max-width: 720px)
	// 	&:before
	// 		// content: none!important