.fw--image-left, .fw--image-right
	width: 100%
	max-width: 100vw
	display: flex
	flew-wrap: wrap
	min-height: 100vh
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
		min-height: auto!important
	&.intro--section
		min-height: 800px
		height: calc(100vh - 1px)
	.fw--image-container
		flex: 1 0 58%
		overflow: hidden
		position: relative
		//height: 100%
		.fw--image, iframe
			&.alter
				background-image: url(../img/img-alterssichtigkeit.jpg)
			&.colors
				background-image: url(../img/img-lustaufmode.jpg)
			&.gl
				background-image: url(../img/gleitsichtexperte.jpg)
			&.dryeye
				background-image: url(../img/dryeye.jpg)
			&.dryeye1
				background-image: url(../img/dryeye-intro.jpg)
			&.kinderoptometrie
				background-image: url(../img/kinderoptometrie/0S9A5464-kopie-3.jpg)
			position: absolute
			width: 100%
			height: 100%
			left: 50%
			top: 50%
			transform: translate(-50%,-50%)
			background-size: cover
			background-position: center
			// background-image: url(attr(data-image))
			transform-origin: center
			transition: all 1000ms ease
			&.scaled
				width: 150%
				
	.fw--text-wrapper
		flex: 1 0 42%
		padding: 3em
		display: flex
		flex-direction: column
		justify-content: center
		@media (max-width: 960px)
			padding: 2em
		@media (min-width: 960px)
			max-width: 42%
		

		.fw--text-content
			max-width: 600px
			width: 100%
			text-align: left
			position: relative
			h2, h1
				text-align: left
				margin: 0
				margin-bottom: 30px
				font-size: 5rem
				width: 100%
				line-height: 1!important
				@media (max-width: 1040px)
					font-size: 2.2rem
	@media (max-width: 960px)
		// flex-direction: column
		display: block
		min-height: 0
		max-height: none
		height: auto

		.fw--image-container
			min-height: 320px
			max-height: 600px
			height: 80vw
			order: 1!important
		&.intro--section
			min-height: 0
			max-height: none
			height: auto
			.fw--image-container
				max-height: none
				min-height: calc(100vh - 80px)
				height: calc(100vh - 80px)
				@media (max-width: 960px)
					min-height: 50vh
					height: 50vh
.fw--image-right
	.fw--image-container
		order: 2
	.fw--text-wrapper
		align-items: flex-end
		order: 1
		@media (max-width: 960px)
			align-items: center
			padding: 2em 1em
		.fw--text-content
			max-width: 600px
.fw--image-left
	.fw--image-container
		order: 1
	.fw--text-wrapper
		align-items: flex-start
		order: 2
		@media (max-width: 960px)
			align-items: center
			padding: 2em 1em
		.fw--text-content
			max-width: 600px

.fullheight--header
	height: calc(100vh - 80px)
	width: 100%
	background-position: center center
	background-size: cover
	position: relative
	.scroll--down
		width: 40px
		height: 40px
		position: absolute
		bottom: 50px
		left: 50%
		transform: translateX(-50%)
		@media (max-width: 740px)
			bottom: 30px
		span
			transform: rotate(45deg) translate(-50%,-50%)
			border: 2px solid $blue
			width: 30px
			height: 30px
			display: block
			position: absolute
			border-left: transparent
			border-top: transparent
			left: 50%
			transition: 300ms ease
			&:first-child
				top: 30%
			&:last-child
				top: 70%
				transition-delay: 50ms
		&.white
			background: transparent
			span
				border-color: white
		&:hover
			span
				&:first-child
					top: 50%
				&:last-child
					top: 90%
	&.ambiente
		background-image: url('../img/img-store.jpg')
	&.anpassung
		background-image: url('../img/img-VisionCare_lounge.jpg')
	&.twen
		background-image: url('../img/img-twen25-hero.jpg')
	&.sportbrille
		background-image: url('../img/b_sport-header.jpg')
	&.about
		background-image: url('../img/philosophie.jpg')
	&.garantie
		background-image: url('../img/img-garantie.jpg')
	&.marken
		background-image: url('../img/img-marken.jpg')
	&.gleitsicht
		background-image: url('../img/Geraet_beim_Optiker.jpg')
		background-position: left 40% top
	&.werkstatt
		background-image: url('../img/img-manufactur.jpg')
	&.jobs
		background-image: url('../img/header-jobs.jpg')
	&.sonnenbrille
		background-image: url('../img/b_sonnenbrille-header.jpg')
	&.augenuntersuchung
		background-image: url('../img/o_augenuntersuchung-header.jpg')
		background-position: right
	&.arbeitsplatz
		background-image: url('../img/img-arbeitsbrille.jpg')
		background-position: top
	&.traenenfilm
		background-image: url('../img/img-traenenfilm.jpg')
	&.visual-training
		background-image: url('../img/o_visual-training.jpg')
	&.visioncare
		background-image: url('../img/logo-visioncare.svg'), url('../img/img-vision_care.jpg')
		background-position: left 4% bottom 30px, top 10% center
		background-size: 240px, cover
		background-repeat: no-repeat
		@media (max-width: 740px)
			background-size: 120px, cover
	@media (max-width: 740px)
		height: 50vh
.line--top
	position: relative
	&:after
		content: ""
		background: $blue
		height: 20px
		width: 50%
		position: absolute
		left: 0
		top: -20px

.info--container
	.info--wrapper
		display: flex
		flex-wrap: wrap
		position: relative
		//padding: 40px 0
		background: #fff
		max-width: 1800px
		margin: 0 auto
		.info--teaser-content
			background: $blue
			flex: 1 0 40%
			padding: 5vw 30px
			display: flex
			justify-content: center
			position: relative
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
				flex: 2 0 10%
				h4, h3
					max-width: 300px
			.constrained--content
				max-width: 300px
				display: flex
				flex-direction: column
				align-items: center
				h3
					//text-transform: lowercase
					color: white
					font-family: $main-font
					font-size: 2.618rem
					font-weight: 200
					text-align: left
					padding: 0
					display: block
					&:before
						content: none
				.info--icon
					width: 260px
					height: 260px
					border-radius: 50%
					border: 1px solid white
					display: flex
					align-items: center
					justify-content: center
					margin-top: 40px
					img
						width: 60%
				.info--image
					max-width: 660px
					display: flex
					align-items: center
					justify-content: center
					margin-top: 40px
					img
						width: 100%
					@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
						max-width: 300px
						height: 100%
	.info--text-content
		background: $grey
		flex: 2 0 60%
		padding: 30px 60px
		display: flex
		align-items: center
		justify-content: flex-start
		position: relative
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
			flex: 2 0 10%
		h4
			font-family: $condensed-font
			font-size: 1.618rem
			margin-top: 60px
			margin-bottom: 10px
		p
			margin-bottom: 0
			
	@media (max-width: 740px)
		.info--wrapper
			display: block
			.info--teaser-content
				.constrained--content
					h3
						font-size: 2rem
						br
							display: none
					.info--icon
						width: 150px
						height: 150px
						margin-bottom: 10px
			.info--text-content
				padding: 30px
				.constrained--content
					p
						margin-bottom: 0
	  
.content
	max-width: 1800px
	margin: 40px auto
	position: relative
	padding: 30px
	//padding-right: 0
	@media (max-width: 740px)
		margin: 0 auto
	.content--header
		padding-right: 30px
		h2
			width: 100%
			max-width: none
			text-align: left
			margin: 0
			font-size: 2.618rem
			margin-bottom: 20px
			line-height: 1.2
			@media (max-width: 740px)
				font-size: 2rem
		h4
			font-family: $condensed-font
			font-size: 1.618rem
.content--double

	h4
		font-family: $condensed-font
		font-size: 1.4rem
	ol
		margin: 20px 0  
	@media (max-width: 1400px)
		.flex-row
			display: block

.image--wrapper
	width: 100%
	position: relative
	min-width: 250px
	margin-bottom: 20px
	overflow: hidden
	padding-bottom: 100%
	&:after
		content: ""
		width: 61.8%
		height: 6px
		position: absolute
		left: 0
		bottom: 0
		background: $blue
	img
		width: 100%
		position: absolute
		left: 50%
		top: 50%
		transition: all 800ms ease
		transform: translate(-50%,-50%)
		&.scaled
			width: 150%
	span
		position: absolute
		left: 30px
		bottom: 30px
		font-size: 2rem
		color: $blue
.counter--reset
	counter-reset: section
	margin-right: -30px
	div[class^='col-']
		padding-right: 40px
	.col-1
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
			min-width: 280px
		
ol
	list-style-type: none
	margin: 20px 0
	li
		margin-left: 25px
		margin-bottom: 20px
		position: relative
		font-weight: 400
		&:before
			counter-increment: section
			content: counters(section, ". ")". "
			color: $blue
			position: absolute
			left: -25px
			top: -0.2rem
			font-weight: 600
			font-size: 1.2rem
		em
			color: $blue
			font-style: normal
			font-weight: 400

.content--large-content
	display: flex
	justify-content: center
	align-items: center
	flex-direction: column

	img
		width: 100%
		max-width: 400px
		height: auto
		margin-top: -150px
		align-self: flex-end
		transition: all 800ms ease
		transition-delay: 0ms
		transform: translateX(0)
		position: relative
		&.out
			transform: translateX(300px)
	h3
		font-family: $main-font
		margin: 0
		padding: 0
		font-weight: 300
		font-size: 2rem
		max-width: 300px
		align-self: flex-start
		position: relative
		z-index: 1
		&:before
			content: none
	@media (max-width: 1100px)
		margin-top: 30px
		min-width: 100%
		img
			margin-top: -5vw
		h3
			max-width: 650px
			font-size: 1.4rem
			width: 100%




.grey--container
	padding: 30px
	margin-top: 30px
	width: 100%
	background: $grey
	p
		width: 100%
		margin: 0
		max-width: 1000px
.image--grid
	width: 100%
	margin-top: 20px
	position: relative
	.fas.fa-info-circle
		cursor: pointer
		display: none
		z-index: 2
		position: absolute
		top: 15px
		left: 15px
		font-size: 1.7rem
		color: white
		&:hover + .brand--overlay-description-popup, &:active + .brand--overlay-description-popup
			display: block
			@media (max-width: 640px)
				display: none
		@media (max-width: 640px)
			display: block
	.brand--overlay-description-popup
		display: none
		p
			color: white
			margin-bottom: 0
			font-size: .8rem
		.popup--close
			z-index: 99999999
			display: flex
			align-items: center
			justify-content: center
			width: 2rem
			height: 2rem
			border-radius: 50%
			background: white
			position: absolute
			top: 15px
			left: 15px
			font-size: 1.7rem
			color: white
			
			color: $blue
			cursor: pointer
			font-weight: bold
			line-height: 0
		@media (max-width: 640px)
			//display: none
			z-index: 10000
			padding: 3rem
			background: rgba(0, 63, 143, 0.95)	  
			position: fixed
			top: 100px
			overflow-y: auto
			// display: flex
			align-items: center
			left: 20px
			right: 20px
			bottom: 20px
			box-shadow: 0 0 5rem 5rem white
			&.active
				display: flex
		@media (max-width: 540px)
			padding: 2rem
	.image--grid-row
		width: 100%
		display: flex
		flex-wrap: wrap
		position: relative
		&:nth-child(3n+2)
			.image--slider
				order: 3
			.brand-item
				order: 1
			.image--wrapper
				order: 2
		&:nth-child(3n+3)
			.image--slider
				order: 2
			.brand-item
				order: 3
			.image--wrapper
				order: 1
		@media (max-width: 640px)
			margin-bottom: 20px
		.image--grid-item
			flex: 1 0 30%
			max-width: 33.333333333%
			background: $grey
			padding: 40px
			display: flex
			flex-wrap: wrap
			align-items: center
			@media (min-width: 1240px)
				height: 30vw
				max-height: 500px
			@media (max-width: 1300px)
				&.image--slider
					order: 3
					flex: 1 0 100%
					min-width: 100%
					max-width: 100%
				&.brand-item
					order: 1
					flex: 1 0 50%
					max-width: 100%
					height: 40vw
					min-height: 300px
					p
						font-size: 0.925rem
						@media (max-width: 1000px)
							font-size: 0.8rem

				&.image--wrapper
					order: 2
					flex: 1 0 50%
					max-width: 100%
					height: 40vw
					min-height: 300px
 
			@media (max-width: 640px)
				&.image--slider
					order: 3
					flex: 1 0 100%
					min-width: 100%
					max-width: 100%
				&.brand-item
					order: 1
					flex: 1 0 25%
					max-width: 100%
					min-height: 150px
					height: 40vw
					.plus-grid
						display: none

				&.image--wrapper
					order: 2
					flex: 1 0 25%
					max-width: 100%
					min-height: 150px
					height: 40vw
 

			.grid--brille
				max-width: 100%
				width: 100%
				img
					max-width: 100%
			.grid--slider
				max-width: 100%
				position: relative
				.slick-next
					position: absolute
					top: 50%
					transform: translateY(-50%)
					right: 0
					z-index: 10
				.slick-prev
					position: absolute
					top: 50%
					transform: translateY(-50%)
					left: 0
					z-index: 10
			figcaption
				display: flex
				flex-wrap: wrap
				align-items: flex-end
				width: 100%
				.left--content
					flex: 2 0 0
					.name
						font-family: $main-font
						color: #707070
						font-size: 1.2rem
						margin-bottom: 10px
						font-weight: 600
						text-transform: uppercase
					.desc
						display: inline-block
						margin-bottom: 0
				.right--content
					flex: 1 0 0
					text-align: right
					.preis
						font-weight: 400
						font-style: italic
						font-size: 2rem
						text-align: right
		.brand-item
			background: $blue
			display: flex
			align-items: center
			justify-content: center
			position: relative
			cursor: pointer
			padding: 0
			overflow: hidden
			.plus-grid
				opacity: 0.5
				color: white
				position: absolute
				left: 50%
				bottom: 30px
				transform: translateX(-50%)
				width: 60px
				height: 60px
				border: 2px solid white
				border-radius: 50%
				display: flex
				align-items: center
				justify-content: center
				span
					font-weight: 400
					font-size: 3rem
					position: relative
					top: -3px
				@media (max-width: 740px)
					width: 40px
					height: 40px
					span
						font-size: 2rem
						top: 0
			img
				position: absolute
				top: 50%
				left: 50%
				transform: translate(-50%,-50%)
				max-width: 70%
				max-height: 40%
				@media (max-width: 640px)
					max-width: 80%
					max-height: 60%
			.brand--overlay-description
				background: $blue
				position: relative
				padding: 20px
				opacity: 0
				left: 50px
				height: 100%
				width: 100%
				transition: all 600ms ease
				display: flex
				align-items: center
				@media (min-width: 960px)
					padding: 20px 40px
				@media (max-width: 740px)
					display: none !important
				p
					color: white
					margin: 0
			&:active, &:hover
				.brand--overlay-description
					opacity: 1
					left: 0
					width: 100%

		.image--wrapper
			min-width: 0
			margin-bottom: 0
			&:after
				content: none
			
.popup--open 
	pointer-events: none
.iframe--wrapper-video
	position: relative
	width: 100%
	height: 0
	padding-bottom: 75%
	iframe
		position: absolute
		width: 100%
		height: 100%
		left: 0
		top: 0

.news-box
	.news--content-container
		.news--image
			display: block
			width: 100%
			height: 0
			padding-bottom: 62%
			background-position: center
			background-size: cover
			margin-bottom: 1em
			position: relative
			p 
				position: absolute
				bottom: 10px
				right: 10px
				margin-bottom: 0!important
				color: white
				font-size: 0.8rem
		h4
			font-size: 2rem

		

.slick-slider.glass-slider
	margin: 0 auto
	margin-bottom: 40px
	max-width: 70%

	.slick-arrow
		position: absolute
		top: 50%
		i
			font-size: 35px
	.slick-prev
		z-index: 999
		left: 0
	.slick-next
		right: 0
		z-index: 999
	@media (max-width: 740px)
		max-width: 100%

.as-btn.full--btn
	@media (max-width: 740px)
		display: block


#rundgang-frame
	height: 800px

.news--slider
	@media (max-width: 800px)
		.col-1-3
			min-width: auto!important
			margin-right: 0px!important
			margin-bottom: 0px!important
	@media (max-width: 600px)
		.col-1-3
			min-width: auto!important
			margin-right: 0px!important
			margin-bottom: 0px!important

.container
	padding: 2rem

.image--slider
	width: 45vw
	max-width: 45%
	padding: 0 2rem
	min-width: 280px
	.slick-prev,
	.slick-next
		position: absolute
		top: 50%
		transform: translateY(-50%)
	.slick-prev
		left: 0
	.slick-next
		right: 0
	img
		width: 100%
		max-width: 100%
.slick-slider
	position: relative
	max-width: 100%

#kontaktlinsenvielfalt
	padding: 2rem 0
	*
		color: white
	.linsen--teaser
		margin-top: 2rem
	hr
		margin: 0 2rem  
	p
		margin-bottom: 1rem
	h3
		margin-bottom: 1rem
	.flex-row
		align-items: flex-start
	.col-1
		img
			max-width: 100%
			display: block
			margin-left: 1rem

	.borderleft
		padding-left: 2rem
		border-left: 1px solid white
.linsen--teaser

	margin: 2rem 0 0
	p, h3
		margin-bottom: 1rem!important
	img
		max-width: 100%
		margin-left: 1rem
.flex-column
	display: flex
	flex-direction: column
	align-items: center
	justify-content: space-around
	img
		width: 250px
		max-width: 80%
	@media (max-width: 740px)
		flex-direction: row!important
		flex-wrap: wrap
		display: flex
		padding: 0rem!important
		max-width: 100%!important
		width: 100%
		// align-items: center
		img
			max-width: 30%
			margin: 1rem

.video--container
	width: 100%
	max-width: 800px
	margin: 2rem auto 0
	video
		width: 100%
.flex-row-tablet
	@media (min-width: 768px)
		display: flex
		align-items: center
	&.reverse
		> *
			&:first-child
				order: 2
			&:last-child
				order: 1
.p20
	padding: 20px