// Section 1
#as-augenblick, #as-lustaufmode, #as-liebling
	.as-textbox
		align-self: center
		@media (max-width: 979px)
			order: 2
		.flex-inner
			margin: 10%
	.as-slider, .background-img
		overflow: hidden
		height: 100vh
		@media (max-width: 979px)
			order: 1
		img
			display: block
			height: 100%

#as-augenblick
	//height: 100vh

#as-lustaufmode
	flex-direction: row-reverse
	.background-img
		background: url('../img/colors.jpg') center no-repeat
		background-size: cover
#as-liebling
	.brillen
		margin-top: 50px

#as-news, #as-tools
	padding: 0 20px

#as-news
	.news-box
		margin-right: 50px
		&:last-child
			margin-right: 0
		img
			max-width: 100%
			border: 1px solid $blue
			display: block
			margin-bottom: 20px
		p
			margin-bottom: 30px


#as-tools
	.online-tools
		border: 1px solid $blue
		//margin-right: 30px

		img
			max-width: 100%
			display: block
		.as-btn
			margin: 30px 10px 10px 0
			float: right

#as-newsletter
	margin-top: 80px
	padding: 30px 4%
	background: $blue
	color: white
	.newsletter-inner
		max-width: 600px
		margin: 0 auto
		padding: 40px 0
		h2
			text-align: center
			color: white
			margin: 0px auto 30px
			font-size: 3rem
		#newsletter-form
			text-align: center
			position: relative
			.newsletter--form-group
				position: relative
				display: flex
				align-items: center
				justify-content: center
				flex-wrap: no-wrap
				margin-bottom: 20px
			#mce-EMAIL
				height: 50px
				border: none
				padding: 15px
				border-radius: 3px 0 0 3px
				flex: 1 0 0
				margin: 0
				font-weight: 300
				display: block
				outline: none
			#send
				position: relative			
				visibility: visible
				transition: 0.3s ease
				background: #eee
				border-radius: 0 3px 3px 0
				border: 0
				width: 60px
				height: 50px
				margin: 0
				display: block
				cursor: pointer
				
				&::after
					content: ""
					background: url('../img/mail.svg') center no-repeat
					background-size: contain
					width: 27px
					height: 22px
					position: absolute
					top: 50%
					left: 50%
					transform: translate(-50%,-50%)
					pointer-events: none
					transition: all 300ms ease
				&:hover
					background: lighten(#eee, 3%)
					&:after
						left: 60%
			label
				font-size: 0.925rem
				text-align: center
				a
					color: white
					font-size: inherit
	@media (max-width: 500px)
		.newsletter-inner
			padding: 0
			h6
				font-size: 1rem


#as-kontakt
	.kontakt-map
		overflow: hidden
		@media (max-width: 980px)
			margin-bottom: 30px
	.col-3
		align-self: center
		.kontakt-text
			margin: 0 40px
			p
				margin-bottom: 30px
			#adress-form
				position: relative
				width: 250px
				#adresse
					width: 250px
					padding: 20px
					border: 2px solid $blue
				#send
					position: absolute
					right: 0
					top: 0
					box-shadow: none
					background: $blue
					border: 0px
					height: 100%
					width: 60px
					&::after
						content: url('../img/send.svg')
						width: 20px
						height: 20px
						display: inline-block
						visibility: visible
						cursor: pointer
						height: 100%
						width: 60px
						line-height: 5.5
				

#as-bottom
	.mehr-sehen
		padding: 0 20px
		margin: 0 auto
		margin-top: 80px
		max-width: 700px 
		h4
			font-size: 2.2rem
			&::before
				background: url('../img/amon_claim.svg') bottom center no-repeat
				background-size: contain
				display: inline-block
				width: 240px
				height: 33px
				content: ""
				margin-right: 15px
	.as-mitarbeiter
		img
			max-width: 100%
			display: block
			margin: 0 auto





.disable
	@media (max-width: 979px)
		visibility: hidden
		h2
			margin: 0

.slider-nav
	margin-top: 30px

#map
	position: absolute
	width: 100%
	height: 100%
	left: 0
	top: 0
	background: url('../img/karte_platzhalter.jpg') center
	background-size: cover

.search--group
	position: relative
	width: 100%
	margin-bottom: 20px
	display: flex
	align-items: center
	#start
		height: 50px
		border: 1px solid $blue
		padding: 15px
		border-radius: 3px 0 0 3px
		flex: 1 0 0
		margin: 0
		font-weight: 300
		display: block
		outline: 0
	#searchZip
		position: relative
		visibility: visible
		transition: .3s ease
		background: $blue
		color: transparent
		border-radius: 0 3px 3px 0
		border: 0
		width: 50px
		height: 50px
		margin: 0
		display: block
		cursor: pointer
		&:after
			content: ""
			background: url(../img/icon--standortsuche.svg) center no-repeat
			background-size: auto
			background-size: contain
			width: 27px
			height: 22px
			position: absolute
			top: 50%
			left: 50%
			transform: translate(-50%,-50%)
			pointer-events: none
			transition: all .3s ease
#msg
	b
		display: block
		color: orangered
		margin: 10px 0 20px


#routeLink
	position: relative
	top: 50px
	transition: all 300ms ease
	opacity: 0
	pointer-events: none
	&.active
		top: 0
		opacity: 1
		pointer-events: all

.fw--video
	width: 100%
	height: calc(100vh - 1px)
	position: relative
	overflow: hidden
	video 
		position: absolute
		left: 50%
		top: 50%
		transform: translate(-50%, -50%)
		min-width: 100%
		min-height: 100%
	@media (max-width: 1040px)
		width: 100%
		height: auto
		video
			position: relative
			left: 0
			top: 0
			transform: none
			width: 100%
			height: auto


#azubis
	background: #000
	margin-top: 2rem
	margin-bottom: -80px
	*
		color: white
		text-align: center
	img
		display: block
		max-width: 100%
	h3
		font-size: 4rem
		font-family: $main-font
	h4
		text-transform: none
	.azubis-row 
		display: grid
		grid-template-columns: repeat(2, 1fr)
		grid-template-rows: auto
		margin-bottom: 20px
		padding: 0 2rem
		margin-bottom: 2rem
		gap: 1rem
		@media (min-width: 380px)
			grid-template-columns: repeat(2, 1fr)
		@media (min-width: 640px)
			grid-template-columns: repeat(3, 1fr)
		@media (min-width: 940px)
			grid-template-columns: repeat(5, 1fr)
		@media (min-width: 1050px)
			grid-template-columns: repeat(5, 1fr)
		.col-1-4
			width: auto
			margin: 0!important
			height: 100%
			.azubi 
				border: 2px solid white
				background: white
				height: 100%
				img 
					object-fit: cover
					object-position: top
					width: 100%
					aspect-ratio: 5 / 6
					height: auto
				.title 
					background: white
					padding: 10px
					min-height: 90px
					display: flex
					justify-content: center
					flex-direction: column
					h3, p, a
						margin-bottom: 0
						color: black
						text-align: left
						text-transform: uppercase
					h3
						font-size: 1.618rem
						@media (max-width: 740px)
							font-size: 1.0rem
			&:last-child
				grid-column: span 2
				@media (min-width: 640px)
					grid-column: span 1
				.azubi
					p, a 
						text-transform: unset
						line-height: 1.1
						font-weight: bold
						font-size: 0.95rem
	&.jobs
		h3
			font-size: 1.5rem