$mobile-nav-width: 1830px


.top--nav
	display: flex
	padding: 0 4%
	height: 80px
	justify-content: space-between
	border-bottom: 2px solid $blue
	width: 100%
	position: fixed
	background: white
	z-index: 99999
	top: 0
	.logos
		display: flex
		z-index: 99999999
		#amon-logo
			// border: 3px solid white
			margin-top: -3px
			height: 145px
			// box-shadow: 0 0 0px 3px white
			@media (max-width: 1040px)
				height: 80px
				top: 0
			@media (max-width: 500px)
				height: 70px
				top: 0
		#amon-slogan
			height: 24px
			margin-top: 29px
			margin-left: 20px
			@media (max-width: 1580px)
				display: none
			@media (max-width: $mobile-nav-width)
				display: block
				left: 200px
				height: 20px
				margin-top: 28px
				margin-left: 20px
			@media (max-width: 500px)
				display: block
				left: 200px
				height: 15px
				margin-top: 20px
				margin-left: 20px
	@media (max-width: 500px)   
		height: 60px
	 
	#nav-wrapper
		display: inline-flex
		align-items: flex-end
		height: 100%
		@media (max-width: $mobile-nav-width)
			display: none
		.nav-left
			display: flex
			height: 100%
			align-items: flex-end
			li
				list-style: none
				cursor: pointer
				padding-bottom: 25px
				a
					color: black
					text-decoration: none
					padding: 30px 20px 15px 15px
					margin-right: 5px
					font-size: 1rem
					font-weight: 300
					transition: .2s ease
					cursor: pointer
					position: relative
					@media (max-width: 1370px)
						margin-right: 9px
					&:hover, &.active
						color: $blue
					&.active
						font-weight: 400
					&::after
						content: url("../img/arrow-down.svg")
						position: absolute
						transition: all 200ms ease
						transform-origin: center
						top: 27px
						padding-left: 5px

				.subnav
					overflow: hidden
					display: flex
					align-items: stretch
					opacity: 0
					visibility: hidden
					pointer-events: none
					width: 100%
					left: 0
					z-index: 9999
					position: fixed
					background: $blue
					justify-content: space-evenly
					padding-left: 17vw
					transition: all 300ms ease
					top: 78px
					transform: scaleY(0)
					transform-origin: top center
					@media (max-width: $mobile-nav-width)
						display: none
					a
						flex: 1 0 0
						color: white
						padding: 20px 10px !important
						text-decoration: none
						transition: 0.3s ease
						border-right: 1px solid rgba(white,0.2)
						border-left: 1px solid rgba(white,0.2)
						margin: 0!important
						//height: 100%
						display: flex
						align-items: center
						justify-content: center
						text-align: center
						background: $blue
						&:hover
							color: white
							background: desaturate(lighten($blue, 10%), 40%)
						&::after
							display: none
				&:last-child
					a
						margin-right: 30px
				&.active
					.nav--item
						color: $blue
						&:after
							transform: rotate(180deg)
							padding-right: 5px
							top: 32px
					.subnav						
						opacity: 1
						visibility: visible
						pointer-events: all
						transform: scaleY(1)
				// &:hover
				//	 .subnav
				//		 transform: scaleY(1)
				//		 opacity: 1

		.nav-right
			display: flex
			align-self: center
			a
				padding: 15px 15px
				margin-left: 2px
				background: $blue
				color: white
				text-decoration: none
				transition: .2s ease-in-out
				font-weight: 400
				&:hover
					background: lighten($blue, 5%)
				i
					margin-right: 10px
					
	.nav-burger
		height: 30px
		width: 20px
		align-self: center
		display: none
		position: relative
		cursor: pointer
		@media (max-width: $mobile-nav-width)
			display: block
		span
			width: 100%
			transition: all 300m ease
			position: absolute
			left: 50%
			top: 50%
			transform: translate(-50%,-50%) rotate(0)
			height: 2px
			border-radius: 3px
			background: #333
			transition: all 300ms ease
			transform-origin: center
			&:first-child
				top: 30%
			&:nth-child(3)
				top: 70%
			






.link--pointer
	cursor: pointer



.nav-drill
	z-index: 999999!important
	box-shadow: -10px 5px 10px rgba(black, 0.25)
	.nav-items
		li
			list-style: none
			a
				text-decoration: none

		
#nav-mobile-wrap
	position: fixed
	z-index: 9
	width: 100vw
	height: 100%
	#nav-mobile
		background: rgba(255,255,255,.9)
		display: none
		transition: all 0.5s ease
		height: 100vh

.display--block
	display: block !important

.stop--scroll
	overflow: hidden !important

.mobile__phone
	text-decoration: none
	// font-weight: bold
	color: $blue
	display: flex
	align-items: center
	i
		margin-right: 0.5rem
	@media (min-width: $mobile-nav-width)
		display: none




$black: #252525
$white: #fff
$grey: #eaeaea
$primary-color: #003F8F
$link-color: $primary-color

$primary-color-dark: darken( $primary-color, 10% )
$primary-color-tint-1: lighten( $primary-color, 10% )
$primary-color-tint-2: lighten( $primary-color, 20% )
$primary-color-tint-3: lighten( $primary-color, 30% )
$primary-color-tint-4: lighten( $primary-color, 40% )
$primary-color-tint-5: lighten( $primary-color, 50% )
$primary-font: "Fira Sans", Arial, sans-serif

$nav-width: 250px
$nav-padding: 0.875em 1em
$nav-border-color: $primary-color-tint-5
$nav-background-color: $white
$nav-icon-font: 'Material Icons'
$nav-icon-arrow-right: 'chevron_right'
$nav-icon-arrow-left: 'chevron_left'

$nav-level-border-color: $primary-color
$nav-level-background-color: $primary-color-tint-5

$nav-level3-background-color: $primary-color-tint-4
$nav-level4-background-color: $primary-color-tint-3
$nav-level5-background-color: $primary-color-tint-2


.nav-drill 
	margin-top: 80px
	transform: translateX(150%)
@media (max-width: 500px)
	.nav-drill
		margin-top: 60px

.nav-is-toggled 
	.nav-drill 
		transform: translateX(0)
	
	&::after 
		opacity: 1
		visibility: visible
	

.nav 
	&-drill 
		display: flex
		position: fixed
		z-index: 100
		top: 0
		right: 0
		width: $nav-width
		height: 100vh
		background-color: $nav-background-color
		overflow-y: auto
		overflow-x: hidden
		-webkit-overflow-scrolling: touch
		transition: 0.45s

	&-items 
		flex: 0 0 100%
	&-item 
		&:not(:last-child) 
			border-bottom: solid 1px $nav-level-border-color
	&-link
		display: block
		padding: $nav-padding
		background-color: $nav-background-color
		color: $link-color
		font-size: 1rem
		line-height: 1.5em
		font-weight: 300
	&-expand 
		&-content
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			transform: translateX(100%)
			background-color: white
			transition: 0.3s
			visibility: hidden
			.nav-item 
				&:not(:last-child)
					border-bottom: solid 1px $nav-level-border-color

			.nav-link 
				background-color: $nav-level-background-color

			.nav-back-link 
				display: flex
				align-items: center
				background-color: $primary-color !important
				color: $white
				&::before
					content: $nav-icon-arrow-left
					margin-right: 0.5em
					font-family: $nav-icon-font 
					
		&-link 
			display: flex
			justify-content: space-between
			
			&::after
				content: $nav-icon-arrow-right
				flex: 0 1 auto
				font-family: $nav-icon-font
		&.active 
			> .nav-expand-content 
				transform: translateX(0)
				visibility: visible
		// quick example for other level colours
		.nav-expand-content 
			background-color: white
			.nav-link 
				background-color: white
			
			.nav-expand-content 
				background-color: $nav-level3-background-color
				.nav-link
					background-color: $nav-level3-background-color
				
				.nav-expand-content 
					background-color: $nav-level4-background-color
					.nav-link 
						background-color: $nav-level4-background-color
					
					.nav-expand-content 
						background-color: $nav-level5-background-color
						.nav-link 
							background-color: $nav-level5-background-color

.nav-interaction-items
	border-bottom: 1px solid white !important
	a
		background-color: $blue !important
		color: white

.nav-is-toggled
	.nav-burger
		span
			&:first-child
				top: 50%
				transform: translate(-50%,-50%)  rotate(45deg)
			&:nth-child(2)
				width: 0
			&:nth-child(3)
				top: 50%
				transform: translate(-50%,-50%)  rotate(-45deg)

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
	.nav-back-link  
		&:before				   
			width: 0

// mobile nav icons
.mobile-nav
	display: flex
	.nav-burger
		margin-left: 45px 
	.mobile-nav-icons
		display: none
		a
			background: $blue
			display: flex
			align-items: center
			justify-content: center
			height: 100%
			width: 60px
			margin-right: 2px
			text-decoration: none
			i
				color: white
				font-size: 1.2rem
			&:hover
				background: lighten($blue, 5%)
	@media ( max-width: $mobile-nav-width )
		.mobile-nav-icons
			display: flex
	@media ( max-width: 550px )
		.nav-burger
			margin-left: 30px
		.mobile-nav-icons
			display: flex
			a
				width: 40px
				background: inherit
				i
					color: $blue