#service
	.mw-1800
		padding: 60px 30px
		h3
			max-width: 900px
   
		.flex-row
			margin: 30px 0
			.col-1
				margin-right: 20px
				&:last-child
					margin-right: 0 
			@media (max-width: 860px)
				display: block
				.col-1
					margin-right: 0
		.image--wrapper
			padding-bottom: 60%
	h1
		font-family: $condensed-font
		font-size: 1.61rem
		text-transform: none
		line-height: 1.4
		@media (max-width: 740px)
			font-size: 1.2rem

		
	.flexbox
		display: flex
		max-width: 1600px
		margin: 0em auto
		flex-wrap: wrap
		> div
			flex: 1 0 30%
			display: flex
			align-items: center
			.fw--image
				height: 100%
				background-size: cover
				background: #000
				background-position: center
				display: flex
				align-items: center
				img
					display: block
					max-width: 100%
			.fw--text-wrapper
				padding: 10%
		.fw--image.big-image
			height: 100vh
			background-size: cover
		@media (max-width: 900px)
			> div
				min-height: 550px
				min-width: 250px
				&:last-of-type
					min-width: 100%
					min-height: 200px
					.fw--text-wrapper
						padding: 50px
			.fw--image.big-image
				height: 400px
				background-size: cover

.manufaktur-slider
	width: 50%
	max-width: 50%
	padding: 0 2rem
	height: auto
	margin: 0 auto
	@media (max-width: 768px)
		width: 100%
		max-width: 100%
	.slick-prev, .slick-next
		position: absolute
		display: block
		height: 20px
		width: 20px
		cursor: pointer
		top: 50%
		transform: translate(0, -50%)
		padding: 0
		color: black
		.fa
			font-size: 35px
	.slick-prev 
		left: 0
	.slick-next
		right: 0


